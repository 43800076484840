import { criteria } from "../data/RatingCriteria";

const ClientPropertyDetailController = ({ t, form }) => {
  const getMissingRatingKeys = (ratings) => {
    let removeArray = [];
    const buro = form.getFieldValue("areaOffice");
    const wohnen = form.getFieldValue("areaResidential");
    const gewerbe = form.getFieldValue("areaCommercial");
    if (!buro) removeArray.push("büro");
    if (!wohnen) removeArray.push("wohnen");
    if (!gewerbe) removeArray.push("gewerbe");

    const ratingsWithZeroValue = ratings.filter((rating) =>
      isNaN(rating.value)
    );

    let missings = [];
    criteria.forEach((group) => {
      group.children.forEach((rating) => {
        if (rating.children) {
          rating.children.forEach((subrating) => {
            const criteriaWithZeroValue = ratingsWithZeroValue.find(
              (r) => r.key.toString() === subrating.key
            );
            if (criteriaWithZeroValue) {
              //check if usage-type is ZERO
              const usageTypeZero = removeArray.includes(
                subrating.title.toLowerCase()
              );

              if (usageTypeZero) return;
              //push if it is not Zero
              if (!usageTypeZero) {
                missings.push(
                  `) ${rating.code} : ${t(
                    "criteria." + rating.key.split(".").join("_") + ".title"
                  )} [${t(
                    "criteria." + subrating.key.split(".").join("_") + ".title"
                  )}]`
                );
              }
            }
          });
        } else {
          const criteriaWithZeroValue = ratingsWithZeroValue.find(
            (r) => r.key.toString() === rating.key
          );
          if (criteriaWithZeroValue) {
            missings.push(
              `) ${rating.code} : ${t(
                "criteria." + rating.key.split(".").join("_") + ".title"
              )}`
            );
          }
        }
      });
    });

    return missings;
  };

  return { getMissingRatingKeys };
};

export default ClientPropertyDetailController;
