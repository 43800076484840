import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Select,
  Statistic,
  TreeSelect,
  Tooltip,
  Affix,
  Popover,
  Avatar,
} from "antd";

import { UndoOutlined, CalendarOutlined } from "@ant-design/icons";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DashboardFilterBox } from "../../Common/Charts";
import TwoSetComparisonBarChart from "../../Common/Charts/TwoSetComparisonBarChart";
import TwoSetComparisonPieChart from "../../Common/Charts/TwoSetComparisonPieChart";
import CompanyModel from "../../../models/CompanyModel";
//translation
import { useTranslation } from "react-i18next";

const { SHOW_CHILD } = TreeSelect;
const { Option } = Select;
const { Title } = Typography;

const RatingBox = (props) => {
  const { t } = useTranslation("common");
  const { BenchMarkData } = props;
  var bb = [...BenchMarkData].reverse();
  if (bb.length > 1) bb.pop();
  return (
    <>
      <div className={"ant-table-wrapper"} style={{ width: "400px" }}>
        <div className={"ant-table ant-table-bordered ant-table-small"}>
          <div className={"ant-table-container"}>
            <div className={"ant-table-content"}>
              <table style={{ tableLayout: "auto" }}>
                <thead className={"ant-table-thead"}>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.period")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {" "}
                    {t("admin_dashboard.rating")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.properties")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    m<sup>2</sup>
                  </th>
                </thead>
                <tbody className={"ant-table-tbody"}>
                  {bb.map((b) => {
                    return (
                      <>
                        <tr className={"ant-table-row ant-table-row-level-0"}>
                          <td align={"center"}>
                            {b.category2 + "." + b.category}
                          </td>
                          <td align={"center"}>
                            {parseFloat(b.rating).toFixed(2)}
                          </td>
                          <td align={"center"}>{b.property_count}</td>
                          <td align={"center"}>
                            {parseFloat(b.totalArea).toLocaleString("de-ch", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function ClientDashboard(props) {
  const { t } = useTranslation("common"); //translation
  const { store } = props;
  const id = parseInt(props.computedMatch.params.id);
  const [companyName, setCompanyName] = useState("");
  const [usageBarChartData, setUsageBarChartData] = useState(false);
  const [ratingSetA, setRatingSetA] = useState(0);
  const [propertyCountSetA, setPropertyCountSetA] = useState(0);
  const [totalAreaSetA, setTotalAreaSetA] = useState(0);
  const [propertyCountBenchmarkOrSetA, setPropertyCountBenchmarkOrSetA] =
    useState(0);
  const [benchMarkData, setBenchMarkData] = useState([
    { dataset: "Benchmark", category: 0, category2: 0, rating: "0" },
  ]);
  const [activeBenchmarkDataIndex, setActiveBenchmarkDataIndex] = useState(0);
  const [clientCompany, setClientCompany] = useState(CompanyModel.create({}));
  const [ClientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    store.setActiveClientId(id);

    Chart.pluginService.register(ChartDataLabels);
    Chart.defaults.global.defaultFontFamily = "Bahnschrift Regular";
    Chart.defaults.global.tooltips.cornerRadius = 0;
    Chart.defaults.global.tooltips.backgroundColor = "#ffffff";
    Chart.defaults.global.tooltips.titleFontColor = "#4f81bd";
    Chart.defaults.global.tooltips.bodyFontColor = "#000000";
    Chart.defaults.global.tooltips.borderColor = "#aaaaaa";
    Chart.defaults.global.tooltips.borderWidth = 1;
    Chart.defaults.global.plugins.datalabels.font.family =
      "Bahnschrift Regular";

    Chart.plugins.register({
      id: "paddingBelowLegends",
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height = this.height + 40;
        };
      },
    });

    if (store.clients.length == 0) {
      store.getClients().then((s) => {
        setClientCompany(
          store.clients.find((c) => c.id === store.activeClientId)
        );
      });
    } else {
      setClientCompany(
        store.clients.find((c) => c.id === store.activeClientId)
      );
    }

    return () => {
      store.setActiveClientId(0);
      Chart.pluginService.unregister(ChartDataLabels);
    };
  }, []);

  const [dashboardPortfolios, setDashboardPortfolios] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/portfoliosbycompany`, {
          companyId: id,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (result.success) {
        setDashboardPortfolios(result.records);
      }
    }
    fetchData();

    return () => {
      setDashboardPortfolios([]);
    };
  }, [id]);

  useEffect(() => {
    if (clientCompany && clientCompany.id > 0) {
      setCompanyName(" : " + clientCompany.name);
    }
    return () => {};
  }, [clientCompany]);

  const createRandomChildren = (parentkey) => {
    var list = [];

    var xlist = clientCompany.portfolios.filter(
      (pf) =>
        pf.companyId === parentkey &&
        dashboardPortfolios.findIndex((rr) => rr.portfolio_id === pf.id) > -1
    );
    if (xlist) {
      list = xlist.map((pf) => {
        return {
          title: pf.name,
          value: parentkey + "-" + pf.id,
          key: parentkey + "-" + pf.id,
        };
      });
    }
    return list;
  };
  useEffect(() => {
    if (
      clientCompany &&
      clientCompany.id > 0 &&
      dashboardPortfolios.length > 0
    ) {
      var children = [];
      var selections = [];
      [clientCompany].forEach((client) => {
        children.push({
          title: t("admin_client_dashboard.all_portfolios"),
          value: client.id,
          key: client.id,
          children: createRandomChildren(client.id),
        });

        children[children.length - 1].children.forEach((c) => {
          selections.push(c.value);
        });
      });

      setClientOptions(children);
      setSelectedRowKeysA(selections);
      setSelectedRowKeysB(selections);
    }
    return () => {
      setClientOptions([]);
    };
  }, [clientCompany, dashboardPortfolios]);

  const [CompareToBenchMark, setCompareToBenchMark] = useState(true);

  const [selectedRowKeysA, setSelectedRowKeysA] = useState([]);
  const [selectedRowKeysB, setSelectedRowKeysB] = useState([]);
  const [SelectedNamesA, setSelectedNamesA] = useState("");
  const [SelectedNamesB, setSelectedNamesB] = useState("");
  const [ColorA, setColorA] = useState("#4f81bd");
  const [ColorB, setColorB] = useState("#ff6026");
  const [selectionModeA, setSelectionModeA] = useState("benchmark");
  const [selectionModeB, setSelectionModeB] = useState("benchmark");

  //RatingByThreeYears
  const requestThreeYearRatings = async () => {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);

        var item = result.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //SetA
        if (item) {
          setRatingSetA(parseFloat(item.rating ? item.rating : 0).toFixed(2));
          setPropertyCountSetA(item.property_count);
          setTotalAreaSetA(item.totalArea);
        }
        //Benchmark Ratings
        var benchmarkInfo = result
          .filter((d) => d.dataset === "Benchmark" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );
        setBenchMarkData(benchmarkInfo);
        setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);
        setPropertyCountBenchmarkOrSetA(
          benchmarkInfo.length > 0
            ? benchmarkInfo[benchmarkInfo.length - 1].property_count
            : 0
        );
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);

        var item = result.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //SetA
        if (item) {
          setRatingSetA(parseFloat(item.rating ? item.rating : 0).toFixed(2));
          setPropertyCountSetA(item.property_count);
        }
        //Benchmark Ratings
        var benchmarkInfo = result
          .filter((d) => d.dataset === "Set B" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );
        setBenchMarkData(benchmarkInfo);
        setPropertyCountBenchmarkOrSetA(
          benchmarkInfo.length > 0
            ? benchmarkInfo[benchmarkInfo.length - 1].property_count
            : 0
        );
      }
    }
  };

  const requestUsageTypeData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);
        setUsageBarChartData(result);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);

        setUsageBarChartData(result);
      }
    }
  };

  const requestRegionData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    }
  };

  const requestRegionPieData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    }
  };

  const requestAreaByUsageData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    }
  };

  const requestCriteriaGroupData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })
          .then((response) => response)
          .catch((error) => error.response);
      }
    }
  };

  useEffect(() => {
    var list = [];
    selectedRowKeysA.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNamesA(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    return () => {
      setSelectedNamesA("");
    };
  }, [selectedRowKeysA]);
  useEffect(() => {
    var list = [];
    selectedRowKeysB.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNamesB(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    return () => {
      setSelectedNamesB("");
    };
  }, [selectedRowKeysB]);
  useEffect(() => {
    requestThreeYearRatings();
    requestUsageTypeData();
    requestRegionData();
    requestRegionPieData();
    requestCriteriaGroupData();
    requestAreaByUsageData();
  }, [selectedRowKeysA, selectedRowKeysB, CompareToBenchMark]);

  useEffect(() => {
    return () => {};
  }, [usageBarChartData]);

  const PortfolioATreeSelectOnChange = (value) => {
    setSelectedRowKeysA(value);
  };
  const PortfolioBTreeSelectOnChange = (value) => {
    setSelectedRowKeysB(value);
  };
  const tPropsA = {
    treeData: ClientOptions,
    value: selectedRowKeysA,
    onChange: PortfolioATreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,
    listHeight: 700,
    placeholder: t("admin_client_dashboard.please_select_set_a"),
    style: {
      width: "350px",
    },
  };
  const tPropsB = {
    treeData: ClientOptions,
    value: selectedRowKeysB,
    onChange: PortfolioBTreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,

    listHeight: 700,
    placeholder: t("admin_client_dashboard.portfolio_set_b"),
    style: {
      width: "350px",
    },
  };

  const handleComparisonTypeChange = (value) => {
    setCompareToBenchMark(value === "yes");
    setActiveBenchmarkDataIndex(0);
  };

  const resetFiltersA = () => {
    setSelectedRowKeysA([]);
  };
  const resetFiltersB = () => {
    setSelectedRowKeysB([]);
  };

  const [pieChartRegionSource, setPieChartRegionSource] = useState("1");
  const [pieChartUsageTypeSource, setPieChartUsageTypeSource] = useState("1");

  useEffect(() => {
    var cubeName = CompareToBenchMark ? "IndexVsSetA" : "SetAVsSetB";
    var valueA = selectedRowKeysA;
    var valueB = selectedRowKeysB;
    var q1 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.usage_type`]: "desc",
      },
      dimensions: [`${cubeName}.usage_type`],
      filters: [],
    };
    var q2 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.region`]: "asc",
      },
      dimensions: [`${cubeName}.region`],
      filters: [],
    };
    var q3 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.criteria_group`]: "asc",
      },
      dimensions: [`${cubeName}.criteria_group`],
      filters: [],
    };

    var q4 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.region"],
      filters: [],
    };
    var q5 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.usage_type"],
      filters: [],
    };

    if (CompareToBenchMark) {
      //use set A
      var pieFilters = [];
      if (valueA.length > 0) {
        pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }
      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        //use Benchmark
        q4.filters = [];
      }
      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        //use Benchmark
        q5.filters = [];
      }
    } else {
      var pieFilters = [];
      //use set A
      if (valueA.length > 0) {
        var pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }

      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q4.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }

      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q5.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }
    }

    var filters = [];
    if (CompareToBenchMark) {
      if (valueA.length > 0) {
        filters = [
          {
            or: [
              {
                dimension: "IndexVsSetA.row_type",
                operator: "equals",
                values: ["Benchmark"],
              },
              {
                and: [
                  {
                    dimension: "IndexVsSetA.row_type",
                    operator: "equals",
                    values: ["Set A"],
                  },
                  {
                    dimension: "IndexVsSetA.portfolio_id",
                    operator: "equals",
                    values: valueA.map((v) => {
                      return v.split("-")[1];
                    }),
                  },
                ],
              },
            ],
          },
        ];
      }
    } else {
      filters = [
        {
          or: [
            valueA.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["SetA"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set A"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueA.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
            valueB.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["Set B"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set B"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueB.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
          ],
        },
      ];
    }
    q1.filters = filters;
    q2.filters = filters;
    q3.filters = filters;
  }, [
    selectedRowKeysA,
    selectedRowKeysB,
    CompareToBenchMark,
    pieChartRegionSource,
    pieChartUsageTypeSource,
  ]);

  const [isAffixed, setIsAffixed] = useState(false);

  useLayoutEffect(() => {
    setIsAffixed(false);
    return () => {
      setIsAffixed(false);
    };
  }, []);

  return (
    <>
      <Affix
        style={{ position: "absolute" }}
        offsetTop={0}
        onChange={(affixed) => {
          setIsAffixed(affixed);
        }}
      ></Affix>
      <Affix offsetTop={0}>
        <Row
          className={"page-title"}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? "rgba(255,255,255,0.8)"
              : "rgba(255,255,255,0.0)",
            boxShadow: isAffixed
              ? "10px 1px 15px rgba(128,128,128,0.5)"
              : "none",
          }}
        >
          <Col flex={"auto"}>
            <Title className={isAffixed ? "page-title" : ""}>
              {t("admin_client_dashboard.dashboard")}
              {" " + companyName}
            </Title>
          </Col>
          <Col span={16}></Col>
        </Row>
      </Affix>

      <Row gutter={[32, 24]}>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorA}
            setColor={setColorA}
            setName={"Portfolio Set A"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysA}
            setSelectedKeys={setSelectedRowKeysA}
            selectionMode={selectionModeA}
            setSelectionMode={setSelectionModeA}
          ></DashboardFilterBox>
        </Col>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorB}
            setColor={setColorB}
            setName={"Portfolio Set B"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysB}
            setSelectedKeys={setSelectedRowKeysB}
            selectionMode={selectionModeB}
            setSelectionMode={setSelectionModeB}
          ></DashboardFilterBox>
        </Col>
      </Row>
      {1 == 0 && (
        <Row gutter={[32, 24]}>
          <Col span={12}>
            <Card className={"ant-card-shadow ant-card-statistic"}>
              <Row gutter={[0, 25]}>
                <Col flex="auto" style={{ textAlign: "center" }}>
                  <Space>
                    {t("admin_client_dashboard.portfolio_set_a")}
                    <Tooltip
                      title={SelectedNamesA}
                      placement={"left"}
                      color={"#435c77"}
                    >
                      <TreeSelect
                        className={"dashbboard-treeselect"}
                        {...tPropsA}
                      />
                    </Tooltip>
                    <Tooltip title={t("admin_client_dashboard.reset_filters")}>
                      <Button
                        shape="circle"
                        icon={<UndoOutlined />}
                        onClick={resetFiltersA}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333", marginRight: "10px" }}>
                          {t("admin_client_dashboard.rating")}
                        </strong>
                      </>
                    }
                    value={ratingSetA}
                    precision={2}
                    valueStyle={{ color: "#4f81bd" }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333" }}>
                          {t("admin_client_dashboard.properties")}
                        </strong>
                      </>
                    }
                    value={propertyCountSetA}
                    precision={0}
                    valueStyle={{ color: "#4f81bd" }}
                    groupSeparator={"'"}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333", marginRight: "10px" }}>
                          m<sup>2</sup>
                        </strong>
                      </>
                    }
                    value={totalAreaSetA}
                    precision={0}
                    groupSeparator={"'"}
                    valueStyle={{ color: "#4f81bd" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card className={"ant-card-shadow ant-card-statistic"}>
              <Row gutter={[0, 25]}>
                <Col flex="auto" style={{ textAlign: "center" }}>
                  <Space>
                    <Select
                      defaultValue="yes"
                      style={{
                        width: CompareToBenchMark ? 528 : 170,
                        textAlign: "left",
                      }}
                      onChange={handleComparisonTypeChange}
                      value={CompareToBenchMark ? "yes" : "no"}
                    >
                      <Option value="yes">
                        {" "}
                        {t("admin_client_dashboard.benchmark")}
                      </Option>
                      <Option value="no">
                        {t("admin_client_dashboard.portfolio_set_b")}
                      </Option>
                    </Select>
                    {CompareToBenchMark === false && (
                      <>
                        <Tooltip
                          title={SelectedNamesB}
                          placement={"left"}
                          color={"#E76B82"}
                        >
                          <TreeSelect
                            className={"dashbboard-treeselect"}
                            {...tPropsB}
                          />
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title={t("admin_client_dashboard.reset_filters")}>
                      <Button
                        shape="circle"
                        icon={<UndoOutlined />}
                        onClick={resetFiltersB}
                      />
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
              <Row>
                {CompareToBenchMark === true && (
                  <>
                    <Col
                      span={6}
                      key={
                        benchMarkData[activeBenchmarkDataIndex].dataset +
                        "_" +
                        benchMarkData[activeBenchmarkDataIndex].category +
                        "_" +
                        benchMarkData[activeBenchmarkDataIndex].category2
                      }
                    >
                      <Statistic
                        title={
                          <>
                            <strong style={{ color: "#333" }}>
                              {t("admin_dashboard.rating")}
                            </strong>
                          </>
                        }
                        value={parseFloat(
                          benchMarkData[activeBenchmarkDataIndex].rating
                        ).toFixed(2)}
                        precision={2}
                        valueStyle={{ color: "#ff6026" }}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title={
                          <>
                            <strong style={{ color: "#333" }}>
                              {t("admin_dashboard.properties")}
                            </strong>
                          </>
                        }
                        value={
                          benchMarkData[activeBenchmarkDataIndex].property_count
                        }
                        precision={0}
                        valueStyle={{ color: "#ff6026" }}
                        groupSeparator={"'"}
                      />
                    </Col>
                    <Col span={6}>
                      <Statistic
                        title={
                          <>
                            <strong
                              style={{ color: "#333", marginRight: "10px" }}
                            >
                              m<sup>2</sup>
                            </strong>
                          </>
                        }
                        value={
                          benchMarkData[activeBenchmarkDataIndex].totalArea
                        }
                        precision={0}
                        groupSeparator={"'"}
                        valueStyle={{ color: "#ff6026" }}
                      />
                    </Col>
                    <Col span={6}>
                      {benchMarkData.length > 1 && (
                        <Statistic
                          title={
                            <>
                              <div>
                                <strong style={{ color: "#333" }}>
                                  {t("admin_dashboard.previous_month")}
                                </strong>
                              </div>
                              <Popover
                                placement="right"
                                title={false}
                                content={
                                  <RatingBox
                                    BenchMarkData={benchMarkData}
                                  ></RatingBox>
                                }
                                trigger="click"
                              >
                                <Avatar
                                  size={40}
                                  icon={<CalendarOutlined />}
                                  style={{
                                    marginTop: "10px",
                                    backgroundColor: "#ff6026",
                                    cursor: "pointer",
                                  }}
                                />
                              </Popover>
                            </>
                          }
                          value={""}
                          precision={0}
                          valueStyle={{ color: "#ff6026", display: "none" }}
                        />
                      )}
                    </Col>
                  </>
                )}
                {CompareToBenchMark === false && (
                  <>
                    {[benchMarkData[benchMarkData.length - 1]].map(
                      (benchmark) => (
                        <>
                          <Col span={6}>
                            <Statistic
                              title={
                                <>
                                  <strong style={{ color: "#333" }}>
                                    {t("admin_client_dashboard.rating")}
                                    <span className={"rating-prefix"}>
                                      {benchmark.category2 +
                                        "." +
                                        benchmark.category}
                                    </span>
                                  </strong>
                                </>
                              }
                              value={parseFloat(benchmark.rating).toFixed(2)}
                              precision={2}
                              valueStyle={{ color: "#e76b82" }}
                            />
                          </Col>
                          <Col span={6}>
                            <Statistic
                              title={
                                <>
                                  <strong style={{ color: "#333" }}>
                                    {" "}
                                    {t("admin_client_dashboard.properties")}
                                  </strong>
                                </>
                              }
                              value={propertyCountBenchmarkOrSetA}
                              precision={0}
                              valueStyle={{ color: "#e76b82" }}
                              groupSeparator={"'"}
                            />
                          </Col>
                          <Col span={6}>
                            <Statistic
                              title={
                                <>
                                  <strong
                                    style={{
                                      color: "#333",
                                      marginRight: "10px",
                                    }}
                                  >
                                    m<sup>2</sup>
                                  </strong>
                                </>
                              }
                              value={benchmark.totalArea}
                              precision={0}
                              groupSeparator={"'"}
                              valueStyle={{ color: "#e76b82" }}
                            />
                          </Col>
                        </>
                      )
                    )}
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      )}
      <Row gutter={[32, 24]}>
        <Col span={8} lg={8} md={24} sm={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.nutzungen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByUsageBenchmark",
              set: "RatingByUsageSetWIthName",
              "pf-1": "RatingByUsagePortfolioType",
              "pf-2": "RatingByUsagePortfolioType",
              "pf-3": "RatingByUsagePortfolioType",
              "pf-4": "RatingByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>

        <Col span={16} lg={16} md={24} sm={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.regionen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByRegionBenchmark",
              set: "RatingByRegionSetWithName",
              "pf-1": "RatingByRegionPortfolioType",
              "pf-2": "RatingByRegionPortfolioType",
              "pf-3": "RatingByRegionPortfolioType",
              "pf-4": "RatingByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
      </Row>

      <Row gutter={[32, 24]} style={{ marginBottom: "35px" }}>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.bereich")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByCriteriaGroupBenchmark",
              set: "RatingByCriteriaGroupSetWithName",
              "pf-1": "RatingByCriteriaGroupPortfolioType",
              "pf-2": "RatingByCriteriaGroupPortfolioType",
              "pf-3": "RatingByCriteriaGroupPortfolioType",
              "pf-4": "RatingByCriteriaGroupPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.flache")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "AreaByUsageBenchmark",
              set: "AreaByUsageSetWithName",
              "pf-1": "AreaByUsagePortfolioType",
              "pf-2": "AreaByUsagePortfolioType",
              "pf-3": "AreaByUsagePortfolioType",
              "pf-4": "AreaByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.gebäude")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "TotalAreaByRegionBenchmark",
              set: "TotalAreaByRegionSetWithName",
              "pf-1": "TotalAreaByRegionPortfolioType",
              "pf-2": "TotalAreaByRegionPortfolioType",
              "pf-3": "TotalAreaByRegionPortfolioType",
              "pf-4": "TotalAreaByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
      </Row>
    </>
  );
}

export default observer(ClientDashboard);
