import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Switch,
  AutoComplete,
  Tooltip,
  Space,
  Table,
  Drawer,
  Descriptions,
  message,
  Statistic,
} from "antd";
import PropertyModel from "../../models/PropertyModel";
import { getAgeText } from "../../data/AddressBook";

import ClientPropertyDetailModal from "./ClientPropertyDetailModal";
import ActualRatingsModal from "./Ratings/ActualRatingsModal";
import PotentialRatingsModal from "./Ratings/PotentialRatingsModal";
import { stream } from "exceljs";
import PortfolioModel from "../../models/PortfolioModel";
import { getPropertyRating } from "../../data/RatingCriteria";
import { toJS } from "mobx";

import PropertyAuditList from "../Audit/PropertyAuditList";
import PropertyAuditAdder from "../Audit/PropertyAuditAdder";

//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;
function ClientPropertyDetailDrawer(props) {
  const { t } = useTranslation("common"); //translation
  const {
    store,
    visible,
    setVisible,

    /*  clientId,
    portfolioId, */
    /* selectedPortfolio, */
    refreshList,
    /* properties, */
    getCantonName,
  } = props;

  const clientId = store.activeClientId;
  const portfolioId = store.activePortfolioId;
  const [propertyId, setPropertyId] = useState(0);

  useEffect(
    () => setPropertyId(store.activePropertyId),
    [store.activeClientId]
  );
  /*  const [selectedPortfolio, setSelectedPortfolio] = useState(
    PortfolioModel.create({})
  );
  useEffect(() => {
    if (clientId > 0 && portfolioId > 0) {
      setSelectedPortfolio(
        store.clients
          .find((c) => c.id === clientId)
          .portfolios.find((pf) => pf.id === portfolioId)
      );
    }
    return () => {
      setSelectedPortfolio(PortfolioModel.create({}));
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [clientId, portfolioId]);

  useEffect(() => {
    if (selectedPortfolio.id > 0) {
      setProperties(selectedPortfolio.properties);
    }
    return () => {
      setProperties([]);
    };
  }, [selectedPortfolio]);

  //const properties = selectedPortfolio ? selectedPortfolio.properties : [];
  const [properties, setProperties] = useState([]); */

  const [currentProperty, setCurrentProperty] = useState(
    PropertyModel.create({})
  );

  const [AuditLogs, setAuditLogs] = useState([]);

  useEffect(() => {
    if (propertyId === 0) {
      setCurrentProperty(PropertyModel.create({}));
    } else {
      setCurrentProperty(
        store.clients
          .find((c) => c.id === store.activeClientId)
          .portfolios.find((pf) => pf.id === store.activePortfolioId)
          .properties.find((pp) => pp.id === propertyId)
      );
    }
    return () => {
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [propertyId]);

  const [modalVisible, setModalVisible] = useState(false);

  const [popConfirmRatingvisible, setPopConfirmRatingvisible] = useState(false);

  useEffect(() => {
    if (!modalVisible) {
      setPopConfirmRatingvisible(false);
    }
  }, [modalVisible]);

  useEffect(() => {
    if (portfolioId > 0) {
      if (propertyId === 0) {
        setCurrentProperty(PropertyModel.create({}));
      } else {
        setCurrentProperty(
          store.clients
            .find((c) => c.id === store.activeClientId)
            .portfolios.find((pf) => pf.id === store.activePortfolioId)
            .properties.find((p) => p.id === propertyId)
        );
      }
    }
    return () => {
      setCurrentProperty(PropertyModel.create({}));
    };
  }, [propertyId]);

  const propertyModalProps = {
    store,
    visible: modalVisible,
    setVisible: setModalVisible,
    propertyId,
    /*  selectedProperty: currentProperty, */
    clientId,
    portfolioId: portfolioId,
    selectedPortfolio: store.portfolios.find((pf) => pf.id === portfolioId),
    refreshList,
    popConfirmRatingvisible,
    setPopConfirmRatingvisible,
    getCantonName,
  };
  const [actualRatingsModalVisible, setActualRatingsModalVisible] =
    useState(false);
  const [potentialRatingsModalVisible, setPotentialRatingsModalVisible] =
    useState(false);
  const actualRatingsModalProps = {
    store,
    id: propertyId,
    /* currentProperty, */
    visible: actualRatingsModalVisible,
    setVisible: setActualRatingsModalVisible,
    refreshList,
    setPropertyId,
  };
  const potentialRatingsModalProps = {
    store,
    /* id: propertyId, */
    currentProperty,
    visible: potentialRatingsModalVisible,
    setVisible: setPotentialRatingsModalVisible,
  };
  const [areas, setAreas] = useState([
    {
      areaOffice: 0,
      areaResidential: 0,
      areaCommercial: 0,
      areaBuilding: 0,
      areaHealth: 0,
      areaTotal: 0,
    },
  ]);

  const areaOffice = toJS(currentProperty).areaOffice;
  const areaResidential = toJS(currentProperty).areaResidential;
  const areaCommercial = toJS(currentProperty).areaCommercial;
  const areaBuilding = toJS(currentProperty).areaBuilding;
  const areaHealth = toJS(currentProperty).areaHealth;

  useEffect(() => {
    setAreas([
      {
        areaOffice: currentProperty.areaOffice,
        areaResidential: currentProperty.areaResidential,
        areaCommercial: currentProperty.areaCommercial,
        areaBuilding: currentProperty.areaBuilding,
        areaHealth: currentProperty.areaHealth,
        areaTotal:
          areaOffice +
          areaResidential +
          areaCommercial +
          areaBuilding +
          areaHealth,
      },
    ]);
  }, [areaOffice, areaResidential, areaCommercial, areaBuilding, areaHealth]);

  const [computedAge, setComputedAge] = useState(0);
  useEffect(() => {
    setComputedAge(getAgeText(currentProperty.age, t));
  }, [currentProperty.age]);

  const [auditCount, setAuditCount] = useState(0);

  useEffect(() => {
    /*  currentProperty.getRatings().then(s=>{
      currentProperty.getPotentialRatings();
    }); */

    loadAuditLogs();
    return () => {
      setAuditLogs([]);
    };
  }, [currentProperty, store.clientListUpdateOn]);

  const loadAuditLogs = () => {
    if (currentProperty && currentProperty.id > 0) {
      currentProperty.getAuditLogs().then((s) => {
        //sort audite logs by desc date first
        setAuditLogs(
          currentProperty.auditLogs.sort(
            (a, b) =>
              new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
          )
        );
      });
    }
  };

  useEffect(() => {
    setAuditCount(AuditLogs.length);
    return () => {
      setAuditCount(0);
    };
  }, [AuditLogs]);

  const areaUsageColumns = [
    {
      title: t("client_property.wohnen"),
      dataIndex: "areaResidential",
      key: "areaResidential",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaResidential).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.büro"),
      dataIndex: "areaOffice",
      key: "areaOffice",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaOffice).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.gewerbe"),
      dataIndex: "areaCommercial",
      key: "areaCommercial",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaCommercial).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.bildung"),
      dataIndex: "areaBuilding",
      key: "areaBuilding",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaBuilding).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.gesundheit"),
      dataIndex: "areaHealth",
      key: "areaHealth",
      align: "center",
      width: "16.7%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaHealth).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
    {
      title: t("client_property.total"),
      dataIndex: "areaTotal",
      key: "areaTotal",
      align: "center",
      width: "16.6%",
      render: (text, record) => (
        <div>
          {`${parseInt(record.areaTotal).toLocaleString(
            "de-ch"
            // , {minimumFractionDigits: 0,}
          )}`}
          m<sup>2</sup>
        </div>
      ),
    },
  ];

  const [auditform] = Form.useForm();
  const onFinishAuditForm = (values) => {
    var data = {
      auditDate: values.auditDate.toDate().toLocaleDateString(),
      comment: values.comment ? values.comment : "",
    };
    currentProperty.addAuditLog(data).then((s) => {
      if (s) {
        message.success(t("client_property.audit_log_added_successfully"));
        loadAuditLogs();
      } else {
        message.error(t("client_property.could_not_add_audit_log_property"));
      }
    });
  };

  useEffect(() => {
    //getPotentialRatings
    if (
      potentialRatingsModalVisible === true &&
      currentProperty.potentialRatings.length === 0
    ) {
      currentProperty.getPotentialRatings();
    }
    return () => {};
  }, []);
  return (
    <>
      <Drawer
        destroyOnClose
        title={false}
        placement="right"
        closable={true}
        visible={visible}
        getContainer={true}
        style={{ position: "absolute" }}
        width={"calc(100% - 400px)"}
        onClose={() => {
          setCurrentProperty(PropertyModel.create({}));
          setVisible(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Title
              style={{
                display: "block !important",
                textAlign: "left",
                width: "100%",
              }}
              level={3}
            >
              {t("client_property.property_semi")} {currentProperty.name}
            </Title>
          </Col>
        </Row>
        <Row style={{ textAlign: "left", marginBottom: "16px" }}>
          <Col span={24}>
            <Space>
              {/* <Title level={4}>{selectedClient ? selectedClient.name : ""}</Title> */}

              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setActualRatingsModalVisible(true);
                }}
              >
                {t("client_property.view_actual_ratings")}
              </Button>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  setPotentialRatingsModalVisible(true);
                }}
              >
                {t("client_property.view_potential_ratings")}
              </Button>
              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                {t("client_property.edit")}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col flex={"auto}"} span={18}>
            <Descriptions
              bordered
              size={"default"}
              className={"description-label-fix"}
            >
              {/* <Descriptions.Item label="Name" span={3}>
              {currentProperty.name}
            </Descriptions.Item> */}
              <Descriptions.Item label={t("client_property.address")} span={3}>
                {currentProperty.address}
              </Descriptions.Item>

              <Descriptions.Item label={t("client_property.plz")} span={1}>
                {currentProperty.plz}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_property.city")} span={2}>
                {currentProperty.city}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_property.canton")} span={1}>
                {currentProperty.canton}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_property.region")} span={1}>
                {currentProperty.region}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Tooltip title={t("client_property.year_about")}>
                    {t("client_property.year")}
                  </Tooltip>
                }
                span={1}
              >
                <Tooltip title={currentProperty.age == 0 ? "?" : computedAge}>
                  {currentProperty.age == 0 ? "?" : currentProperty.age}
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col flex={"auto}"} span={4}>
            <Card>
              <Statistic
                title={t("client_property.rating")}
                value={(
                  Math.round(
                    parseFloat(getPropertyRating(currentProperty)) * 100
                  ) / 100
                ).toFixed(2)}
                precision={2}
                valueStyle={{ zoom: 2.4, textAlign: "center" }}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col flex={"auto}"} span={18}>
            {/* <Descriptions title="Area per Usage Type" bordered>
            <Descriptions.Item label="Wohnen" span={1}>
              {currentProperty.areaOffice} m<sup>2</sup>
            </Descriptions.Item>

            <Descriptions.Item label="Büro" span={1}>
              {currentProperty.areaResidential} m<sup>2</sup>
            </Descriptions.Item>
            <Descriptions.Item label="Gewerbe" span={1}>
              {currentProperty.areaCommercial} m<sup>2</sup>
            </Descriptions.Item>
            <Descriptions.Item label="Total" span={1}>
              {currentProperty.areaOffice +
                currentProperty.areaResidential +
                currentProperty.areaCommercial}{" "}
              m<sup>2</sup>
            </Descriptions.Item>
          </Descriptions> */}
            <Title level={4} style={{ margin: "10px" }}>
              {t("client_property.area_per_usage")}
            </Title>
            <Table
              title={false}
              dataSource={areas}
              columns={areaUsageColumns}
              size={"middle"}
              bordered={true}
              pagination={false}
              rowKey={"areaTotal"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "40px" }}>
          <Col flex={"auto}"} span={18}>
            <Title level={4} style={{ margin: "10px" }}>
              {t("client_property.audit_history")}
            </Title>
            <PropertyAuditAdder property={currentProperty}></PropertyAuditAdder>
            {/* <Form
              form={auditform}
              name="horizontal_login"
              layout="inline"
              onFinish={onFinishAuditForm}
            >
              <Row style={{ width: "100%", margin: "20px 0px" }}>
                <Col span={6}>
                  <Form.Item
                    label={"Audit"}
                    name="auditDate"
                    rules={[
                      { required: true, message: "Please enter Audit Date" },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item name="comment">
                    <Input maxLength={2000} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add Audit Log
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form> */}
            <PropertyAuditList
              AuditLogs={AuditLogs}
              property={currentProperty}
              isAdmin={true}
            ></PropertyAuditList>
          </Col>
        </Row>
      </Drawer>
      <ClientPropertyDetailModal
        {...propertyModalProps}
      ></ClientPropertyDetailModal>

      {actualRatingsModalVisible && propertyId > 0 && (
        <ActualRatingsModal {...actualRatingsModalProps}></ActualRatingsModal>
      )}

      {potentialRatingsModalVisible && propertyId > 0 && (
        <PotentialRatingsModal
          {...potentialRatingsModalProps}
        ></PotentialRatingsModal>
      )}
    </>
  );
}

export default inject("store")(observer(ClientPropertyDetailDrawer));
