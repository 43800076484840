import { types,/*  flow, getRoot  */} from "mobx-state-tree";
/* import axios from "axios"; */

const DefaultPropertyRatingValueModel = types
  .model('DefaultPropertyRatingValueModel',{
    attribute_id: types.optional(types.number, 0), 
    key: types.optional(types.string, ''),
    value: types.optional(types.number, 0), 
    
    
  })


  export default DefaultPropertyRatingValueModel;
