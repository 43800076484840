import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import { Row, Col, Typography, Affix } from "antd";

import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { DashboardFilterBox } from "../../Common/Charts";
import TwoSetComparisonBarChart from "../../Common/Charts/TwoSetComparisonBarChart";
import TwoSetComparisonPieChart from "../../Common/Charts/TwoSetComparisonPieChart";

//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;

function Dashboard(props) {
  const { t } = useTranslation("common"); //translation
  const { store } = props;

  const [usageBarChartData, setUsageBarChartData] = useState(false);

  const [ClientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState([]);
  useEffect(() => {
    Chart.pluginService.register(ChartDataLabels);
    Chart.defaults.global.defaultFontFamily = "Bahnschrift Regular";
    Chart.defaults.global.tooltips.cornerRadius = 0;
    Chart.defaults.global.tooltips.backgroundColor = "#ffffff";
    Chart.defaults.global.tooltips.titleFontColor = "#4f81bd";
    Chart.defaults.global.tooltips.bodyFontColor = "#000000";
    Chart.defaults.global.tooltips.borderColor = "#aaaaaa";
    Chart.defaults.global.tooltips.borderWidth = 1;
    Chart.defaults.global.plugins.datalabels.font.family =
      "Bahnschrift Regular";
    Chart.plugins.register({
      id: "paddingBelowLegends",
      beforeInit: function (chart) {
        chart.legend.afterFit = function () {
          this.height = this.height + 40;
        };
      },
    });
    return () => {
      Chart.pluginService.unregister(ChartDataLabels);
    };
  }, []);

  const [dashboardPortfolios, setDashboardPortfolios] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const { data: result } = await axios
        .post(`/api/dashboard/portfolios`, {
          t: store.company.type,
          user_id: store.login.id,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (result.success) {
        setDashboardPortfolios(result.records);
      }
    }
    fetchData();

    return () => {
      setDashboardPortfolios([]);
    };
  }, []);

  useEffect(() => {
    var co = [];
    store.getPortfoliosByUser(props.store.login.id).then(() => {
      [store.company].forEach((c) => {
        // add the client only if it has porfolios..
        if (
          store.portfolios
            .filter(
              (pf) =>
                dashboardPortfolios.findIndex(
                  (rr) => rr.portfolio_id === pf.id
                ) > -1
            )
            .find((pf) => pf.companyId === c.id)
        ) {
          co.push({ ...c, key: c.id });
        }
      });

      setClients(co);
    });

    return () => {};
  }, [dashboardPortfolios]);

  const createRandomChildren = (parentkey) => {
    var list = [];

    var xlist = store.portfolios.filter(
      (pf) =>
        pf.companyId === parentkey &&
        dashboardPortfolios.findIndex((rr) => rr.portfolio_id === pf.id) > -1
    );
    if (xlist) {
      list = xlist.map((pf) => {
        return {
          title: pf.name,
          value: parentkey + "-" + pf.id,
          key: parentkey + "-" + pf.id,
        };
      });
    }
    return list;
  };
  useEffect(() => {
    var children = [];
    var selections = [];
    [store.company].forEach((client) => {
      children.push({
        title: t("client_dashboard.all_portfolios"),
        value: client.id,
        key: client.id,
        children: createRandomChildren(client.id),
      });

      children[children.length - 1].children.forEach((c) => {
        selections.push(c.value);
      });
    });

    setClientOptions(children);

    setSelectedRowKeysA(selections);
    setSelectedRowKeysB(selections);
    return () => {
      setClientOptions([]);
    };
  }, [clients]);

  const [CompareToBenchMark, setCompareToBenchMark] = useState(true);

  const [selectedRowKeysA, setSelectedRowKeysA] = useState([]);
  const [selectedRowKeysB, setSelectedRowKeysB] = useState([]);
  const [ColorA, setColorA] = useState("#4f81bd");
  const [ColorB, setColorB] = useState("#ff6026");
  const [selectionModeA, setSelectionModeA] = useState("set");
  const [selectionModeB, setSelectionModeB] = useState("benchmark");

  //RatingByThreeYears
  const requestThreeYearRatings = async () => {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })
          .then((response) => response)
          .catch((error) => error.response);

        var item = result.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //Benchmark Ratings
        var benchmarkInfo = result
          .filter((d) => d.dataset === "Benchmark" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );
      } else {
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        var item = result.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //Benchmark Ratings
        var benchmarkInfo = result
          .filter((d) => d.dataset === "Set B" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );
      }
    }
  };

  const requestUsageTypeData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setUsageBarChartData(result);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setUsageBarChartData(result);
      }
    }
  };

  const requestRegionData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    }
  };

  const requestRegionPieData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    }
  };

  const requestAreaByUsageData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    }
  };

  const requestCriteriaGroupData = async () => {
    if (CompareToBenchMark === true) {
      if (selectedRowKeysA.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const { data: result } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);
      } else {
      }
    }
  };

  useEffect(() => {
    var list = [];
    selectedRowKeysA.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
  }, [selectedRowKeysA]);
  useEffect(() => {
    var list = [];
    selectedRowKeysB.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
  }, [selectedRowKeysB]);

  useEffect(() => {
    requestThreeYearRatings();

    requestUsageTypeData();
    requestRegionData();
    requestRegionPieData();
    requestCriteriaGroupData();
    requestAreaByUsageData();
    return () => {};
  }, [selectedRowKeysA, selectedRowKeysB, CompareToBenchMark]);

  useEffect(() => {
    return () => {};
  }, [usageBarChartData]);

  const [pieChartRegionSource, setPieChartRegionSource] = useState("1");
  const [pieChartUsageTypeSource, setPieChartUsageTypeSource] = useState("1");

  useEffect(() => {
    var cubeName = CompareToBenchMark ? "IndexVsSetA" : "SetAVsSetB";
    var valueA = selectedRowKeysA;
    var valueB = selectedRowKeysB;
    var q1 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.usage_type`]: "desc",
      },
      dimensions: [`${cubeName}.usage_type`],
      filters: [],
    };
    var q2 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.region`]: "asc",
      },
      dimensions: [`${cubeName}.region`],
      filters: [],
    };
    var q3 = {
      measures: CompareToBenchMark
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.criteria_group`]: "asc",
      },
      dimensions: [`${cubeName}.criteria_group`],
      filters: [],
    };

    var q4 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.region"],
      filters: [],
    };
    var q5 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.usage_type"],
      filters: [],
    };

    if (CompareToBenchMark) {
      //use set A
      var pieFilters = [];
      if (valueA.length > 0) {
        pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }
      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        //use Benchmark
        q4.filters = [];
      }
      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        //use Benchmark
        q5.filters = [];
      }
    } else {
      var pieFilters = [];
      //use set A
      if (valueA.length > 0) {
        var pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }

      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q4.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }

      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q5.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }
    }

    var filters = [];
    if (CompareToBenchMark) {
      if (valueA.length > 0) {
        filters = [
          {
            or: [
              {
                dimension: "IndexVsSetA.row_type",
                operator: "equals",
                values: ["Benchmark"],
              },
              {
                and: [
                  {
                    dimension: "IndexVsSetA.row_type",
                    operator: "equals",
                    values: ["Set A"],
                  },
                  {
                    dimension: "IndexVsSetA.portfolio_id",
                    operator: "equals",
                    values: valueA.map((v) => {
                      return v.split("-")[1];
                    }),
                  },
                ],
              },
            ],
          },
        ];
      }
    } else {
      filters = [
        {
          or: [
            valueA.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["SetA"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set A"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueA.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
            valueB.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["Set B"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set B"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueB.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
          ],
        },
      ];
    }
    q1.filters = filters;
    q2.filters = filters;
    q3.filters = filters;
  }, [
    selectedRowKeysA,
    selectedRowKeysB,
    CompareToBenchMark,
    pieChartRegionSource,
    pieChartUsageTypeSource,
  ]);

  const [headerAffixClassName, setHeaderAffixClassName] = useState(
    "page-title title-affix"
  );
  const [isAffixed, setIsAffixed] = useState(false);

  useLayoutEffect(() => {
    setIsAffixed(false);
    return () => {
      setIsAffixed(false);
    };
  }, []);

  return (
    <>
      <Affix
        style={{ position: "absolute" }}
        offsetTop={0}
        onChange={(affixed) => {
          console.log(affixed);
          setIsAffixed(affixed);
          setHeaderAffixClassName(
            affixed
              ? "page-title title-affix title-with-bg"
              : "page-title title-affix"
          );
        }}
      ></Affix>
      <Affix offsetTop={0}>
        <Row
          className={"page-title"}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? "rgba(255,255,255,0.8)"
              : "rgba(255,255,255,0.0)",
            boxShadow: isAffixed
              ? "10px 1px 15px rgba(128,128,128,0.5)"
              : "none",
          }}
        >
          <Col flex={"auto"}>
            <Title className={isAffixed ? "page-title" : ""}>
              {" "}
              {t("client_dashboard.dashboard")}
            </Title>
          </Col>
          <Col span={16}></Col>
        </Row>
      </Affix>
      <Row gutter={[32, 24]}>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorA}
            setColor={setColorA}
            setName={"Portfolio Set A"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysA}
            setSelectedKeys={setSelectedRowKeysA}
            selectionMode={selectionModeA}
            setSelectionMode={setSelectionModeA}
          ></DashboardFilterBox>
        </Col>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorB}
            setColor={setColorB}
            setName={"Portfolio Set B"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysB}
            setSelectedKeys={setSelectedRowKeysB}
            selectionMode={selectionModeB}
            setSelectionMode={setSelectionModeB}
          ></DashboardFilterBox>
        </Col>
      </Row>

      <Row gutter={[32, 24]}>
        <Col span={8} lg={8} md={24} sm={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.nutzungen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByUsageBenchmark",
              set: "RatingByUsageSetWIthName",
              "pf-1": "RatingByUsagePortfolioType",
              "pf-2": "RatingByUsagePortfolioType",
              "pf-3": "RatingByUsagePortfolioType",
              "pf-4": "RatingByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>

        <Col span={16} lg={16} md={24} sm={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.regionen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByRegionBenchmark",
              set: "RatingByRegionSetWithName",
              "pf-1": "RatingByRegionPortfolioType",
              "pf-2": "RatingByRegionPortfolioType",
              "pf-3": "RatingByRegionPortfolioType",
              "pf-4": "RatingByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
      </Row>

      <Row gutter={[32, 24]} style={{ marginBottom: "35px" }}>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.bereich")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByCriteriaGroupBenchmark",
              set: "RatingByCriteriaGroupSetWithName",
              "pf-1": "RatingByCriteriaGroupPortfolioType",
              "pf-2": "RatingByCriteriaGroupPortfolioType",
              "pf-3": "RatingByCriteriaGroupPortfolioType",
              "pf-4": "RatingByCriteriaGroupPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.flache")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "AreaByUsageBenchmark",
              set: "AreaByUsageSetWithName",
              "pf-1": "AreaByUsagePortfolioType",
              "pf-2": "AreaByUsagePortfolioType",
              "pf-3": "AreaByUsagePortfolioType",
              "pf-4": "AreaByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.gebäude")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "TotalAreaByRegionBenchmark",
              set: "TotalAreaByRegionSetWithName",
              "pf-1": "TotalAreaByRegionPortfolioType",
              "pf-2": "TotalAreaByRegionPortfolioType",
              "pf-3": "TotalAreaByRegionPortfolioType",
              "pf-4": "TotalAreaByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
      </Row>
    </>
  );
}

export default observer(Dashboard);
