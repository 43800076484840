import { types } from "mobx-state-tree";


const PotentialPropertyRatingModel = types
  .model('PotentialPropertyRatingModel',{
    id: types.optional(types.number, 0),
    key: types.optional(types.string, ""),
    value: types.optional(types.number, 0),
    comment: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setValues(values) {
      for (var p in values) {
        self[p] = values[p];
      }
    },
  }));

export default PotentialPropertyRatingModel;
