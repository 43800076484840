import { types } from "mobx-state-tree";


const InvestmentTypeModel = types
  .model('InvestmentTypeModel',{
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
   
  
  })
  .actions((self) => ({
   

    setName(value) {
      self.name = value;
    },

   
  }));

export default InvestmentTypeModel;
