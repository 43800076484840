import { types, flow, cast } from "mobx-state-tree";
import axios from "axios";
import LoginModel from "./LoginModel";
import CompanyModel from "./CompanyModel";
import PortfolioModel from "./PortfolioModel";
import PortfolioUserAccessModel from "./PortfolioUserAccessModel";
import PropertyModel from "./PropertyModel";
import InvestmentTypesModel from "./InvestmentTypeModel";
import PropertyRatingModel from "./PropertyRatingModel";
import PotentialPropertyRatingModel from "./PotentialPropertyRatingModel";
import { getCompanyRating } from "../data/RatingCriteria";
import PropertyAttributeModel from "./PropertyAttributeModel";
import DefaultPropertyRatingValueModel from "./DefaultPropertyRatingValueModel";
import PropertyAuditLogModel from "./PropertyAuditLogModel";

// import { toJS } from "mobx";
import fileDownload from "js-file-download";

const CLEAR_CACHE = 2;

// let Clients = [];

const RootStore = types
  .model("RootStore", {
    appName: types.optional(types.string, "SSREI-Portfoliobewertungstool"),
    appVersion: types.optional(types.string, "v1.1"),
    language: types.optional(types.string, "de"),
    login: types.optional(LoginModel, {}),
    company: types.optional(CompanyModel, {}),
    clients: types.optional(types.array(CompanyModel), []),
    users: types.optional(types.array(LoginModel), []),
    properties: types.optional(types.array(PropertyModel), []),
    portfolios: types.optional(types.array(PortfolioModel), []),
    downloadPortfolios: types.optional(types.array(PortfolioModel), []),
    downloadSnapshots: types.optional(types.array(PortfolioModel), []),
    portfolioUsersAccessList: types.optional(
      types.array(PortfolioUserAccessModel),
      []
    ),
    userPortfoliosAccessRights: types.optional(
      types.array(PortfolioUserAccessModel),
      []
    ),
    properties: types.optional(types.array(PropertyModel), []),
    investmentTypes: types.optional(types.array(InvestmentTypesModel), []),

    activeClientId: types.optional(types.number, 0),
    activeUserId: types.optional(types.number, 0),
    activePortfolioId: types.optional(types.number, 0),
    activePropertyId: types.optional(types.number, 0),

    activeAdminCompanyId: types.optional(types.number, 0),
    // apiKey: types.optional(types.string, ""),

    /**/

    administrators: types.optional(types.array(CompanyModel), []),
    clientListUpdateOn: types.optional(types.string, ""),
    /* Utility Lists*/
    propertyAttributeMasters: types.optional(
      types.array(PropertyAttributeModel),
      []
    ),
    propertyDefaultRatings: types.optional(
      types.array(DefaultPropertyRatingValueModel),
      []
    ),
  })
  .actions((self) => ({
    setLanguage(locale) {
      self.language = locale;
      localStorage.setItem("lang", locale);
    },
    setLocale(locale) {
      localStorage.setItem("locale", locale);
    },
    /*  addTodo(id, name) {
            self.todos.set(id, Todo.create({ name }))
        } */
    initialize: flow(function* () {
      // This should check if the currently stored login data is still valid.
      var token = localStorage.getItem("token");
      axios.defaults.headers.common["X-Auth-Token"] = token;
      // if there is a token, validate from server if not expired..

      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post("/api/auth/token", { token })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) {
        localStorage.removeItem("token");
        token = null;
        localStorage.removeItem("user");
        localStorage.removeItem("company");
      } else {
        localStorage.setItem("token", result.token);
      }

      // var expired = false;

      var user = token ? JSON.parse(localStorage.getItem("user")) : {};

      self.company = CompanyModel.create(
        token
          ? JSON.parse(localStorage.getItem("company"))
          : {
              id: 0,
              name: "",
              address: "",
              plz: "",
              city: "",
              canton: "",
              type: -1,
              status: "",
            }
      );
      self.login = LoginModel.create(
        token
          ? {
              id: user.id,
              token: token,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,

              companyId: user.companyId,
              position: user.position,
              phoneNumber: user.phoneNumber,
              mobileNumber: user.mobileNumber,

              isLoggedIn: true,
            }
          : {
              id: 0,
              token: localStorage.getItem("token") || "",
              email: "",
              firstName: "",
              lastName: "",

              companyId: 0,
              position: "",
              phoneNumber: "",
              mobileNumber: "",
              isLoggedIn: localStorage.getItem("token") ? true : false,
            }
      );

      self.clients = [];

      /*  self.portfolios.push(
        PortfolioModel.create({
          companyId: 14,
          id: 1,
          investmentType: 2,
          name: "Ports Folious",
        })
      ); */
      //INvestment Types array
      self.investmentTypes.push(
        InvestmentTypesModel.create({ id: 1, name: "Real estate funds" })
      );
      self.investmentTypes.push(
        InvestmentTypesModel.create({
          id: 2,
          name: "Real estate public limited companies",
        })
      );
      self.investmentTypes.push(
        InvestmentTypesModel.create({
          id: 3,
          name: "Investment foundations and pension funds",
        })
      );
      self.investmentTypes.push(
        InvestmentTypesModel.create({
          id: 4,
          name: "Others",
        })
      );

      self.getPropertyAttributeList().then((success) => {
        // console.log(self.propertyAttributes)
      });
    }),

    setClearCache: async () => {
      let clearCacheLocalStorage = localStorage.getItem("clearCache");

      if (CLEAR_CACHE !== parseInt(clearCacheLocalStorage)) {
        // localStorage.clear();
        localStorage.setItem("clearCache", CLEAR_CACHE);
        window.location.reload(true);
        console.log("An Update is detected, cache cleared...");
      }
    },

    setCompany: flow(function* (id, name, address, plz, city, canton, type) {
      self.company = CompanyModel.create({
        id: id,
        name: name,
        address: address,
        plz: plz,
        city: city,
        canton: canton,
        type: type,
      });
      return true;
    }),

    clientExists: flow(function* (name) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/clients/exists", {
          name,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result) {
        return { exists: true, client: result };
      } else return { exists: false };
    }),
    // sort alpha numeric v2
    sortFn: function (a, b) {
      // console.log(a, "a");
      var ax = [],
        bx = [];
      a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
      });
      while (ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }
      return ax.length - bx.length;
    },
    getClients: flow(function* () {
      let result = {};

      const {
        data,
        status: responseStatus,
        statusText,
      } = yield axios
        .get("/api/clients/", {})
        .then((response) => response)
        .catch((error) => error.response);
      result.clients = data.clients;

      if (responseStatus !== 200) return false;

      if (result.clients.length > 0) {
        self.clients = [];
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.properties.length;
        };
        result.clients.forEach((c) => {
          const {
            id,
            name,
            address,
            plz,
            city,
            canton,
            region,
            type,
            status,
            users,
          } = c;
          var client = CompanyModel.create({
            id: id,
            name,
            address,
            plz,
            city,
            canton,
            region,

            status,
            portfolioCountCached: c.portfolios.length,
            propertyCountCached: c.portfolios.reduce(reducer, 0),
            type: 1,
            portfolios: c.portfolios.map((pf) => {
              return PortfolioModel.create({
                ...pf,
                properties: pf.properties
                  .sort((a, b) => self.sortFn(a.name, b.name))
                  .map((pp) => {
                    return PropertyModel.create({
                      ...pp,
                      areaCommercial: parseFloat(pp.areaCommercial),
                      areaOffice: parseFloat(pp.areaOffice),
                      areaResidential: parseFloat(pp.areaResidential),
                      areaBuilding: parseFloat(pp.areaBuilding),
                      areaHealth: parseFloat(pp.areaHealth),
                      ratings: pp.ratings.map((r) => {
                        return PropertyRatingModel.create({
                          ...r,
                          value: parseFloat(r.value),
                        });
                      }),
                      propertyAttributes: pp.propertyAttributeDetails
                        ? pp.propertyAttributeDetails.map(
                            (att) => att.attribute_id
                          )
                        : [],
                      auditLogs: pp.auditLogs
                        ? pp.auditLogs.map((r) =>
                            PropertyAuditLogModel.create({
                              id: r.id,
                              auditDate: r.auditDate,
                              comment: r.comment,
                            })
                          )
                        : [],
                    });
                  }),
              });
            }),
            ratingCached: getCompanyRating(c),
            users: users.map((u) => {
              return LoginModel.create({
                ...u,
                token: "",
                isLoggedIn: false,
                isAdmin: true,
              });
            }),
          });

          self.clients.push(client);
        });

        return true;
      } else return false;
    }),

    getClientsCSV: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .get("/api/downloads", {})

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      fileDownload(result.data, result.fileName);

      return true;
    }),

    getClient: flow(function* (id) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/clients/${id}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.client) {
        // self.clients = [];
        var c = result.client;
        var client = CompanyModel.create({
          ...c,
          type: 1,
        });
        self.company = client;

        return client;
      } else return false;
    }),
    addClient: flow(function* (name, address, plz, city, canton, region) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post("/api/clients/", {
          name,
          address,
          plz,
          city,
          canton,
          region,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.client) {
        var client = CompanyModel.create({
          ...result.client,
          type: 1,
        });
        self.clients.push(client);

        return client;
      } else return result;
    }),

    setMultipleClientStatus: flow(function* (status, ids) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post(`/api/clients/setStatusMultiple`, { status, ids })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.clients) {
        result.clients.forEach((c) => {
          var LocalClient = self.clients.find((lc) => lc.id === c.id);
          if (LocalClient) LocalClient.status = c.status;
        });
        result.clients.forEach((c) => {
          var LocalClient = self.administrators.find((lc) => lc.id === c.id);
          if (LocalClient) LocalClient.status = c.status;
        });

        return true;
      } else return false;
    }),

    getUsers: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get("/api/users/", {})

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.users) {
        self.users = [];
        result.users.forEach((c) => {
          var user = LoginModel.create({
            ...c,
            token: "",
            isLoggedIn: false,
            isAdmin: false,
          });
          self.users.push(user);
        });

        return true;
      } else return false;
    }),
    getUsersByCompanyId: flow(function* (companyId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/users/bycompany/${companyId}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.users) {
        self.users = [];
        result.users.forEach((c) => {
          var user = LoginModel.create({
            ...c,
            token: "",
            isLoggedIn: false,
            isAdmin: false,
          });
          self.users.push(user);
        });

        return true;
      } else return false;
    }),

    addUser: flow(function* (
      lastName,
      firstName,
      phoneNumber,
      mobileNumber,
      email,
      companyId,
      position,
      password,
      portfoliosWithAccess = [],
      sendInviteEmail
    ) {
      const language = self.language;
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post("/api/users/register", {
          lastName,
          firstName,
          email,
          phoneNumber,
          mobileNumber,
          companyId,
          position,
          password,
          portfoliosWithAccess,
          sendInviteEmail,
          language,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return { ...result, status: 409 } || false;

      if (result.user) {
        var user = LoginModel.create({
          ...result.user,
          token: "",
          isLoggedIn: false,
        });
        self.users.push(user);

        return user;
      } else return false;
    }),
    removeUser: flow(function* (id, permanent = 0) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .delete(`/api/users/${id}/${permanent}`, {})
        .then((response) => response)
        .catch((error) => error.response);

      var status = false;
      if (responseStatus !== 200) status = false;
      else status = result || false;

      if (status === true) {
        if (permanent === 1) {
          self.users.splice(
            self.users.findIndex((u) => u.id === id),
            1
          );
        } else {
          self.users.find((u) => u.id === id).setStatus(false);
        }
      }

      return status;
    }),

    activateUser: flow(function* (id) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post(`/api/users/activate`, { id })
        .then((response) => response)
        .catch((error) => error.response);

      var status = false;
      if (responseStatus !== 200) status = false;
      else status = result || false;

      self.users.find((u) => u.id === id).setStatus(status);

      return status;
    }),

    //POrtfolios
    getPortfolios: flow(function* () {
      if (self.company.type === 0) {
        if (window.location.pathname.indexOf("/ClientDashboard") > -1) {
          const [x, y, z] = window.location.pathname.split("/");

          const {
            data: result,
            status: responseStatus,
            // statusText,
          } = yield axios
            .get(`/api/portfolios/is-company/${z}`)

            .then((response) => response)
            .catch((error) => error.response);

          //setTimeout(function(){
          // self.status =isNaN(result)?1:result;
          if (responseStatus !== 200) return false;

          if (result.portfolios) {
            self.portfolios = [];
            result.portfolios.forEach((c) => {
              var portfolio = PortfolioModel.create({
                ...c,
              });
              self.portfolios.push(portfolio);
            });

            return true;
          } else return false;
        } else {
          const {
            data: result,
            status: responseStatus,
            // statusText,
          } = yield axios
            .get(`/api/portfolios`)

            .then((response) => response)
            .catch((error) => error.response);

          //setTimeout(function(){
          // self.status =isNaN(result)?1:result;
          if (responseStatus !== 200) return false;

          if (result.portfolios) {
            self.portfolios = [];
            result.portfolios.forEach((c) => {
              var portfolio = PortfolioModel.create({
                ...c,
              });
              self.portfolios.push(portfolio);
            });

            return true;
          } else return false;
        }
      } else {
        const {
          data: result,
          status: responseStatus,
          // statusText,
        } = yield axios
          .get(`/api/portfolios/is-client/${self.login.id}`)

          .then((response) => response)
          .catch((error) => error.response);

        // console.log(result);

        //setTimeout(function(){
        // self.status =isNaN(result)?1:result;
        if (responseStatus !== 200) return false;

        if (result.portfolios) {
          self.portfolios = [];
          result.portfolios.forEach((c) => {
            var portfolio = PortfolioModel.create({
              ...c,
            });
            self.portfolios.push(portfolio);
          });

          return true;
        } else return false;
      }
    }),
    getDownloadPortfolios: flow(function* () {
      let _portfolioURL = "";
      let _snapshotURL = "";
      if (self.company.type === 0) {
        _portfolioURL = "/api/portfolios";
        _snapshotURL = "/api/portfolios/snapshots";
      } else if (self.company.type === 1) {
        _portfolioURL = `/api/portfolios/is-client/${self.login.id}`;
        _snapshotURL = `/api/portfolios/snapshots/is-client/${self.login.id}`;
      }

      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(_portfolioURL)
        .then((response) => response)
        .catch((error) => error.response);

      const { data: snap } = yield axios
        .get(_snapshotURL)
        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolios) {
        self.downloadPortfolios = cast(
          result.portfolios.filter((pf) => {
            return pf.company.status === "active";
          })
        );
        // self.downloadPortfolios = cast(result.portfolios);
        if (snap.snapshots) {
          self.downloadSnapshots = cast(snap.snapshots);
          return true;
        }

        return true;
      } else return false;
    }),

    getPortfoliosByCompany: flow(function* (companyId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/portfolios/bycompany/${companyId}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolios) {
        self.portfolios = [];
        result.portfolios.forEach((c) => {
          const {
            id,
            name,
            investmentType,
            companyId,
            properties,
            indexStatus,
          } = c;
          var portfolio = PortfolioModel.create({
            id,
            name,
            investmentType,
            companyId,
            indexStatus,
            properties: properties.map((p) => {
              return PropertyModel.create({
                id: p.id,
                name: p.name,
                portfolioId: p.portfolioId,
                address: p.address,
                age: p.age,
                lastTotalRenovation: p.lastTotalRenovation,
                plz: p.plz,
                city: p.city,
                canton: p.canton,
                region: p.region,
                areaOffice: parseFloat(p.areaOffice),
                areaResidential: parseFloat(p.areaResidential),
                areaCommercial: parseFloat(p.areaCommercial),
                areaBuilding: parseFloat(p.areaBuilding),
                areaHealth: parseFloat(p.areaHealth),
                ratings: p.ratings.map((r) =>
                  PropertyRatingModel.create({
                    id: r.id,
                    key: r.key,
                    value: parseFloat(r.value),
                    comment: r.comment,
                    potential_comment: r.potential_comment,
                    indexChanged: r.indexChanged,
                  })
                ),
                potentialRatings: p.potentialRatings.map((r) =>
                  PotentialPropertyRatingModel.create({
                    id: r.id,
                    key: r.key,
                    value: parseFloat(r.value),
                    comment: r.comment,
                  })
                ),
                propertyAttributes: p.propertyAttributeDetails
                  ? p.propertyAttributeDetails.map((att) => att.attribute_id)
                  : [],

                auditLogs: p.auditLogs
                  ? p.auditLogs.map((r) =>
                      PropertyAuditLogModel.create({
                        id: r.id,
                        auditDate: r.auditDate,
                        comment: r.comment,
                      })
                    )
                  : [],
              });
            }),
          });
          self.portfolios.push(portfolio);
        });

        return true;
      } else return false;
    }),
    getPortfoliosByUser: flow(function* (userId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/portfolios/byuser/${userId}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolios) {
        self.portfolios = [];
        result.portfolios.forEach((c) => {
          const {
            id,
            name,
            investmentType,
            companyId,
            properties,
            indexStatus,
          } = c;
          var portfolio = PortfolioModel.create({
            id,
            name,
            investmentType,
            companyId,
            indexStatus,
            properties: properties
              .sort((a, b) => self.sortFn(a.name, b.name))
              .map((p) => {
                return PropertyModel.create({
                  id: p.id,
                  name: p.name,
                  portfolioId: p.portfolioId,
                  address: p.address,
                  age: p.age,
                  lastTotalRenovation: p.lastTotalRenovation,
                  plz: p.plz,
                  city: p.city,
                  canton: p.canton,
                  region: p.region,
                  areaOffice: parseFloat(p.areaOffice),
                  areaResidential: parseFloat(p.areaResidential),
                  areaCommercial: parseFloat(p.areaCommercial),
                  areaBuilding: parseFloat(p.areaBuilding),
                  areaHealth: parseFloat(p.areaHealth),
                  ratings: p.ratings.map((r) =>
                    PropertyRatingModel.create({
                      id: r.id,
                      key: r.key,
                      value: parseFloat(r.value),
                      comment: r.comment,
                      potential_comment: r.potential_comment,
                      indexChanged: r.indexChanged,
                    })
                  ),
                  potentialRatings: p.potentialRatings.map((r) =>
                    PotentialPropertyRatingModel.create({
                      id: r.id,
                      key: r.key,
                      value: parseFloat(r.value),
                      comment: r.comment,
                    })
                  ),
                  propertyAttributes: p.propertyAttributeDetails
                    ? p.propertyAttributeDetails.map((att) => att.attribute_id)
                    : [],
                  auditLogs: p.auditLogs
                    ? p.auditLogs.map((r) =>
                        PropertyAuditLogModel.create({
                          id: r.id,
                          auditDate: r.auditDate,

                          comment: r.comment,
                        })
                      )
                    : [],
                });
              }),
          });
          self.portfolios.push(portfolio);
        });

        return true;
      } else return false;
    }),
    addPortfolio: flow(function* (
      name,
      investmentType,
      companyId,
      usersWithAccess = []
    ) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post("/api/portfolios/", {
          name,
          investmentType,
          companyId,
          usersWithAccess,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolio) {
        var portfolio = PortfolioModel.create({
          ...result.portfolio,
        });
        self.portfolios.push(portfolio);

        return portfolio;
      } else return false;
    }),
    removePortfolio: flow(function* (portfolio) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post(`/api/portfolios/delete`, { portfolioId: portfolio.id })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        self.portfolios.splice(self.portfolios.indexOf(portfolio), 1);

        return true;
      } else return false;
    }),
    removeProperty: flow(function* (property) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .post(`/api/properties/delete`, { propertyId: property.id })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        self.properties.splice(self.properties.indexOf(property), 1);

        return true;
      } else return false;
    }),
    clearPortfolios: flow(function* () {
      self.portfolios = [];
      self.properties = [];
      return true;
    }),
    //PortfolioAccess
    getPortfolioUserAccessByPortfolioId: flow(function* (portfolioId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/portfoliouseraccess/byportfolio/${portfolioId}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolioUsersAccessList) {
        self.portfolioUsersAccessList = [];
        result.portfolioUsersAccessList.forEach((c) => {
          var pua = PortfolioUserAccessModel.create({
            ...c,
          });
          self.portfolioUsersAccessList.push(pua);
        });

        return true;
      } else return false;
    }),
    getPortfolioUserAccessByUserId: flow(function* (userId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/portfoliouseraccess/byuser/${userId}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolioUsersAccessList) {
        // Note that this uses a different list..
        self.userPortfoliosAccessRights = [];
        result.portfolioUsersAccessList.forEach((c) => {
          var pua = PortfolioUserAccessModel.create({
            ...c,
          });
          self.userPortfoliosAccessRights.push(pua);
        });

        return true;
      } else return false;
    }),

    //Properties
    getPropertiesByCompany: flow(function* (companyId) {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get(`/api/properties/bycompany/${companyId}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.properties) {
        self.properties = [];
        result.properties.forEach((c) => {
          const {
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice,
            areaResidential,
            areaCommercial,
            areaBuilding,
            areaHealth,
            propertyAttributeDetails,
            auditLogs,
          } = c;
          var property = PropertyModel.create({
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice: parseFloat(areaOffice),
            areaResidential: parseFloat(areaResidential),
            areaCommercial: parseFloat(areaCommercial),
            areaBuilding: parseFloat(areaBuilding),
            areaHealth: parseFloat(areaHealth),
            propertyAttributes: propertyAttributeDetails
              ? propertyAttributeDetails.map((att) => att.attribute_id)
              : [],
            auditLogs: auditLogs
              ? auditLogs.map((r) =>
                  PropertyAuditLogModel.create({
                    id: r.id,
                    auditDate: r.auditDate,
                    comment: r.comment,
                  })
                )
              : [],
          });
          self.properties.push(property);
        });

        return true;
      } else return false;
    }),

    /// add to the property list in the store directly..
    insertProperty: flow(function* (property) {
      self.properties.push(property);
      return true;
    }),

    /// INdex
    generateCurrentMonthIndex: flow(function* () {
      try {
        const {
          data: result,
          status: responseStatus,
          // statusText,
        } = yield axios
          .post(`/api/portfolios/generatemonthlyindex`, {})
          .then((response) => response)
          .catch((error) => error.response);

        if (responseStatus !== 200) return false;

        // update object's property?

        return true;
      } catch (error) {
        return false;
      }
    }),

    setClientListUpdateOn(value) {
      self.clientListUpdateOn = value;
    },

    setActiveClientId(value) {
      self.activeClientId = value;

      //reset
      /*  self.activeUserId = 0;
      self.activePortfolioId = 0;
      self.activePropertyId = 0; */
    },
    setActiveUserId(value) {
      self.activeUserId = value;
    },
    setActivePortfolioId(value) {
      self.activePortfolioId = value;
      //reset
      /*  self.activePropertyId = 0; */
    },
    setActivePropertyId: flow(function* (value) {
      self.activePropertyId = value;
      return true;
    }),

    setActiveAdminCompanyId: flow(function* (value) {
      self.activeAdminCompanyId = value;
      return true;
    }),

    // setApiKey: flow(function* (value) {
    //   self.apiKey = value;
    //   return true;
    // }),

    getAdministratorCompanies: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .get("/api/management/companies", {})

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.administrators) {
        self.administrators = [];
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.properties.length;
        };
        result.administrators.forEach((c) => {
          const {
            id,
            name,
            address,
            plz,
            city,
            canton,
            region,
            type,
            status,
            users,
          } = c;
          var client = CompanyModel.create({
            id: id,
            name,
            address,
            plz,
            city,
            canton,
            region,
            type,
            type: 0,
            status,
            users: users.map((u) => {
              return LoginModel.create({
                ...u,
                token: "",
                isLoggedIn: false,
                isAdmin: true,
              });
            }),
          });
          self.administrators.push(client);
        });

        return true;
      } else return false;
    }),
    addAdministratorCompany: flow(function* (
      name,
      address,
      plz,
      city,
      canton,
      region
    ) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/management/companies/add", {
          name,
          address,
          plz,
          city,
          canton,
          region,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.administrator) {
        var client = CompanyModel.create({
          ...result.administrator,
          type: 0,
        });
        self.administrators.push(client);

        return client;
      } else return false;
    }),

    getPropertyAttributeList: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        // statusText,
      } = yield axios
        .get("/api/propertyattributemaster/")

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.propertyAttributeMasters) {
        self.propertyAttributeMasters = [];
        result.propertyAttributeMasters.forEach((c) => {
          var pa = PropertyAttributeModel.create({
            ...c,
          });
          self.propertyAttributeMasters.push(pa);
        });
        if (result.propertyDefaultRatingValues) {
          self.propertyDefaultRatings = [];
          result.propertyDefaultRatingValues.forEach((c) => {
            var pa = DefaultPropertyRatingValueModel.create({
              ...c,
            });
            self.propertyDefaultRatings.push(pa);
          });
        }

        return true;
      } else return false;
    }),
  }))
  .views((self) => ({
    /*  get clientCompanies() {
      return self.clients;
    }, */
    get activeClientCompany() {
      return self.activeClientId > 0
        ? self.properties.find((pp) => pp.id === self.activeClientId)
        : null;
    },

    get activeClients() {
      return self.clients.filter((pp) => pp.status === "active");
    },
    get activeAdmins() {
      return self.administrators.filter((pp) => pp.status === "active");
    },
    checkboxOptionsPortfolio(checkedList) {
      const _indexSelection = {
        indexed: checkedList.includes("Verifiziert") ? 1 : 0,
        nonIndexed: checkedList.includes("Nicht verifiziert") ? 1 : 0,
      };
      let _portfolios = self.downloadPortfolios.reduce((_arr, portfolio) => {
        if (_indexSelection.indexed === 1 && _indexSelection.nonIndexed === 0) {
          if (portfolio.indexStatus === 1) {
            _arr = [
              ..._arr,
              {
                title: portfolio.name,
                value: portfolio.id,
                indexStatus: portfolio.indexStatus,
                companyId: portfolio.companyId,
              },
            ];
          }
        }

        if (_indexSelection.nonIndexed === 1) {
          _arr = [
            ..._arr,
            {
              title: portfolio.name,
              value: portfolio.id,
              indexStatus: portfolio.indexStatus,
              companyId: portfolio.companyId,
            },
          ];
        }

        return _arr;
      }, []);

      let _snapshots = self.downloadSnapshots.reduce((_arr, snapshot) => {
        if (_indexSelection.indexed === 1)
          if (snapshot.indexStatus === 1) {
            _arr = [
              ..._arr,
              {
                title: snapshot.name,
                value: snapshot.id,
                indexStatus: snapshot.indexStatus,
                companyId: snapshot.companyId,
              },
            ];
          }
        return _arr;
      }, []);
      let _results = [..._snapshots, ..._portfolios];
      return _results.filter(
        (result, index, self) =>
          index === self.findIndex((r) => r.value === result.value)
      );
    },
    get defaultCheckboxOptionsPortfolio() {
      return self.portfolios.map((portfolio) => {
        return portfolio.id;
      });
    },
  }));

export default RootStore;
