import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import {
  Row,
  Col,
  Button,
  Tooltip,
  Space,
  Table,
  Popconfirm,
  message,
} from "antd";

import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ResizableTitle from "../Common/ResizableTitle";
import { getPropertyRating } from "../../data/RatingCriteria";
//translation
import { useTranslation } from "react-i18next";

const getAreaPercentage = (value, self) => {
  var total =
    self.areaOffice +
    self.areaResidential +
    self.areaCommercial +
    self.areaBuilding +
    self.areaHealth;
  if (total === 0) return 0;
  return value / total;
};

const getAreaTotal = (self) => {
  return (
    self.areaOffice +
    self.areaResidential +
    self.areaCommercial +
    self.areaBuilding +
    self.areaHealth
  );
};

// sorting alphanumeric names
// const reA = /[^a-zA-Z]/g; //find letters
// const reN = /[^0-9]/g; //find numbers
// function sortAlphaNum(a, b) {
//   var aA = a.replace(reA, ""); //a letters
//   var bA = b.replace(reA, ""); //b letters
//   if (aA === bA) {
//     var aN = parseInt(a.replace(reN, ""), 10); //a numbers
//     var bN = parseInt(b.replace(reN, ""), 10); //b numbers
//     return aN === bN ? 0 : aN > bN ? 1 : -1;
//   } else {
//     return aA > bA ? 1 : -1;
//   }
// }

// sort alpha numeric v2
function sortFn(a, b) {
  var ax = [],
    bx = [];
  a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
    ax.push([$1 || Infinity, $2 || ""]);
  });
  b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
    bx.push([$1 || Infinity, $2 || ""]);
  });
  while (ax.length && bx.length) {
    var an = ax.shift();
    var bn = bx.shift();
    var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }
  return ax.length - bx.length;
}

// sort for index changed properties
function sortRed(a, b) {
  const indexA = a.ratings?.find((r) => r.indexChanged === 1)?.indexChanged;
  const indexB = b.ratings?.find((r) => r.indexChanged === 1)?.indexChanged;
  if (indexA || indexB) {
    const indexChangedA = indexA ? indexA : 0;
    const indexChangedB = indexB ? indexB : 0;
    return indexChangedA > indexChangedB ? 1 : -1;
  } else if (!indexA && !indexB) {
    return sortFn(a.name, b.name);
  }
}

const getLatestAuditDate = (property) => {
  if (property.auditLogs.length > 0) {
    //not the latest entry but the most recent date entered.
    //return new Date(property.auditLogs[property.auditLogs.length-1].auditDate).getTime();
    var s = property.auditLogs.sort(
      (a, b) =>
        new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
    );
    return new Date(s[0].auditDate).getTime();
  }

  return 0;
};
const PropertyAuditLog = (props) => {
  //activeClientId: types.optional(types.number, 0),
  //activeUserId: types.optional(types.number, 0),
  //activePortfolioId: types.optional(types.number, 0),

  const { store, property, portfolioId } = props;
  const [AuditLogs, setAuditLogs] = useState({ auditDate: "", comment: "" });
  useEffect(() => {
    if (store.company.type == 0) {
      const p = store.clients
        .find((c) => c.id === store.activeClientId)
        .portfolios.find((pf) => pf.id === property.portfolioId)
        .properties.find((p) => p.id === property.id);
      /* if (p.auditLogs.length === 0) {
        p.getAuditLogs().then((s) => {
          setAuditLogs(
            p.auditLogs.length > 0
              ? p.auditLogs[p.auditLogs.length - 1]
              : { auditDate: "", comment: "" }
          );
         
        });
      } else { */
      setAuditLogs(
        p.auditLogs.length > 0
          ? p.auditLogs.sort(
              (a, b) =>
                new Date(b.auditDate).getTime() -
                new Date(a.auditDate).getTime()
            )[0]
          : { auditDate: "", comment: "" }
      );
      //}
    } else {
      const px = store.portfolios
        .find((pf) => pf.id === property.portfolioId)
        .properties.find((p) => p.id === property.id);
      /* if (px.auditLogs.length === 0) {
        px.getAuditLogs().then((s) => {
          setAuditLogs(
            px.auditLogs.length > 0
              ? px.auditLogs[px.auditLogs.length - 1]
              : { auditDate: "", comment: "" }
          );
        });
      } else { */

      if (px.auditLogs.length > 0) {
        var log = px.auditLogs.sort(
          (a, b) =>
            new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()
        )[0];

        setAuditLogs(log);
      } else {
        setAuditLogs({ auditDate: "", comment: "" });
      }
      /* } */
    }

    return () => {
      setAuditLogs({ auditDate: "", comment: "" });
    };
  }, [property]);
  return (
    <Tooltip color={"#435c77"} title={AuditLogs.comment}>
      {AuditLogs.auditDate.trim().length > 0
        ? new Date(AuditLogs.auditDate).toLocaleDateString("de", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        : ""}
    </Tooltip>
  );
};

// let _columnSizes = localStorage.getItem(`columnSizes${store.language}`)? JSON.parse(localStorage.getItem(`columnSizes${store.language}`)): null
const PropertyTableRow = ({
  row,
  searchKeyword,
  portfolios,
  addNewProperty,
  editProperty,
  // getCantonName,
  store,
  showActualRatingsForm,
}) => {
  const { t } = useTranslation("common"); //translation

  // hide usage type column if specified usagetype total for all property is 0
  useEffect(() => {
    const initialValue = 0;
    setColumns(PropertyColumns);
    getTotalUsageTypes(initialValue);
    // getTotalCommercial(initialValue);
  }, [row]);

  function getTotalUsageTypes(initialValue) {
    //wohnen total
    const totalResidential = row.properties.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.areaResidential),
      initialValue
    );
    if (totalResidential === 0)
      // setColumns((columns) => columns.filter((_, index) => index !== 7));
      setColumns((columns) =>
        columns.filter((column) => column.key !== "wohnen")
      );

    //buro total
    const totalOffice = row.properties.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.areaOffice),
      initialValue
    );
    if (totalOffice === 0)
      setColumns((columns) =>
        columns.filter((column) => column.key !== "buro")
      );

    //gewerbe total
    const totalCommercial = row.properties.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.areaCommercial),
      initialValue
    );
    if (totalCommercial === 0)
      // setColumns((columns) => columns.filter((_, index) => index !== 7));
      setColumns((columns) =>
        columns.filter((column) => column.key !== "gewerbe")
      );

    //bildung total
    const totalBuilding = row.properties.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.areaBuilding),
      initialValue
    );
    if (totalBuilding === 0)
      // setColumns((columns) => columns.filter((_, index) => index !== 7));
      setColumns((columns) =>
        columns.filter((column) => column.key !== "bildung")
      );

    //gesundheit total
    const totalHealth = row.properties.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.areaHealth),
      initialValue
    );
    if (totalHealth === 0)
      // setColumns((columns) => columns.filter((_, index) => index !== 7));
      setColumns((columns) =>
        columns.filter((column) => column.key !== "gesundheit")
      );
  }

  // get columnSizes from localStorage
  let _columnSizes = localStorage.getItem(`columnSizes`)
    ? JSON.parse(localStorage.getItem(`columnSizes`))
    : null;

  function onConfirmDeleteProperty(row) {
    var property = portfolios
      .find((pf) => pf.id === row.portfolioId)
      .properties.find((pp) => pp.id === row.id);
    if (property)
      property.remove().then((success) => {
        if (success) {
          store.setClientListUpdateOn(new Date().getTime().toString());
          message.success(t("property.property_deleted"));
        } else {
          message.success(t("property.failed_delete_property"));
        }
      });
    else {
      message.success(t("property.property_not_found"));
    }
  }

  function onCancelDeleteProperty(e) {
    console.log(e);
  }

  const PropertyColumns = [
    {
      title: t("property.action"),
      key: "action",
      width: 50,
      sorter: (a, b) => sortRed(a, b),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) =>
        showActualRatingsForm && (
          <Button
            type={"text"}
            onClick={() => {
              showActualRatingsForm(record.portfolioId, record.id);
            }}
            icon={<EditOutlined />}
          ></Button>
        ),
    },
    {
      title: t("property.property_id"),
      dataIndex: "id",
      width: _columnSizes ? _columnSizes["propertyId"] : 100,
      key: "id",
      // sorter: (a, b) => sortAlphaNum(a.name, b.name),
      sorter: (a, b) => sortFn(a.name, b.name),
      // sorter: (a, b) => a.name.localeCompare(b.name, "en", { numeric: true }),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],

      render: (text, record) => (
        <a
          key={record.id}
          onClick={() => {
            store.setActivePortfolioId(record.portfolioId);
            editProperty(record.portfolioId, record.id);
          }}
        >
          <span>{`${record.name}`}</span>
        </a>
      ),
    },
    {
      title: t("property.address"),
      dataIndex: "address",
      key: "address",
      width: _columnSizes ? _columnSizes["address"] : 130,
      sorter: (a, b) => a.address.localeCompare(b.address),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <span key={record.id}>{`${record.address}`}</span>
      ),
    },
    {
      title: t("property.plz"),
      dataIndex: "plz",
      key: "plz",
      width: _columnSizes ? _columnSizes["plz"] : 60,
      sorter: (a, b) => a.plz.localeCompare(b.plz),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => <span key={record.id}>{`${record.plz}`}</span>,
    },
    {
      title: t("property.ort"),
      dataIndex: "id",
      width: _columnSizes ? _columnSizes["ort"] : 100,
      key: "id",
      sorter: (a, b) => a.city.localeCompare(b.city),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => <span key={record.id}>{`${record.city}`}</span>,
    },
    {
      title: t("property.canton"),
      dataIndex: "id",
      width: _columnSizes ? _columnSizes["canton"] : 60,
      key: "id",
      sorter: (a, b) => a.canton.localeCompare(b.canton),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          <span key={record.id}>{`${record.canton}`}</span>
        </div>
      ),
    },
    {
      title: t("property.m2"),
      dataIndex: "m2",
      key: "m2",
      width: _columnSizes ? _columnSizes["m2"] : 80,
      sorter: (a, b) => a.areaTotal - b.areaTotal,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          <span key={record.id}>
            {`${parseInt(record.areaTotal).toLocaleString("de-ch")}`}
            {/* {`${record.areaTotal.toLocaleString("de-ch", {
            minimumFractionDigits: 0,
          })}`} */}
          </span>
        </div>
      ),
    },
    {
      title: t("property.wohnen"),
      key: "wohnen",
      children: [
        {
          title: (
            <>
              m<sup>2</sup>
            </>
          ),
          dataIndex: "areaResidential",
          key: "areaResidential",
          width: 70,
          sorter: (a, b) => a.areaResidential - b.areaResidential,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseInt(record.areaResidential).toLocaleString("de-ch")}`}
              {/* {record.areaResidential.toLocaleString("de-ch", {
                minimumFractionDigits: 0,
              })} */}
            </div>
          ),
        },
        {
          title: "% ",
          dataIndex: "areaResidentialPercentage",
          key: "areaResidentialPercentage",
          width: 50,
          sorter: (a, b) =>
            a.areaResidentialPercentage - b.areaResidentialPercentage,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseFloat(record.areaResidentialPercentage * 100).toFixed(
                0
              )}%`}
            </div>
          ),
        },
      ],
    },
    {
      title: t("property.büro"),
      key: "buro",
      children: [
        {
          title: (
            <>
              m<sup>2</sup>
            </>
          ),
          dataIndex: "areaOffice",
          key: "areaOffice",
          width: 70,
          sorter: (a, b) => a.areaOffice - b.areaOffice,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseInt(record.areaOffice).toLocaleString("de-ch")}`}
              {/* {record.areaOffice.toLocaleString("de-ch", {
                minimumFractionDigits: 0,
              })} */}
            </div>
          ),
        },
        {
          title: "% ",
          dataIndex: "areaOffice",
          key: "areaOffice",
          width: 50,
          sorter: (a, b) => a.areaOffice - b.areaOffice,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseFloat(record.areaOfficePercentage * 100).toFixed(0)}%`}
            </div>
          ),
        },
      ],
    },
    {
      title: t("property.gewerbe"),
      key: "gewerbe",
      children: [
        {
          title: (
            <>
              m<sup>2</sup>
            </>
          ),
          dataIndex: "areaCommercial",
          key: "areaCommercial",
          width: 70,
          sorter: (a, b) => a.areaCommercial - b.areaCommercial,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseInt(record.areaCommercial).toLocaleString("de-ch")}`}
              {/* {record.areaCommercial.toLocaleString("de-ch", {
                minimumFractionDigits: 0,
              })} */}
            </div>
          ),
        },
        {
          title: "% ",
          dataIndex: "areaCommercialPercentage",
          key: "areaCommercialPercentage",
          width: 50,
          sorter: (a, b) =>
            a.areaCommercialPercentage - b.areaCommercialPercentage,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseFloat(record.areaCommercialPercentage * 100).toFixed(
                0
              )}%`}
            </div>
          ),
        },
      ],
    },
    {
      title: t("property.bildung"),
      key: "bildung",
      children: [
        {
          title: (
            <>
              m<sup>2</sup>
            </>
          ),
          dataIndex: "areaBuilding",
          key: "areaBuilding",
          width: 70,
          sorter: (a, b) => a.areaBuilding - b.areaBuilding,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseInt(record.areaBuilding).toLocaleString("de-ch")}`}
              {/* {record.areaBuilding.toLocaleString("de-ch", {
                minimumFractionDigits: 0,
              })} */}
            </div>
          ),
        },
        {
          title: "% ",
          dataIndex: "areaBuildingPercentage",
          key: "areaBuildingPercentage",
          width: 50,
          sorter: (a, b) => a.areaBuildingPercentage - b.areaBuildingPercentage,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseFloat(record.areaBuildingPercentage * 100).toFixed(0)}%`}
            </div>
          ),
        },
      ],
    },
    {
      title: t("property.gesundheit"),
      key: "gesundheit",
      children: [
        {
          title: (
            <>
              m<sup>2</sup>
            </>
          ),
          dataIndex: "areaHealth",
          key: "areaHealth",
          width: 70,
          sorter: (a, b) => a.areaHealth - b.areaHealth,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseInt(record.areaHealth).toLocaleString("de-ch")}`}
              {/* {record.areaHealth.toLocaleString("de-ch", {
                minimumFractionDigits: 0,
              })} */}
            </div>
          ),
        },
        {
          title: "% ",
          dataIndex: "areaHealthPercentage",
          key: "areaHealthPercentage",
          width: 50,
          sorter: (a, b) => a.areaHealthPercentage - b.areaHealthPercentage,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {`${parseFloat(record.areaHealthPercentage * 100).toFixed(0)}%`}
            </div>
          ),
        },
      ],
    },
    {
      title: t("property.rating"),
      dataIndex: "rating",
      key: "rating",
      width: _columnSizes ? _columnSizes["rating"] : 70,
      sorter: (a, b) => a.rating.toString().localeCompare(b.rating.toString()),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => {
        const _r = parseFloat(record.rating).toFixed(2);

        if (isNaN(_r)) {
          return (
            <div style={{ textAlign: "center", color: "red" }}>
              <span>{`NaN`}</span>
            </div>
          );
        } else {
          return (
            <div style={{ textAlign: "center" }}>
              <span>{`${parseFloat(record.rating).toFixed(2)}`}</span>
            </div>
          );
        }
      },
    },
    {
      title: t("audit.title"),
      // dataIndex: "id",
      key: "audit",
      width: _columnSizes ? _columnSizes["audit"] : 100,
      sorter: (a, b) =>
        (getLatestAuditDate(a) ? getLatestAuditDate(a) : 0) -
        (getLatestAuditDate(b) ? getLatestAuditDate(b) : 0),
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, record) => (
        <PropertyAuditLog store={store} property={record}></PropertyAuditLog>
      ),
    },
    {
      title: t("property.action"),
      // dataIndex: "id",
      key: "id",
      width: 50,

      render: (text, record) => (
        <Popconfirm
          title={t("property.are_you_sure_you_want_remove")}
          onConfirm={() => onConfirmDeleteProperty(record)}
          onCancel={onCancelDeleteProperty}
          okText={t("property.yes")}
          cancelText={t("property.no")}
        >
          <Button type={"text"} icon={<DeleteOutlined />}></Button>
          {/* <a>{t("property.remove")}</a> */}
        </Popconfirm>
      ),
    },
  ];
  const [columns, setColumns] = useState(PropertyColumns);

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      const _cSizes = nextColumns.reduce((_nc, _obj) => {
        let _name;

        if (_obj.title === "Identification" || _obj.title === "ID") {
          _name = "propertyId";
        }

        if (_obj.title === "Adresse") {
          _name = "address";
        }
        if (_obj.title === "NPA" || _obj.title === "PLZ") {
          _name = "plz";
        }
        if (_obj.title === "Lieu" || _obj.title === "Ort") {
          _name = "ort";
        }
        if (_obj.title === "Canton" || _obj.title === "Kanton") {
          _name = "canton";
        }
        if (_obj.title === "m2") {
          _name = "m2";
        }
        if (_obj.title === "Évaluation" || _obj.title === "Rating") {
          _name = "rating";
        }
        if (_obj.title === "Audit") {
          _name = "audit";
        }
        if (_obj.title === "") {
          _name = "action";
        }
        return {
          ..._nc,
          [_name]: _obj.width ? _obj.width : 0,
        };
      }, {});

      _columnSizes = _cSizes;
      localStorage.setItem(`columnSizes`, JSON.stringify(_cSizes));
      setColumns(nextColumns);
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  //const data =   row.properties //.filter((p) => p.portfolioId === row.key); //properties.filter((p) => p.portfolioId === row.key);

  const data =
    searchKeyword !== undefined &&
    searchKeyword.trim().length > 0 &&
    row.properties.length > 0
      ? row.properties
          .filter(
            (pp) =>
              pp.name
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              pp.address
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              pp.city
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              pp.canton
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              pp.region
                .toLowerCase()
                .includes(searchKeyword.trim().toLowerCase()) ||
              portfolios.find(
                (pf) =>
                  pf.id == pp.portfolioId &&
                  pf.name
                    .toLowerCase()
                    .includes(searchKeyword.trim().toLowerCase())
              )
          )
          .map((pp) => {
            return {
              key: pp.id,
              ...pp,
              rating: getPropertyRating(pp),
              areaTotal: getAreaTotal(pp),
              areaOfficePercentage: getAreaPercentage(pp.areaOffice, pp),
              areaResidentialPercentage: getAreaPercentage(
                pp.areaResidential,
                pp
              ),
              areaCommercialPercentage: getAreaPercentage(
                pp.areaCommercial,
                pp
              ),
              areaBuildingPercentage: getAreaPercentage(pp.areaBuilding, pp),
              areaHealthPercentage: getAreaPercentage(pp.areaHealth, pp),
            };
          })
      : row.properties.map((pp) => {
          return {
            key: pp.id,
            ...pp,
            rating: getPropertyRating(pp),
            areaTotal: getAreaTotal(pp),
            areaOfficePercentage: getAreaPercentage(pp.areaOffice, pp),
            areaResidentialPercentage: getAreaPercentage(
              pp.areaResidential,
              pp
            ),
            areaCommercialPercentage: getAreaPercentage(pp.areaCommercial, pp),
            areaBuildingPercentage: getAreaPercentage(pp.areaBuilding, pp),
            areaHealthPercentage: getAreaPercentage(pp.areaHealth, pp),
          };
        }); //  store.properties.filter((pp) => pp.portfolioId === row.id);
  return (
    <div key={row.key} style={{ marginLeft: "14px", marginBottom: "10px" }}>
      <Table
        className={"property_table table-resizeable-property"}
        key={row.key}
        size="small"
        bordered="true"
        title={() => (
          <Row>
            <Col flex="auto">
              {/*  </Col>
              <Col flex="auto" style={{ textAlign: "left" }}> */}
              <Space>{t("property.properties")}</Space>
            </Col>
            <Col flex="auto" style={{ textAlign: "right" }}>
              <Button
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  addNewProperty(row.key, row.id);
                }}
              >
                {t("property.new_property")}
              </Button>
            </Col>
          </Row>
        )}
        footer={() => `${data.length}    ${t("property.properties_dot")}`}
        components={components}
        columns={resizableColumns}
        expanded={true}
        dataSource={data}
        pagination={{
          pageSizeOptions: ["6", "20", "25", "30"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
        }}
        scroll={{ y: "calc(100vh - 240px)" }}
        rowKey={"key"}
        rowClassName={(record, index) => {
          let _indexChanged = record.ratings.find((r) => r.indexChanged === 1);
          if (_indexChanged) return "red";
          else return null;
        }}
      />
    </div>
  );
};

export default inject("store")(observer(PropertyTableRow));
