import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  Button,
  Space,
  Table,
  Affix,
  Popconfirm,
  message,
} from "antd";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  AppstoreOutlined,
  // UserOutlined,
} from "@ant-design/icons";

import {
  postal_codes,
  cities,
  cantons,
  regions,
  getCantonName,
} from "../../data/AddressBook";

import { getCompanyRating } from "../../data/RatingCriteria";

import ClientDetailModal from "./ClientDetailModal";
import ClientDetailDrawer from "./ClientDetailDrawer";
import ClientListTable from "./ClientListTable";

// import ClientPortfoliosDrawer from "./ClientPortfoliosDrawer";

import ResizableTitle from "../Common/ResizableTitle";
// import ResizableAntdTable from "resizable-antd-table";

import { Link } from "react-router-dom";

import CompanyModel from "../../models/CompanyModel";
//const { Header, Footer, Sider, Content } = Layout;
//translation
import { useTranslation } from "react-i18next";

import "../ipadCss/m_custom.css";

const { Title } = Typography;
const { Search } = Input;

// let _columnSizes = localStorage.getItem('clientColumnSizes')? JSON.parse(localStorage.getItem('clientColumnSizes')): null

function ClientList(props) {
  const { store } = props;
  const { t } = useTranslation("common"); //translation
  const selectedClientId = store.activeClientId;
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [Message, setMessage] = useState("");

  const [IsEditMode, setIsEditMode] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [HasData, setHasData] = useState(false);

  const [isClientDetailVisible, setIsClientDetailVisible] = useState(false);

  // const [isClientUsersModalVisible, setIsClientUsersModalVisible] =
  //   useState(false);

  const [isClientPortfoliosVisible, setIsClientPortfoliosVisible] =
    useState(false);

  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}));
  // const [cityFilters, setCityFilters] = useState([]);
  // const [cantonFilters, setCantonFilters] = useState([]);
  // const [regionFilters, setRegionFilters] = useState([]);
  /*  useEffect(() => {
    if (selectedClient) selectedClient.getPortfolios((success) => {});
  }, [selectedClient]);
 */
  // const [isNewClient, setIsNewClient] = useState(true);
  /*  const getCantonName = (id) => {
    var canton = cantons.find((c) => c.id === id);
    return canton ? canton.name : "";
  }; */

  let _columnSizes = localStorage.getItem(`clientColumnSizes`)
    ? JSON.parse(localStorage.getItem(`clientColumnSizes`))
    : null;
  const initialcolumns = [
    {
      title: t("admin.name"),
      width: _columnSizes ? _columnSizes["adminName"] : 420,
      dataIndex: "name",
      key: "id",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          title={t("admin.view_detail")}
          onClick={() => {
            showClientDrawer(record.key);
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: t("admin.address"),
      width: _columnSizes ? _columnSizes["address"] : 260,
      dataIndex: "address",
      key: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("admin.city"),
      width: _columnSizes ? _columnSizes["city"] : 100,
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("admin.products"),
      width: _columnSizes ? _columnSizes["products"] : 80,
      dataIndex: "portfolioCount",
      key: "portfolioCount",
      sorter: (a, b) => a.portfolioCount - b.portfolioCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("admin.properties"),
      width: _columnSizes ? _columnSizes["properties"] : 110,
      dataIndex: "propertyCount",
      key: "propertyCount",
      sorter: (a, b) => a.propertyCount - b.propertyCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("admin.rating"),
      width: _columnSizes ? _columnSizes["rating"] : 70,
      dataIndex: "rating",
      key: "rating",
      sorter: (a, b) => a.rating - b.rating,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => parseFloat(record.rating).toFixed(2),
    },
    {
      title: (
        <span>
          m<sup>2</sup>
        </span>
      ),
      dataIndex: "totalArea",
      key: "totalArea",
      sorter: (a, b) =>
        (a.totalArea ? a.totalArea : 0) - (b.totalArea ? b.totalArea : 0),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div
          style={{ textAlign: "right" }}
          key={record.id}
        >{`${record.totalArea.toLocaleString("de-ch", {
          minimumFractionDigits: 2,
        })}`}</div>
      ),
      width: _columnSizes ? _columnSizes["m2"] : 90,
    },
    {
      title: t("admin.dashboard"),
      width: 100,
      key: "id",
      render: (text, record) => (
        <Space size="middle">
          <Link
            title={t("admin.view_dashboard")}
            to={`ClientDashboard/${record.key}`}
          >
            <AppstoreOutlined />
          </Link>
        </Space>
      ),
    },
  ];
  const [columns, setColumns] = useState(initialcolumns);
  const search = (keyword) => {
    if (keyword !== searchKeyword) {
      setSearchKeyword(keyword);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    // setMessage("");

    setIsEditMode(false);
    setHasData(false);
    setIsClientDetailVisible(false);

    // setTimeout(() => {
    setIsLoading(true);

    store.getClients().then((success) => {
      loadClientList();
      setHasData(true);
      setIsLoading(false);
    });

    // }, 500);

    return () => {
      setIsEditMode(false);
    };
  }, []);

  useEffect(() => {
    loadClientList();
  }, [store.clientCompanies, searchKeyword, store.clientListUpdateOn]);

  useEffect(() => {
    /* setColumns(initialcolumns);
    setResizableColumns(
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      }))
    ); */
    store.getClients().then((s) => {});
  }, [store.language]);
  const [dataSource, setdataSource] = useState([]);

  const loadClientList = (keyword = "") => {
    //if(keyword) setSearchKeyword(keyword);

    var sword = (
      keyword && keyword.length > 0 ? keyword : searchKeyword
    ).trim();

    const getPropertyCount = (c) => {
      var pcount = 0;
      c.portfolios.forEach((pf) => {
        pcount += pf.properties.length;
      });

      return pcount;
    };

    /* setTimeout(() => { */
    setdataSource(
      (sword.length > 0
        ? store.activeClients.filter(
            (r) =>
              sword.length === 0 ||
              r.name.toLowerCase().indexOf(sword.toLowerCase()) > -1
          )
        : store.activeClients
      ).map((c) => {
        return {
          key: c.id,
          name: c.name,
          address: c.address,
          city: c.city,
          canton: c.canton,
          region: c.region,
          portfolioCount: c.portfolios.length /*  c.portfolioCountCached */,
          propertyCount: getPropertyCount(c) /* c.propertyCountCached */,
          rating: getCompanyRating(c),
          users: c.users,
          totalArea: c.totalArea,
        };
      })
    );

    setIsLoading(false);
    /*  }, 500); */
  };

  // useEffect(() => {
  //   var uniqueCityNames = dataSource
  //     .map((c) => {
  //       return {
  //         value: c.city,
  //       };
  //     })
  //     .filter(function (obj, index, self) {
  //       return (
  //         index ===
  //         self.findIndex(function (t) {
  //           return t["value"] === obj["value"];
  //         })
  //       );
  //     });

  //   setCityFilters(
  //     uniqueCityNames
  //       .sort((a, b) => a.value.localeCompare(b.value))
  //       .map((c) => {
  //         return { text: c.value, value: c.value };
  //       })
  //   );

  //   var uniqueCantonNames = dataSource
  //     .map((c) => {
  //       return {
  //         value: c.canton,
  //       };
  //     })
  //     .filter(function (obj, index, self) {
  //       return (
  //         index ===
  //         self.findIndex(function (t) {
  //           return t["value"] === obj["value"];
  //         })
  //       );
  //     });

  //   setCantonFilters(
  //     uniqueCantonNames
  //       .map((c) => {
  //         return { text: getCantonName(c.value), value: c.value };
  //       })
  //       .sort((a, b) => a.text.localeCompare(b.text))
  //   );

  //   var uniqueRegionNames = dataSource
  //     .map((c) => {
  //       return {
  //         value: c.region,
  //       };
  //     })
  //     .filter(function (obj, index, self) {
  //       return (
  //         index ===
  //         self.findIndex(function (t) {
  //           return t["value"] === obj["value"];
  //         })
  //       );
  //     })
  //     .sort((a, b) => a.value.localeCompare(b.value));
  //   setRegionFilters(
  //     uniqueRegionNames.map((c) => {
  //       return { text: c.value, value: c.value };
  //     })
  //   );

  //   return () => {
  //     //reset
  //     setCityFilters([]);
  //     setCantonFilters([]);
  //     setRegionFilters([]);
  //   };
  // }, [dataSource]);

  const addNewClient = () => {
    store.setActiveClientId(0);
    // setIsNewClient(true);
    setSelectedClient(CompanyModel.create({}));

    setIsClientModalVisible(true);
  };
  // const showUserModal = (id) => {
  //   //setSelectedClientData
  //   setSelectedClient(store.clients.find((c) => c.id === id));

  //   setIsClientUsersModalVisible(true);
  // };
  const showClientDrawer = (id) => {
    store.setActiveClientId(id);
    // setIsNewClient(false);
    store.getPortfoliosByCompany(id).then((s) => {
      setSelectedClient(store.clients.find((c) => c.id === id));

      // setIsNewClient(false);
      setIsClientDetailVisible(true);
    });
    //setSelectedClientData
  };

  const [isClientModalVisible, setIsClientModalVisible] = useState(false);
  const clientDetailModalProps = {
    store,
    isClientModalVisible,
    setIsClientModalVisible,
    postal_codes,
    cities,
    cantons,
    regions,
    search,
    setSearchKeyword,
  };

  const clientDetailDrawerProps = {
    store,
    isClientDetailVisible: isClientDetailVisible,
    setIsClientDetailVisible,
    /* isClientUsersModalVisible,
    setIsClientUsersModalVisible,
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible, */
    postal_codes,
    cities,
    cantons,
    regions,
    /* loadClientList,
    isNewClient,
    setIsNewClient,
    selectedClient,
    setSelectedClient,
    isAddMode,
    setIsAddMode, */
    getCantonName,
  };

  const clientPortfoliosProps = {
    store,
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible,
    clientId: isClientPortfoliosVisible ? selectedClient.id : 0,
    getCantonName,
    /*  portfolios: store.portfolios.filter(p=>p.companyId==selectedClient.id) */
  };
  const [isAffixed, setIsAffixed] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  useEffect(() => {
    setIsInitial(false);
  }, []);
  /* 
  useEffect(() => {
    if (isClientDetailVisible === false && isInitial==false) {
      setIsLoading(true);
      store.getClients().then((success) => {
        loadClientList();
        setHasData(true);
        setIsLoading(false);
      });
      console.log("reloading client list...");
    }
  }, [isClientDetailVisible]); */

  const [ClientIDsForDeletion, setClientIDsForDeletion] = useState([]);
  const [ClientNameForDeletion, setClientNameForDeletion] = useState("");

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setClientIDsForDeletion(selectedRowKeys);

      if (selectedRows.length === 1) {
        setClientNameForDeletion(selectedRows[0].name);
      }
    },
    /*  getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }), */
  };

  const DeleteClients = () => {
    if (ClientIDsForDeletion.length > 0) {
      store
        .setMultipleClientStatus("inactive", ClientIDsForDeletion)
        .then((success) => {
          if (success) {
            store.setClientListUpdateOn(new Date().getTime().toString());

            if (ClientIDsForDeletion.length === 1) {
              message.success(
                `${t("admin.client")} ${ClientNameForDeletion} ${t(
                  "admin.deleted_successfully"
                )}`
              );

              setClientNameForDeletion("");
            } else {
              message.success(t("admin.selected_clients_deleted_successfully"));
            }

            setClientIDsForDeletion([]);
          } else {
            message.error(t("admin.delete_failed"));

            setClientNameForDeletion("");
            setClientIDsForDeletion([]);
          }
        });
    }
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      const _cSizes = nextColumns.reduce((_nc, _obj) => {
        let _name;

        if (_obj.title === "Name" || _obj.title === "Nom") {
          _name = "adminName";
        }

        if (_obj.title === "Adresse") {
          _name = "address";
        }
        if (_obj.title === "Ort" || _obj.title === "Lieu") {
          _name = "city";
        }
        if (_obj.title === "Produkte" || _obj.title === "Produits") {
          _name = "products";
        }
        if (
          _obj.title === "Anz. Liegenschaften" ||
          _obj.title === "Immeubles"
        ) {
          _name = "properties";
        }
        if (_obj.title.type === "span") {
          _name = "m2";
        }
        if (_obj.title === "Ratings" || _obj.title === "Évaluation") {
          _name = "rating";
        }

        return {
          ..._nc,
          [_name]: _obj.width ? _obj.width : 0,
        };
      }, {});
      _columnSizes = _cSizes;
      localStorage.setItem(`clientColumnSizes`, JSON.stringify(_cSizes));

      setColumns(nextColumns);
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <>
      <Affix
        style={{ position: "absolute" }}
        offsetTop={0}
        onChange={(affixed) => {
          console.log(affixed);
          setIsAffixed(affixed);
        }}
      ></Affix>
      <Affix offsetTop={0}>
        <Row
          className={"page-title"}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? "rgba(255,255,255,0.8)"
              : "rgba(255,255,255,0.0)",
            boxShadow: isAffixed
              ? "10px 1px 15px rgba(128,128,128,0.5)"
              : "none",
          }}
        >
          <Col flex="auto">
            <Title className={isAffixed ? "page-title" : ""}>
              {t("admin.client")}
            </Title>
          </Col>
          <Col></Col>
        </Row>
      </Affix>
      <Row gutter={[32, 24]} style={{ minHeight: "calc(100% - 100px)" }}>
        <Col flex="auto">
          <Card
            className={"ant-custom-table"}
            style={{
              overflow: "hidden",
              padding: "15px",
              height: "calc(100%)",
            }}
          >
            <Row gutter={[32, 24]}>
              {/*   <Col flex="auto">
                <Title level={3}>Clients</Title>
              </Col> */}
              <Col
                style={{
                  textAlign: "left",
                }}
              >
                <Space>
                  <Search
                    placeholder={t("admin.search_client")}
                    onSearch={(value) => search(value)}
                    loading={IsLoading}
                  />
                  {IsEditMode === false && (
                    <>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => addNewClient()}
                      >
                        {t("admin.add")}
                      </Button>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => setIsEditMode(rowSelection)}
                      >
                        {t("admin.edit")}
                      </Button>
                    </>
                  )}
                  {IsEditMode && (
                    <>
                      <Popconfirm
                        placement={"bottom"}
                        title={t("client.are_you_sure_you_want_remove")}
                        onConfirm={() => DeleteClients()}
                        okText={t("client.yes")}
                        cancelText={t("client.no")}
                      >
                        <Button
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => console.log("+")}
                        >
                          {t("admin.delete")}
                        </Button>
                      </Popconfirm>
                      <Button onClick={() => setIsEditMode(false)}>
                        {" "}
                        {t("admin.done")}
                      </Button>
                    </>
                  )}
                </Space>
              </Col>
            </Row>

            {/* <Table
              className={"table-resizeable admin__client_table"}
              scroll={{ y: "1000" }}
              dataSource={dataSource}
              components={components}
              columns={resizableColumns}
              // columns={initialcolumns}
              pagination={{ pageSize: 20 }}
              size="small"
              bordered
              rowSelection={IsEditMode}
              loading={IsLoading}
              hasData={HasData}
              footer={() => dataSource.length + " " + t("admin.clients")}
            /> */}
            <ClientListTable
              dataSource={dataSource}
              isEditMode={IsEditMode}
              isLoading={IsLoading}
              hasData={HasData}
              showClientDrawer={showClientDrawer}
            />
            {/*>
              {resizableColumns.map((col) => (
                <Table.Column
                  key={col.key}
                  title={t(col.title)}
                  dataIndex={col.dataIndex}
                  width={col.width}
                  sorter={col.sorter}
                  defaultSortOrder={col.defaultSortOrder}
                  sortDirections={col.sortDirections}
                  render={col.render}
                />
              ))}
            </Table>*/}

            {selectedClientId > 0 && (
              <ClientDetailDrawer
                {...clientDetailDrawerProps}
              ></ClientDetailDrawer>
            )}
          </Card>
        </Col>
      </Row>
      <ClientDetailModal {...clientDetailModalProps}></ClientDetailModal>
    </>
  );
}

export default observer(ClientList);
