import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Button, Modal, Input, message } from "antd";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import axios from "axios";

const { TextArea } = Input;
const RatingComment = (props) => {

  const { t } = useTranslation("common")
  const { getActualRatingObjectByKey, criteria } = props
  const [comment, setComment] = useState('')
  const [originalComment, setOriginalComment] = useState('')


  useEffect(() => {

    let _rating

    if(props.childIndex) {

      _rating = getActualRatingObjectByKey(`${criteria.key}.${props.childIndex}`)
      
    } else { 
      
      _rating = getActualRatingObjectByKey(criteria.key)
    }

    if(_rating) {
      
      setComment(_rating.potential_comment)
      setOriginalComment(_rating.potential_comment)
    }
  }, [criteria])

  const onCommentChange = (e) => {
    const newComment = e.target.value;

    setComment(newComment)
  };

  // modal move
  const draggleRef = useRef(false);
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();

    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  const handleOk = async (e) => {
    
    let _rating

    if(props.childIndex) {

      _rating = getActualRatingObjectByKey(`${criteria.key}.${props.childIndex}`)
      
    } else { 
      
      _rating = getActualRatingObjectByKey(criteria.key)
    }
    if(_rating) {

      try {
        
        await axios.put(`/api/ratings/comment/${_rating.id}`, { comment })
        
        await props.currentProperty.getRatings()

        setOriginalComment(comment)
        message.success(t('rating.potential_ratings_updated_successfully'))

        setVisible(false)

      } catch (error) {
        
        message.error('comment_failed')
      }
    }
  };

  const handleCancel = (e) => {
    setComment(originalComment)
    setVisible(false);
  };

  return (
    <>
      <Button
        tabIndex={-1}
        type="link"
        block
        onClick={(e) => {
          setVisible(true);
        }}
        title={comment ||""}
      >

        { comment? t("admin.edit"): t("admin.add") }
        
      </Button>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
           
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {criteria.code} : {t("rating.comment")}
          </div>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <TextArea
          rows={5}
          showCount
          maxLength={200}
          value={comment}
          onChange={onCommentChange}
        />
      </Modal>
    </>
  );
};

export default observer(RatingComment);
