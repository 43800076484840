import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  Row,
  Col,
  Form,
  // Input,
  Button,
  Modal,
  InputNumber,
  Typography,
  message,
  Card,
  // Statistic,
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import RatingComment from "../../Admin/Ratings/RatingComment";
import parse from "html-react-parser";
import { COLORS_SERIES } from "../../../data/constants";
import { criteria, getPropertyRating } from "../../../data/RatingCriteria";

//translation
import { useTranslation } from "react-i18next";
//CriteriaList
import RatingCriteriaList from "../../Admin/Ratings/RatingCriteriaList";
const { Title } = Typography;

const RatingRow = (props) => {
  const { t } = useTranslation("common"); //translation

  const {
    criteria,
    setHelpDescription,
    getActualRatingByKey,
    getActualRatingObjectByKey,
    computeTotalAveragePotentialRating,
    currentProperty,
    groupName,
    index,
    blackOrWhiteText,
  } = props;

  // const COLORS_SERIES = { G: "#ff5555", U: "#aacc88", W: "#99ccff" };
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];

  return (
    <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
      <td
        className="ant-table-cell"
        style={{
          textAlign: "center",
          maxWidth: "50px",
          backgroundColor: code_color,
          color: "white",
          // color: `${blackOrWhiteText(code_color)}`,
        }}
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
      >
        {row_code}
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
      >
        {t("criteria." + criteria.key.split(".").join("_") + ".title")}
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
        style={{ textAlign: "center" }}
      >
        {isNaN(
          Math.round(parseFloat(getActualRatingByKey(criteria.key)) * 100) / 100
        )
          ? ""
          : Math.round(parseFloat(getActualRatingByKey(criteria.key)) * 100) /
            100}
      </td>
      <td className="ant-table-cell" style={{ textAlign: "center" }}>
        <Form.Item
          style={{ marginBottom: "0px" }}
          name={criteria.key + "_rating"}
        >
          <InputNumber
            min={0}
            max={3}
            step={1}
            className={"text-center"}
            placeholder={
              isNaN(
                Math.round(
                  parseFloat(getActualRatingByKey(criteria.key)) * 100
                ) / 100
              )
                ? ""
                : Math.round(
                    parseFloat(getActualRatingByKey(criteria.key)) * 100
                  ) / 100
            }
            onChange={() => {
              computeTotalAveragePotentialRating();
            }}
            onFocus={() => {
              setHelpDescription(criteria.key);
            }}
          />
        </Form.Item>
      </td>
      <td className="ant-table-cell">
        <Form.Item
          style={{ marginBottom: "0px" }}
          name={criteria.key + "_comment"}
        >
          {/* <Input
            onFocus={() => {
              setHelpDescription(criteria.key);
            }}

            tabIndex={"-1"}
          /> */}
          <RatingComment
            currentProperty={currentProperty}
            getActualRatingObjectByKey={getActualRatingObjectByKey}
            criteria={criteria}
          ></RatingComment>
        </Form.Item>
      </td>
      <td className="ant-table-cell"></td>
      {/* <td className="ant-table-cell">{criteria.key}</td> */}
    </tr>
  );
};

const RatingRowPerUsage = (props) => {
  const { t } = useTranslation("common"); //translation
  const {
    criteria,
    setHelpDescription,
    getActualRatingByKey,
    computeCriteriaAverageRating,
    currentProperty,
    getActualCalculatedRatingByKey,
    getComputedCriteriaAverageRating,
    groupName,
    getActualRatingObjectByKey,
    index,
    blackOrWhiteText,
  } = props;
  // const COLORS_SERIES = { G: '#ff5555', U: '#aacc88', W: '#99ccff' }
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];

  return (
    <React.Fragment key={criteria.key}>
      <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
        <td
          rowSpan={
            6 -
            [
              currentProperty.areaCommercial,
              currentProperty.areaOffice,
              currentProperty.areaResidential,
              currentProperty.areaBuilding,
              currentProperty.areaHealth,
            ].filter((_) => _ === 0).length
          }
          style={{
            textAlign: "center",
            maxWidth: "50px",
            backgroundColor: code_color,
            color: "white",
            // color: `${blackOrWhiteText(code_color)}`,
          }}
          className="ant-table-cell"
        >
          {row_code}
        </td>
        <td
          className="ant-table-cell"
          onClick={() => {
            setHelpDescription(criteria.key);
          }}
        >
          {t("criteria." + criteria.key.split(".").join("_") + ".title")}
        </td>

        <td className="ant-table-cell" style={{ textAlign: "center" }}>
          {isNaN(
            Math.round(
              parseFloat(getActualCalculatedRatingByKey(criteria.key)) * 100
            ) / 100
          )
            ? ""
            : (
                Math.round(
                  parseFloat(getActualCalculatedRatingByKey(criteria.key)) * 100
                ) / 100
              ).toFixed(2)}
        </td>

        <td className="ant-table-cell" style={{ textAlign: "center" }}>
          {isNaN(
            Math.round(
              parseFloat(getComputedCriteriaAverageRating(criteria.key)) * 100
            ) / 100
          )
            ? ""
            : (
                Math.round(
                  parseFloat(getComputedCriteriaAverageRating(criteria.key)) *
                    100
                ) / 100
              ).toFixed(2)}
        </td>
        <td className="ant-table-cell"></td>
        <td className="ant-table-cell"></td>
        {/* <td className="ant-table-cell">{criteria.key}</td> */}
      </tr>

      {criteria.children.map((c, _i) => {
        if (
          (_i === 0 && currentProperty.areaResidential > 0) ||
          (_i === 1 && currentProperty.areaOffice > 0) ||
          (_i === 2 && currentProperty.areaCommercial > 0) ||
          (_i === 3 && currentProperty.areaBuilding > 0) ||
          (_i === 4 && currentProperty.areaHealth > 0)
        ) {
          return (
            <tr key={c.key} className="ant-table-row ant-table-row-level-0">
              <td
                className="ant-table-cell"
                onClick={() => {
                  setHelpDescription(c.key);
                }}
              >
                <div style={{ marginLeft: "50px" }}>
                  {t("criteria." + c.key.split(".").join("_") + ".title")}
                </div>
              </td>
              <td
                className="ant-table-cell"
                onClick={() => {
                  setHelpDescription(c.key);
                }}
                style={{ textAlign: "center" }}
              >
                {isNaN(
                  Math.round(parseFloat(getActualRatingByKey(c.key)) * 100) /
                    100
                )
                  ? ""
                  : Math.round(parseFloat(getActualRatingByKey(c.key)) * 100) /
                    100}
              </td>
              <td className="ant-table-cell" style={{ textAlign: "center" }}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name={c.key + "_rating"}
                >
                  <InputNumber
                    min={0}
                    max={3}
                    step={1}
                    className={"text-center"}
                    formatter={(value) =>
                      `${
                        value.toString().length == 0
                          ? ""
                          : isNaN(Math.round(value * 100) / 100)
                          ? ""
                          : Math.round(value * 100) / 100
                      }`
                    }
                    placeholder={
                      isNaN(
                        Math.round(
                          parseFloat(getActualRatingByKey(c.key)) * 100
                        ) / 100
                      )
                        ? ""
                        : Math.round(
                            parseFloat(getActualRatingByKey(c.key)) * 100
                          ) / 100
                    }
                    onChange={() => {
                      computeCriteriaAverageRating(criteria.key);
                    }}
                    onFocus={() => {
                      setHelpDescription(c.key);
                    }}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name={c.key + "_comment"}
                >
                  {/* <Input
                    onFocus={() => {
                      setHelpDescription(c.key);
                    }}
                    tabIndex={"-1"}
                  /> */}
                  <RatingComment
                    currentProperty={currentProperty}
                    childIndex={_i + 1}
                    getActualRatingObjectByKey={getActualRatingObjectByKey}
                    criteria={criteria}
                  ></RatingComment>
                </Form.Item>
              </td>
              <td className="ant-table-cell"></td>
            </tr>
          );
        }
      })}
    </React.Fragment>
  );
};

const PotentialRatingsModal = (props) => {
  const { t } = useTranslation("common"); //translation
  const { store, visible, setVisible, currentProperty } = props;

  /*  const currentProperty = id
    ? toJS(store.properties.find((pp) => pp.id === id))
    : PropertyModel.create({}); */

  const [ratings, setRatings] = useState(
    currentProperty ? currentProperty.ratings.map((r) => toJS(r)) : []
  );

  const [potentialRatings, setPotentialRatings] = useState(
    currentProperty ? currentProperty.potentialRatings.map((r) => toJS(r)) : []
  );

  const [form] = Form.useForm();

  //when any form value changes..
  const onFormValuesChange = (changedValues, allValues) => {
    computeTotalAverageActualRating();
    computeTotalAveragePotentialRating();
  };

  const onFormFinish = (values, t) => {
    //transform form fields to array of {key,value,comment}

    var ratings = [];
    var p_id = currentProperty.id;
    for (var i in values) {
      // i =  KEY_rating or KEY_comment
      var kp = i.split("_");
      var field = ratings.find((f) => f.key === kp[0]);
      var found = true;
      if (!field) {
        field = { propertyId: p_id, key: kp[0], value: 0, comment: "" };
        found = false;
      }
      if (kp[1] === "rating") field.value = parseFloat(values[i]);
      else if (kp[1] === "comment") field.comment = values[i];

      if (!found) ratings.push(field);
    }

    //do not include no input
    ratings = ratings.filter((r) => r.value);
    //console.log([values,ratings]);

    currentProperty.updatePotentialRatings(ratings).then((success) => {
      if (success)
        message.success(t("rating.potential_ratings_updated_successfully"));
    });
  };
  const onFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (currentProperty && currentProperty.id > 0) {
      // setRatings(currentProperty.ratings.map((r) => toJS(r)));
      // setPotentialRatings(currentProperty.potentialRatings.map((r) => toJS(r)));

      currentProperty.getRatings().then((success) => {
        if (success) {
          setRatings(currentProperty.ratings);

          currentProperty.getPotentialRatings().then((success1) => {
            setPotentialRatings(currentProperty.potentialRatings);
          });
        }
      });
    }
    return () => {
      form.resetFields();
      setRatings([]);
      setPotentialRatings([]);
    };
  }, [currentProperty, store.clientListUpdateOn]);

  const [totalAverageRating, setTotalAverageRating] = useState(0);
  const [totalAverageActualRating, setTotalAverageActualRating] = useState(0);

  const bindCalculatedRatings = () => {
    form.resetFields();
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        if (c.children) {
          computeCriteriaAverageRating(c.key);
          computeCriteriaAverageActualRating(c.key);
        }
      });
    });
    computeTotalAverageActualRating();
    computeTotalAveragePotentialRating();
  };

  const bindRatings = () => {
    form.resetFields();
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        if (c.children) {
          c.children.forEach((u) => {
            var key = u.key;
            var rating = potentialRatings.find((r) => r.key === key);
            if (rating) {
              form.setFieldsValue({ [key + "_rating"]: rating.value });
              form.setFieldsValue({ [key + "_comment"]: rating.comment });
            } else {
              form.setFieldsValue({ [key + "_rating"]: "" });
              form.setFieldsValue({ [key + "_comment"]: "" });
            }

            computeCriteriaAverageRating(c.key);
            computeCriteriaAverageActualRating(c.key);
          });
        } else {
          var rating = potentialRatings.find((r) => r.key === c.key);
          if (rating) {
            form.setFieldsValue({ [c.key + "_rating"]: rating.value });
            form.setFieldsValue({ [c.key + "_comment"]: rating.comment });
          } else {
            // form.setFieldsValue({ [c.key + "_rating"]: 0 });
            form.setFieldsValue({ [c.key + "_comment"]: "" });

            var arating = ratings.find((r) => r.key === c.key);
            if (arating)
              form.setFieldsValue({ [c.key + "_computedActualRating"]: 0 });
          }
        }
      });
    });
    computeTotalAverageActualRating();
    computeTotalAveragePotentialRating();
  };

  useEffect(() => {
    bindRatings();
    return () => {
      form.resetFields();
    };
  }, [potentialRatings]);

  useLayoutEffect(() => {
    //bindCalculatedRatings();

    computeTotalAverageActualRating();
    computeTotalAveragePotentialRating();
    return () => {};
  }, [form]);

  useEffect(() => {
    //    computeTotalAverageActualRating();
    bindRatings();
  }, [ratings]);

  //when a usage rating changes, the parent criteria's avg rating is calculated again.
  const getComputedCriteriaAverageRating = (key) => {
    var result = 0;
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        //this is the parent criteria
        if (c.key === key) {
          var sumRating = 0;

          if (c.children) {
            var subrating = [1, 2, 3, 4, 5].map((x) => {
              var value = form.getFieldValue(key + `.${x}_rating`);
              if (isNaN(value) || value === "") {
                var ar = ratings.find((r) => r.key === key + `.${x}`);
                if (ar) {
                  value = parseFloat(ar.value);
                }
              }

              return parseFloat(value);
            });

            var v = getWeightedAverageFromSubrating(
              currentProperty,

              isNaN(subrating[0]) ? "" : subrating[0],
              isNaN(subrating[1]) ? "" : subrating[1],
              isNaN(subrating[2]) ? "" : subrating[2],
              isNaN(subrating[3]) ? "" : subrating[3],
              isNaN(subrating[4]) ? "" : subrating[4]
            );
            // if (isNaN(v)) v = 0;
            result = v;
          }
        }
      });
    });
    return result;
  };

  const computeCriteriaAverageRating = (key) => {
    storeScrollY();

    form.setFieldsValue({
      [key + "_computedRating"]: getComputedCriteriaAverageRating(key),
    });

    computeTotalAveragePotentialRating();
  };

  const computeCriteriaAverageActualRating = (key) => {
    if (ratings.length === 0) {
      form.setFieldsValue({ [key + "_computedActualRating"]: 0 });
      return;
    }
    var subrating = [1, 2, 3].map((x) => {
      var subkey = key + `.${x}`;
      var rating = ratings.find((r) => r.key === subkey);
      return parseFloat(rating ? rating.value : 0);
    });

    var v = getWeightedAverageFromSubrating(
      currentProperty,

      isNaN(subrating[0]) ? "" : subrating[0],
      isNaN(subrating[1]) ? "" : subrating[1],
      isNaN(subrating[2]) ? "" : subrating[2]
    );
    // if (isNaN(v)) v = 0;

    form.setFieldsValue({ [key + "_computedActualRating"]: v });
    /* 
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        //this is the parent criteria
        if (c.key === key) {
          var sumRating = 0;
          if (c.children) {
            c.children.forEach((s) => {
              // actual rating value.
              var ar = ratings.find((r) => r.key === s.key);
              if (ar) {
                sumRating += parseFloat(ar.value);
              }
            });
            sumRating = parseFloat((sumRating / c.children.length).toFixed(2));
          }
          form.setFieldsValue({ [key + "_computedActualRating"]: sumRating });
        }
      });
    });
    computeTotalAveragePotentialRating(); */
  };
  useEffect(() => {
    (() => {
      if (!visible) return;

      const property = toJS(currentProperty);
      if (property.ratings.length === 0) return 0;

      var ratingCount = 0;
      var sumRating = 0;
      var totalArea =
        property.areaOffice +
        property.areaResidential +
        property.areaCommercial +
        property.areaBuilding +
        property.areaHealth;

      var areaOfficePercentage = property.areaOffice / totalArea;
      var areaResidentialPercentage = property.areaResidential / totalArea;
      var areaCommercialPercentage = property.areaCommercial / totalArea;
      var areaBuildingPercentage = property.areaBuilding / totalArea;
      var areaHealthPercentage = property.areaHealth / totalArea;

      var factor = [
        areaResidentialPercentage,
        areaOfficePercentage,
        areaCommercialPercentage,
        areaBuildingPercentage,
        areaHealthPercentage,
      ];

      var ratingCount = 0;
      var sumRating = 0;

      let nanExists = false;

      for (const _pr of property.ratings) {
        if (isNaN(_pr.value)) {
          nanExists = true;
          break;
        }
      }

      if (nanExists || property.ratings.length < 12) {
        setTotalAverageRating(NaN);
      } else {
        criteria.forEach((g) => {
          // g - GeselSchaft,Wirthschaft...
          g.children.forEach((c) => {
            // c = 102.1,105.1,..
            ratingCount++;
            if (c.children) {
              var sV = 0;
              c.children.forEach((s) => {
                //s is subratings: 105.1.1 Wohnen,...
                var value = 0;
                value = form.getFieldValue(s.key + "_rating");

                // if not specified by user use actual rating for this entry instead
                if (value !== 0 && (!value || value === "")) {
                  var subrate = property.ratings.find((r) => r.key === s.key);
                  if (subrate) {
                    value = parseFloat(subrate.value);
                  }
                }
                var subrating_index = parseInt(s.key.split(".")[2]) - 1;
                sV += value * factor[subrating_index];
              });

              //sumRating += sV / c.children.length;
              sumRating += sV;
            } else {
              var value = form.getFieldValue(c.key + "_rating");
              if (!value || value === "") {
                var rate = property.ratings.find((r) => r.key === c.key);
                if (rate) {
                  value = parseFloat(rate.value);
                }
              }

              sumRating += parseFloat(value || 0);
            }
          });
        });

        sumRating = parseFloat((sumRating / ratingCount).toFixed(2));

        setTotalAverageRating(sumRating);
      }
    })();
  }, [visible]);

  const computeTotalAveragePotentialRating = () => {
    const property = toJS(currentProperty);
    if (property.ratings.length === 0) return 0;

    var ratingCount = 0;
    var sumRating = 0;
    var totalArea =
      property.areaOffice +
      property.areaResidential +
      property.areaCommercial +
      property.areaBuilding +
      property.areaHealth;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea;
    var areaBuildingPercentage = property.areaBuilding / totalArea;
    var areaHealthPercentage = property.areaHealth / totalArea;

    var factor = [
      areaResidentialPercentage,
      areaOfficePercentage,
      areaCommercialPercentage,
      areaBuildingPercentage,
      areaHealthPercentage,
    ];

    var ratingCount = 0;
    var sumRating = 0;

    let nanExists = false;

    for (const _pr of property.ratings) {
      if (isNaN(_pr.value)) {
        nanExists = true;
        break;
      }
    }

    let _missingFields = false;

    const _iAR = property.ratings.findIndex((_pr) => _pr.key === "103.1.1");
    const _iAO = property.ratings.findIndex((_pr) => _pr.key === "103.1.2");
    const _iAC = property.ratings.findIndex((_pr) => _pr.key === "103.1.3");
    const _iAB = property.ratings.findIndex((_pr) => _pr.key === "103.1.4");
    const _iAH = property.ratings.findIndex((_pr) => _pr.key === "103.1.5");

    if (property.areaResidential > 0 && _iAR === -1) {
      _missingFields = true;
    }

    if (property.areaOffice > 0 && _iAO === -1) {
      _missingFields = true;
    }

    if (property.areaCommercial > 0 && _iAC === -1) {
      _missingFields = true;
    }

    if (property.areaBuilding > 0 && _iAB === -1) {
      _missingFields = true;
    }

    if (property.areaHealth > 0 && _iAH === -1) {
      _missingFields = true;
    }

    if (nanExists || property.ratings.length < 12 || _missingFields) {
      setTotalAverageRating(NaN);
    } else {
      criteria.forEach((g) => {
        // g - GeselSchaft,Wirthschaft...
        g.children.forEach((c) => {
          // c = 102.1,105.1,..
          ratingCount++;
          if (c.children) {
            var sV = 0;
            c.children.forEach((s) => {
              //s is subratings: 105.1.1 Wohnen,...
              var value = 0;
              value = form.getFieldValue(s.key + "_rating");

              // if not specified by user use actual rating for this entry instead
              if (value !== 0 && (!value || value === "")) {
                var subrate = property.ratings.find((r) => r.key === s.key);
                if (subrate) {
                  value = parseFloat(subrate.value);
                }
              }
              var subrating_index = parseInt(s.key.split(".")[2]) - 1;
              sV += value * factor[subrating_index];
            });

            //sumRating += sV / c.children.length;
            sumRating += sV;
          } else {
            var value = form.getFieldValue(c.key + "_rating");
            if (!value || value === "") {
              var rate = property.ratings.find((r) => r.key === c.key);
              if (rate) {
                value = parseFloat(rate.value);
              }
            }

            sumRating += parseFloat(value || 0);
          }
        });
      });

      sumRating = parseFloat((sumRating / ratingCount).toFixed(2));

      setTotalAverageRating(sumRating);
    }
  };
  const computeTotalAverageActualRating = () => {
    const property = toJS(currentProperty);
    if (property.ratings.length === 0) return 0;

    var ratingCount = 0;
    var sumRating = 0;
    var totalArea =
      property.areaOffice +
      property.areaResidential +
      property.areaCommercial +
      property.areaBuilding +
      property.areaHealth;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea;
    var areaBuildingPercentage = property.areaBuilding / totalArea;
    var areaHealthPercentage = property.areaHealth / totalArea;

    //try {
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        ratingCount++;
        // var value = 0;
        if (c.children) {
          //compute weighted vg from subratings first

          // fx= P.areaOffice/P.percentageOffice + P.areaResidential/P.percentageResidential+P.areaCommercial/P/percentageCommercial

          var sumSubrating = 0;
          //
          c.children.forEach((s) => {
            //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...
            var factor = [
              areaResidentialPercentage,
              areaOfficePercentage,
              areaCommercialPercentage,
              areaBuildingPercentage,
              areaHealthPercentage,
            ][parseInt(s.key.split(".")[2]) - 1];

            var rr = ratings.find((r) => r.key === s.key);

            sumSubrating += parseFloat(parseFloat(rr ? rr.value : 0)) * factor;
          });
          // sumRating += parseFloat(sumSubrating / c.children.length);
          sumRating += sumSubrating;
        } else {
          // an error happens in  the propduction only???
          var rr = ratings.find((r) => r.key === c.key);

          sumRating += parseFloat(rr ? rr.value : 0); //parseFloat(ar.value);
        }
      });
    });
    var result = parseFloat((sumRating / ratingCount).toFixed(2));

    setTotalAverageActualRating(result);
  };
  const getWeightedAverageFromSubrating = (
    p,

    residentialRating,
    officeRating,
    commercialRating,
    buildingRating,
    healthRating
  ) => {
    const property = toJS(p);
    if (property.ratings.length === 0) return 0;

    var avg = 0;
    var totalArea =
      property.areaOffice +
      property.areaResidential +
      property.areaCommercial +
      property.areaBuilding +
      property.areaHealth;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea;
    var areaBuildingPercentage = property.areaBuilding / totalArea;
    var areaHealthPercentage = property.areaHealth / totalArea;

    avg =
      officeRating * areaOfficePercentage +
      residentialRating * areaResidentialPercentage +
      commercialRating * areaCommercialPercentage +
      buildingRating * areaBuildingPercentage +
      healthRating * areaHealthPercentage;

    if (
      (property.areaOffice > 0 && officeRating === "") ||
      (property.areaResidential > 0 && residentialRating === "") ||
      (property.areaCommercial > 0 && commercialRating === "") ||
      (property.areaBuilding > 0 && buildingRating === "") ||
      (property.areaHealth > 0 && healthRating === "")
    ) {
      avg = NaN;
    }

    /*   criteria.forEach((g) => {
      g.children.forEach((c) => {
        if (c.children) {
          c.children.forEach((s) => {
            var subrate = property.ratings.find((r) => r.key === s.key);
            if (subrate) {
              //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...
              var factor = [
                areaOfficePercentage,
                areaResidentialPercentage,
                areaCommercialPercentage,
              ][parseInt(subrate.key.split(".")[2]) - 1];
  
              avg += parseFloat(subrate.value) * factor;
            }
          });
        }
      });
    }); */

    return avg;
  };

  const getFormPropertyActualRating = (p, form) => {
    const property = toJS(p);
    if (property.ratings.length === 0) return 0;

    var ratingCount = 0;
    var sumRating = 0;
    var totalArea =
      property.areaOffice +
      property.areaResidential +
      property.areaCommercial +
      property.areaBuilding +
      property.areaHealth;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea;
    var areaBuildingPercentage = property.areaBuilding / totalArea;
    var areaHealthPercentage = property.areaHealth / totalArea;

    //try {
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        ratingCount++;
        // var value = 0;
        if (c.children) {
          //compute weighted vg from subratings first

          // fx= P.areaOffice/P.percentageOffice + P.areaResidential/P.percentageResidential+P.areaCommercial/P/percentageCommercial

          var sumSubrating = 0;
          //
          c.children.forEach((s) => {
            //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...
            var factor = [
              areaOfficePercentage,
              areaResidentialPercentage,
              areaCommercialPercentage,
              areaBuildingPercentage,
              areaHealthPercentage,
            ][parseInt(s.key.split(".")[2]) - 1];

            sumSubrating +=
              parseFloat(parseFloat(form.getFieldValue(s.key + "_rating"))) *
              factor;
          });
          // sumRating += parseFloat(sumSubrating / c.children.length);
          sumRating += sumSubrating;
        } else {
          var ar = property.ratings.find((r) => r.key === c.key);
          if (ar) {
            sumRating += parseFloat(ar.value);
          }
        }
      });
    });
    var result = parseFloat((sumRating / ratingCount).toFixed(2));

    return result;
  };

  const [helpText, setHelpText] = useState({
    intro: "",
    manual: "",
    automatic: "",
  });

  const [helpTextTitle, setHelpTextTitle] = useState("");
  const [helpGroup, setHelpGroup] = useState("");
  const [helpChild, setHelpChild] = useState("");
  const [helpCardStyle, setHelpCardStyle] = useState("");
  // const [helpZertifikat, setHelpZertifikat] = useState("");
  const [helpFoto, setHelpFoto] = useState("");
  const [helpFotoGoogle, setHelpFotoGoogle] = useState("");
  const [helpFotoPlan, setHelpFotoPlan] = useState("");
  const [helpFotoGooglePlan, setHelpFotoGooglePlan] = useState("");
  // const [helpKonzepte, setHelpKonzepte] = useState("");
  const [helpMessungen, setHelpMessungen] = useState("");
  const [helpPlan, setHelpPlan] = useState("");
  const [helpWebLink, setHelpWebLink] = useState("");
  const [helpSonstige, setHelpSonstige] = useState("");

  function blackOrWhiteText(bgColor) {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? "black" : "white";
  }

  const setHelpDescription = (key) => {
    // return ;
    setHelpChild("");
    var key1 = parseInt(key.split(".")[0]);
    if (key1 < 200) setHelpCardStyle("t1");
    else if (key1 < 300) setHelpCardStyle("t2");
    else setHelpCardStyle("t3");

    criteria.forEach((g) => {
      g.children.forEach((c, cindex) => {
        if (c.key === key) {
          setHelpGroup(g.groupName[0] + (cindex + 1));
          setHelpTextTitle(
            t("criteria." + c.key.split(".").join("_") + ".title")
          );
          setHelpText({
            intro: c.help
              ? t("criteria." + c.key.split(".").join("_") + ".help")
              : "",
            manual: c.manual
              ? t("criteria." + c.key.split(".").join("_") + ".manual")
              : "",
            automatic: c.automatic
              ? t("criteria." + c.key.split(".").join("_") + ".automatic")
              : "",
          });
          setHelpFoto(
            c.foto ? t("criteria." + c.key.split(".").join("_") + ".foto") : ""
          );
          setHelpFotoGoogle(
            c.fotoorgoogle
              ? t("criteria." + c.key.split(".").join("_") + ".fotoorgoogle")
              : ""
          );
          setHelpFotoPlan(
            c.fotoorplan
              ? t("criteria." + c.key.split(".").join("_") + ".fotoorplan")
              : ""
          );
          setHelpFotoGooglePlan(
            c.fotoorgoogleorplan
              ? t(
                  "criteria." +
                    c.key.split(".").join("_") +
                    ".fotoorgoogleorplan"
                )
              : ""
          );
          // setHelpZertifikat(
          //   c.zertifikat
          //     ? typeof c.zertifikat === "boolean"
          //       ? t("criteria." + c.key.split(".").join("_") + ".plan")
          //       : c.zertifikat
          //           .map((z) => " - " + t("criteria.zertifikats." + z))
          //           .join("<br/>")
          //     : ""
          // );
          setHelpPlan(
            c.plan
              ? typeof c.plan === "boolean"
                ? t("criteria." + c.key.split(".").join("_") + ".plan")
                : c.plan
                    .map((z) => " - " + t("criteria.plans." + z))
                    .join("<br/>")
              : ""
          );
          setHelpWebLink(
            c.weblink
              ? t("criteria." + c.key.split(".").join("_") + ".weblink")
              : "" /*  t("admin_rating.description_not_available") */
          );
          setHelpMessungen(
            c.messungen
              ? t("criteria." + c.key.split(".").join("_") + ".messungen")
              : ""
          );
          setHelpSonstige(
            c.sonstige
              ? t("criteria." + c.key.split(".").join("_") + ".sonstige")
              : ""
          );
        } else {
          if (c.children) {
            c.children.forEach((s) => {
              if (s.key === key) {
                setHelpGroup(g.groupName[0] + (cindex + 1));
                setHelpTextTitle(
                  t("criteria." + c.key.split(".").join("_") + ".title")
                );
                setHelpChild(
                  t("criteria." + s.key.split(".").join("_") + ".title")
                );
                // setHelpText(
                //   s.help
                //     ? t("criteria." + s.key.split(".").join("_") + ".help")
                //     : "" /* t("admin_rating.description_not_available") */
                // );
                setHelpText({
                  ...helpText,
                  intro: s.help
                    ? t("criteria." + s.key.split(".").join("_") + ".help")
                    : "",
                  manual: s.manual
                    ? t("criteria." + s.key.split(".").join("_") + ".manual")
                    : "",
                  automatic: s.automatic
                    ? t("criteria." + s.key.split(".").join("_") + ".automatic")
                    : "",
                });
                setHelpFoto(
                  s.foto
                    ? t("criteria." + s.key.split(".").join("_") + ".foto")
                    : ""
                );
                setHelpFotoGoogle(
                  s.fotoorgoogle
                    ? t(
                        "criteria." +
                          s.key.split(".").join("_") +
                          ".fotoorgoogle"
                      )
                    : ""
                );
                setHelpFotoPlan(
                  s.fotoorplan
                    ? t(
                        "criteria." + s.key.split(".").join("_") + ".fotoorplan"
                      )
                    : ""
                );
                setHelpFotoGooglePlan(
                  s.fotoorgoogleorplan
                    ? t(
                        "criteria." +
                          s.key.split(".").join("_") +
                          ".fotoorgoogleorplan"
                      )
                    : ""
                );
                // setHelpZertifikat(
                //   s.zertifikat
                //     ? typeof s.zertifikat === "boolean"
                //       ? t(
                //           "criteria." +
                //             s.key.split(".").join("_") +
                //             ".zertifikat"
                //         )
                //       : s.zertifikat
                //           .map((z) => " - " + t("criteria.zertifikats." + z))
                //           .join("<br/>")
                //     : ""
                // );
                setHelpPlan(
                  s.plan
                    ? typeof s.plan === "boolean"
                      ? t("criteria." + s.key.split(".").join("_") + ".plan")
                      : s.plan
                          .map((z) => " - " + t("criteria.plans." + z))
                          .join("<br/>")
                    : ""
                );
                setHelpWebLink(
                  s.weblink
                    ? t("criteria." + s.key.split(".").join("_") + ".weblink")
                    : "" /*  t("admin_rating.description_not_available") */
                );
                setHelpMessungen(
                  s.messungen
                    ? t("criteria." + s.key.split(".").join("_") + ".messungen")
                    : ""
                );
                setHelpSonstige(
                  s.sonstige
                    ? t("criteria." + s.key.split(".").join("_") + ".sonstige")
                    : ""
                );
              }
            });
          }
        }
      });
    });
  };

  const getActualRatingByKey = (key) => {
    var rating = 0.0;

    var c = ratings.find((r) => r.key === key);
    if (c) rating = parseFloat(c.value);
    return rating;
  };

  const getActualRatingObjectByKey = (key) => {
    var c = currentProperty.ratings.find((r) => r.key === key);

    return c;
  };

  const getActualCalculatedRatingByKey = (key) => {
    if (ratings.length === 0) {
      // form.setFieldsValue({ [key + "_computedActualRating"]: 0 });
      return 0;
    }
    var subrating = [1, 2, 3, 4, 5].map((x) => {
      var subkey = key + `.${x}`;
      var rating = ratings.find((r) => r.key === subkey);
      return parseFloat(rating ? rating.value : 0);
    });

    var v = getWeightedAverageFromSubrating(
      currentProperty,

      isNaN(subrating[0]) ? "" : subrating[0],
      isNaN(subrating[1]) ? "" : subrating[1],
      isNaN(subrating[2]) ? "" : subrating[2],
      isNaN(subrating[3]) ? "" : subrating[3],
      isNaN(subrating[4]) ? "" : subrating[4]
    );
    if (isNaN(v)) v = 0;

    return v;
  };

  const groupsHeaderStyle = ["t1", "t2", "t3"];
  const scrollRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  //ref.current.scrollTop
  const storeScrollY = () => {
    if (!scrollRef.current) return;
    setScrollCount(scrollCount + 1);
    var sy = scrollRef.current.lastViewScrollTop; //getScrollTop();
    if (scrollRef.current) setScrollY(sy);
  };

  const handleScrollStop = () => {
    //storeScrollY();
  };

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      key={"potentialRating"}
      width={1600}
      title={false}
      centered
      visible={visible}
      okText={t("rating.submit")}
      closable={true}
      onCancel={() => {
        setVisible(false);
      }}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="potentialratingsform"
        initialValues={{ remember: false }}
        onFinish={(values) => {
          onFormFinish(values, t);
        }}
        onFinishFailed={onFormFinishFailed}
        onValuesChange={onFormValuesChange}
      >
        <Row type="flex" align="left">
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
            }}
            level={3}
          >
            {currentProperty.id > 0 ? currentProperty.name + " : " : ""}{" "}
            {t("admin_rating.potential_property_ratings")}
          </Title>
        </Row>
        <Row gutter={16}>
          <Col span={13}>
            <div
              className="ant-table ant-table-small ant-table-bordered"
              style={{
                borderRight: "solid 1px rgb(240,240,240)",
              }}
            >
              <div className="ant-table-container">
                <table style={{ tableLayout: "auto" }}>
                  <colgroup>
                    <col style={{ width: "50px" }}></col>
                    <col style={{ width: "300px" }}></col>
                    <col style={{ width: "175px" }}></col>
                    <col style={{ width: "175px" }}></col>
                  </colgroup>
                  <thead className={"ant-table-thead "}>
                    <tr>
                      <th
                        colSpan={2}
                        className="ant-table-cell"
                        align={"center"}
                      ></th>
                      <th
                        className="ant-table-cell"
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("admin_rating.actual_rating")}
                      </th>
                      <th
                        className="ant-table-cell"
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("admin_rating.potential_rating")}
                      </th>
                      <th className="ant-table-cell" align={"center"}>
                        {t("admin_rating.comment")}
                      </th>
                      {/* <th className="ant-table-cell" align={"center"}>
                        {t("admin_rating.snbs")}
                      </th> */}
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <Scrollbars
              ref={scrollRef}
              style={{ height: 700 }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              onScrollStop={handleScrollStop}
            >
              <div
                className="ant-table ant-table-small ant-table-bordered"
                style={{
                  borderRight: "solid 1px rgb(240,240,240)",
                }}
              >
                <div className="ant-table-container">
                  <table style={{ tableLayout: "auto" }}>
                    <colgroup>
                      <col style={{ width: "50px" }}></col>
                      <col style={{ width: "300px" }}></col>
                      <col style={{ width: "175px" }}></col>
                      <col style={{ width: "175px" }}></col>
                    </colgroup>
                    {criteria &&
                      criteria.map((group, i) => {
                        return (
                          <React.Fragment key={i}>
                            <thead
                              key={i}
                              className={
                                "ant-table-thead " + groupsHeaderStyle[i]
                              }
                            >
                              <tr>
                                <th
                                  className="ant-table-cell"
                                  align={"center"}
                                  colSpan={2}
                                >
                                  <Title
                                    level={4}
                                    style={{
                                      margin: "0px",
                                    }}
                                  >
                                    {t("criteria.group." + i.toString())}
                                  </Title>
                                </th>
                                <th
                                  className="ant-table-cell"
                                  align={"center"}
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                ></th>
                                <th
                                  className="ant-table-cell"
                                  align={"center"}
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                ></th>
                                <th className="ant-table-cell" align={"center"}>
                                  {i === 0 ? t("admin_rating.comment") : ""}
                                </th>
                                <th
                                  className="ant-table-cell"
                                  align={"center"}
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                ></th>
                              </tr>
                            </thead>
                            <tbody
                              className={
                                "ant-table-tbody " + groupsHeaderStyle[i]
                              }
                            >
                              {group.children.map((criteria, cindex) => {
                                return criteria.children ? (
                                  <RatingRowPerUsage
                                    key={criteria.key}
                                    criteria={criteria}
                                    currentProperty={currentProperty}
                                    setHelpDescription={setHelpDescription}
                                    getActualRatingByKey={getActualRatingByKey}
                                    getActualRatingObjectByKey={
                                      getActualRatingObjectByKey
                                    }
                                    getActualCalculatedRatingByKey={
                                      getActualCalculatedRatingByKey
                                    }
                                    computeCriteriaAverageRating={
                                      computeCriteriaAverageRating
                                    }
                                    computeTotalAveragePotentialRating={
                                      computeTotalAveragePotentialRating
                                    }
                                    getComputedCriteriaAverageRating={
                                      getComputedCriteriaAverageRating
                                    }
                                    groupName={group.groupName}
                                    index={cindex}
                                    blackOrWhiteText={blackOrWhiteText}
                                  ></RatingRowPerUsage>
                                ) : (
                                  <RatingRow
                                    key={criteria.key}
                                    criteria={criteria}
                                    currentProperty={currentProperty}
                                    setHelpDescription={setHelpDescription}
                                    getActualRatingByKey={getActualRatingByKey}
                                    getActualRatingObjectByKey={
                                      getActualRatingObjectByKey
                                    }
                                    computeCriteriaAverageRating={
                                      computeCriteriaAverageRating
                                    }
                                    computeTotalAveragePotentialRating={
                                      computeTotalAveragePotentialRating
                                    }
                                    groupName={group.groupName}
                                    index={cindex}
                                    blackOrWhiteText={blackOrWhiteText}
                                  ></RatingRow>
                                );
                              })}
                            </tbody>
                          </React.Fragment>
                        );
                      })}
                  </table>
                </div>
              </div>
            </Scrollbars>

            <div
              className="ant-table ant-table-small ant-table-bordered"
              style={{
                borderRight: "solid 1px rgb(240,240,240)",
              }}
            >
              <div>
                <table>
                  <colgroup>
                    <col style={{ width: "50px" }}></col>
                    <col style={{ width: "38%" }}></col>
                    <col style={{ width: "10%" }}></col>
                    <col style={{ width: "28%" }}></col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th align={"left"}></th>
                      <th
                        className="ant-table-cell"
                        style={{
                          textAlign: "left",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <strong style={{ textTransform: "uppercase" }}>
                          {t("admin_rating.total")}
                        </strong>
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <strong>{`${(
                          Math.round(
                            parseFloat(getPropertyRating(currentProperty)) * 100
                          ) / 100
                        ).toFixed(2)}`}</strong>
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <strong>{`${(
                          Math.round(parseFloat(totalAverageRating) * 100) / 100
                        ).toFixed(2)}`}</strong>
                      </th>
                      <th align={"center"}></th>
                      <th align={"center"}></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </Col>
          <Col span={11}>
            <Row>
              <Col flex={"auto"}>
                <Card>
                  <Title level={2}>
                    {t("admin_rating.rating")}{" "}
                    <span style={{ float: "right", zoom: 1.4 }}>
                      {(
                        Math.round(parseFloat(totalAverageRating) * 100) / 100
                      ).toFixed(2)}
                    </span>
                  </Title>

                  {/* <Statistic
                    title={t("admin_rating.rating")}
                    precision={2}
                    value={(
                      Math.round(parseFloat(totalAverageRating) * 100) / 100
                    ).toFixed(2)}
                    style={{ zoom: 2.4, textAlign: "center" }}
                  ></Statistic> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col flex={"auto"}>
                <Card
                  title={
                    helpGroup &&
                    parse(
                      `<strong class="${helpCardStyle}">${helpGroup}</strong> ${helpTextTitle} ${
                        helpChild && `- ${helpChild.toLocaleUpperCase()}`
                      }`
                    )
                    // <div
                    //   style={{
                    //     color: `${blackOrWhiteText(
                    //       group && COLORS_SERIES[group]
                    //     )}`,
                    //   }}
                    // >
                    //   {helpTextTitle}
                    // </div>
                  }
                  style={{ marginTop: "20px" }}
                  className={`${helpCardStyle}-criteria`}
                >
                  <Scrollbars
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={550}
                  >
                    <RatingCriteriaList
                      helpIntro={helpText.intro}
                      helpManual={helpText.manual}
                      helpAutomatic={helpText.automatic}
                      helpFoto={helpFoto}
                      helpFotoGoogle={helpFotoGoogle}
                      helpFotoPlan={helpFotoPlan}
                      helpFotoGooglePlan={helpFotoGooglePlan}
                      helpPlan={helpPlan}
                      helpMessungen={helpMessungen}
                      helpWebLink={helpWebLink}
                      helpSonstige={helpSonstige}
                    />
                    {/* {helpText.intro != "" && (
                      <div className={"rating-help-group"}>
                        <p className="bold">{t("criteria.requirements")}</p>
                        <p className="content">{parse(helpText.intro)}</p>
                      </div>
                    )}
                    {helpText.manual != "" && (
                      <div className={"rating-help-group"}>
                        <p className="bold">{t("criteria.manual")}</p>
                        <p className="content">{parse(helpText.manual)}</p>
                      </div>
                    )}
                    {helpText.automatic != "" && (
                      <div className={"rating-help-group"}>
                        <p className="bold">{t("criteria.automatic")}</p>
                        <p className="content">{parse(helpText.automatic)}</p>
                      </div>
                    )}
                    <div className={"rating-help-group"}>
                      {(helpFoto != "" ||
                        helpPlan != "" ||
                        helpMessungen != "" ||
                        helpWebLink != "" ||
                        helpSonstige != "") && (
                        <p className="bold">{t("criteria.proof")}</p>
                      )}

                      {helpFoto != "" && (
                        <div className={"rating-help-group"}>
                          <p className="nachweise">{t("criteria.foto")}</p>
                          <p className="content">{parse(helpFoto)}</p>
                        </div>
                      )}
                      {helpPlan != "" && (
                        <div className={"rating-help-group"}>
                          <p className="nachweise">{t("criteria.plan")}</p>
                          <p className="content">{parse(helpPlan)}</p>
                        </div>
                      )}
                      {helpMessungen != "" && (
                        <div className={"rating-help-group"}>
                          <p className="nachweise">{t("criteria.messungen")}</p>
                          <p className="content">{parse(helpMessungen)}</p>
                        </div>
                      )}
                      
                      {helpWebLink != "" && (
                        <div className={"rating-help-group"}>
                          <p className="nachweise">{t("criteria.weblink")}</p>
                          <p className="content">{parse(helpWebLink)}</p>
                        </div>
                      )}
                      {helpSonstige != "" && (
                        <div className={"rating-help-group"}>
                          <p className="nachweise">{t("criteria.sonstige")}</p>
                          <p className="content">{parse(helpSonstige)}</p>
                        </div>
                      )}
                    </div> */}
                  </Scrollbars>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={16}></Col>
          <Col span={8}>
            <div align={"right"} style={{ margin: "20px" }}>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 16px)",
                    margin: "0 8px 0",
                  }}
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  {t("admin_rating.close")}
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    display: "inline-block",
                    width: "calc(33% - 16px)",
                    margin: "0 8px",
                  }}
                >
                  {t("admin_rating.save")}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default observer(PotentialRatingsModal);
