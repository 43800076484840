import { types,/*  flow, getRoot  */} from "mobx-state-tree";
/* import axios from "axios"; */

const PortfolioUserAccessModel = types
  .model('PortfolioUserAccessModel',{
    /* id: types.optional(types.number, 0), */
    portfolioId: types.optional(types.number, 0),
    userId: types.optional(types.number, 0),
    
  })


  export default PortfolioUserAccessModel;
