import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const FotoSelection = ({ selectedFoto, setSelectedFoto }) => {
  const { Option } = Select;
  const { t } = useTranslation("common");
  return (
    <Select
      style={{ width: "50%" }}
      placeholder={t("Foto...")}
      value={selectedFoto}
      onChange={setSelectedFoto}
    >
      <Option key={1} value={"foto"}>
        {t("criteria.foto")}
      </Option>
      <Option key={2} value={"fotoorgoogle"}>
        {t("criteria.fotoorgoogle")}
      </Option>
      <Option key={3} value={"fotoorgoogleorplan"}>
        {t("criteria.fotoorgoogleorplan")}
      </Option>
      <Option key={4} value={"fotoorplan"}>
        {t("criteria.fotoorplan")}
      </Option>
    </Select>
  );
};

export default FotoSelection;
