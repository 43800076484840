import { types, flow, getRoot } from "mobx-state-tree";
import axios from "axios";
import PortfolioModel from "./PortfolioModel";
import PropertyModel from "./PropertyModel";
import PropertyRatingModel from "./PropertyRatingModel";
import PotentialPropertyRatingModel from "./PotentialPropertyRatingModel";
import PropertyAuditLogModel from "./PropertyAuditLogModel";
import LoginModel from "./LoginModel";
const CompanyModel = types
  .model("CompanyModel", {
    id: types.optional(types.number, 0),

    name: types.optional(types.string, ""),
    address: types.optional(types.string, ""),
    plz: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    canton: types.optional(types.string, ""),
    region: types.optional(types.string, ""),
    type: types.optional(types.number, 3),

    status: types.optional(types.string, "active"),

    portfolioCountCached: types.optional(types.number, 0),
    propertyCountCached: types.optional(types.number, 0),

    portfolios: types.optional(types.array(PortfolioModel), []),
    users: types.optional(types.array(LoginModel), []),

    properties: types.optional(types.array(PropertyModel), []),

    ratingCached: types.optional(types.number, 0),
  })
  .actions((self) => ({
    update: flow(function* () {
      const { id, name, address, plz, city, canton, region, type } = self;
      const { status: responseStatus } = yield axios
        .put(`/api/clients/${id}`, {
          name,
          address,
          plz,
          city,
          canton,
          region,
          type,
        })
        .then((response) => response)
        .catch((error) => error.response);

      if (responseStatus !== 200) return false;
      return true;
    }),

    getPortfolios: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .get(`/api/portfolios/bycompany/${self.id}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolios) {
        self.portfolios = [];
        result.portfolios.forEach((c) => {
          const { id, name, investmentType, companyId, properties } = c;
          var portfolio = PortfolioModel.create({
            id,
            name,
            investmentType,
            companyId,
            properties: properties.map((p) => {
              return PropertyModel.create({
                id: p.id,
                name: p.name,
                portfolioId: p.portfolioId,
                address: p.address,
                age: p.age,
                lastTotalRenovation: p.lastTotalRenovation,
                plz: p.plz,
                city: p.city,
                canton: p.canton,
                region: p.region,
                areaOffice: parseFloat(p.areaOffice),
                areaResidential: parseFloat(p.areaResidential),
                areaCommercial: parseFloat(p.areaCommercial),
                areaBuilding: parseFloat(p.areaBuilding),
                areaHealth: parseFloat(p.areaHealth),
                ratings: p.ratings.map((r) =>
                  PropertyRatingModel.create({
                    id: r.id,
                    key: r.key,
                    value: parseFloat(r.value),
                    comment: r.comment,
                  })
                ),
                potentialRatings: p.potentialRatings.map((r) =>
                  PotentialPropertyRatingModel.create({
                    id: r.id,
                    key: r.key,
                    value: parseFloat(r.value),
                    comment: r.comment,
                  })
                ),
                auditLogs: p.auditLogs
                  ? p.auditLogs.map((r) =>
                      PropertyAuditLogModel.create({
                        id: r.id,
                        auditDate: r.auditDate,
                        comment: r.comment,
                      })
                    )
                  : [],
              });
            }),
          });
          self.portfolios.push(portfolio);
        });

        return true;
      } else return false;
    }),

    addPortfolio: flow(function* (
      name,
      investmentType,
      companyId,
      usersWithAccess = []
    ) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/portfolios/", {
          name,
          investmentType,
          companyId,
          usersWithAccess,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.portfolio) {
        var portfolio = PortfolioModel.create({
          ...result.portfolio,
        });
        self.portfolios.push(portfolio);

        return portfolio;
      } else return false;
    }),

    removePortfolio: flow(function* (portfolio) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post(`/api/portfolios/delete`, { portfolioId: portfolio.id })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        self.portfolios.splice(
          self.portfolios.findIndex((u) => u.id === portfolio.id),
          1
        );

        return true;
      } else return false;
    }),

    removePortfolioById: flow(function* (portfolioId) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post(`/api/portfolios/delete`, { portfolioId: portfolioId })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.success) {
        self.portfolios.splice(
          self.portfolios.findIndex((u) => u.id === portfolioId),
          1
        );

        return true;
      } else return false;
    }),

    //Properties
    getProperties: flow(function* () {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .get(`/api/properties/bycompany/${self.id}`)

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return false;

      if (result.properties) {
        self.properties = [];
        result.properties.forEach((c) => {
          const {
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice,
            areaResidential,
            areaCommercial,
            areaBuilding,
            areaHealth,
          } = c;
          var property = PropertyModel.create({
            id,
            name,
            portfolioId,
            address,
            age,
            lastTotalRenovation,
            plz,
            city,
            canton,
            region,
            areaOffice: parseFloat(areaOffice),
            areaResidential: parseFloat(areaResidential),
            areaCommercial: parseFloat(areaCommercial),
            areaBuilding: parseFloat(areaBuilding),
            areaHealth: parseFloat(areaHealth),
          });
          self.properties.push(property);
        });

        return true;
      } else return false;
    }),

    getUsers: flow(function* () {
      if (self.id < 1) return false;

      try {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = yield axios
          .get(`/api/users/bycompany/${self.id}`, {})

          .then((response) => response)
          .catch((error) => error.response);

        //setTimeout(function(){
        // self.status =isNaN(result)?1:result;
        if (responseStatus !== 200) return false;

        if (result.users) {
          self.users = [];
          result.users.forEach((c) => {
            var user = LoginModel.create({
              ...c,
              token: "",
              isLoggedIn: false,
              isAdmin: false,
            });
            self.users.push(user);
          });

          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    }),

    addUser: flow(function* (
      lastName,
      firstName,
      phoneNumber,
      mobileNumber,
      email,
      companyId,
      position,
      password,
      portfoliosWithAccess = [],
      sendInviteEmail
    ) {
      const root = getRoot(self);
      const language = root.language;
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post("/api/users/register", {
          lastName,
          firstName,
          email,
          phoneNumber,
          mobileNumber,
          companyId,
          position,
          password,
          portfoliosWithAccess,
          sendInviteEmail,
          language,
        })

        .then((response) => response)
        .catch((error) => error.response);

      //setTimeout(function(){
      // self.status =isNaN(result)?1:result;
      if (responseStatus !== 200) return { ...result, status: 409 } || false;

      if (result.user) {
        var user = LoginModel.create({
          ...result.user,
          token: "",
          isLoggedIn: false,
        });
        self.users.push(user);

        return user;
      } else return false;
    }),
    removeUser: flow(function* (id, permanent = 0) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .delete(`/api/users/${id}/${permanent}`, {})
        .then((response) => response)
        .catch((error) => error.response);

      var status = false;
      if (responseStatus !== 200) status = false;
      else status = result || false;

      if (status === true) {
        if (permanent === 1) {
          self.users.splice(
            self.users.findIndex((u) => u.id === id),
            1
          );
        } else {
          self.users.find((u) => u.id === id).setStatus(false);
        }
      }

      return status;
    }),

    activateUser: flow(function* (id) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = yield axios
        .post(`/api/users/activate`, { id })
        .then((response) => response)
        .catch((error) => error.response);

      var status = false;
      if (responseStatus !== 200) status = false;
      else status = result || false;

      self.users.find((u) => u.id === id).setStatus(status);

      return status;
    }),

    setName(value) {
      self.name = value;
    },
    setAddress(value) {
      self.address = value;
    },
    setPlz(value) {
      self.plz = value;
    },
    setCity(value) {
      self.city = value;
    },
    setCanton(value) {
      self.canton = value;
    },
    setRegion(value) {
      self.region = value;
    },
    setType(value) {
      self.type = value;
    },
  }))
  .views((self) => {
    return {
      get rating() {
        var ratingCount = 0;
        return 0;
      },
      get portfolioCount() {
        return self.portfolios.length;
      },
      get propertyCount() {
        var all = 0;
        self.portfolios.forEach((pf) => {
          all += pf.properties.length;
        });
        return all;
      },

      get totalArea() {
        var all = 0.0;
        self.portfolios.forEach((pf) => {
          //all += pf.properties.areaCommercial+;
          pf.properties.forEach((p) => {
            all +=
              p.areaCommercial +
              p.areaOffice +
              p.areaResidential +
              p.areaBuilding +
              p.areaHealth;
          });
        });
        return all;
      },
    };
  });

export default CompanyModel;
