import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import CompanyModel from "../../../models/CompanyModel";
import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  Space,
  Table,
  Modal,
  Select,
} from "antd";

//translation
import { useTranslation } from "react-i18next";

const { Option } = Select;

const { Title } = Typography;

const CompanyDetailModal = (props) => {
  const { t } = useTranslation("common"); //translation

  const {
    store,
    isCompanyModalVisible,
    setIsCompanyModalVisible,
    postal_codes,
    cities,
    cantons,
    regions,
    search,
    setSearchKeyword,
  } = props;

  const xloadCompanyList = (s = "") => {
    /*  store.getCompanys().then(s=>{
      search(s);
      setTimeout(() => {
        search('');
      }, 500);
     
    }); */
  };

  const companyId = store.activeAdminCompanyId;
  const [selectedCompany, setSelectedCompany] = useState(
    CompanyModel.create({})
  );

  useEffect(() => {
    if (companyId === 0) {
      setSelectedCompany(CompanyModel.create({}));
    } else {
      setSelectedCompany(store.administrators.find((c) => c.id === companyId));
    }
    return () => {
      setSelectedCompany(CompanyModel.create({}));
    };
  }, [companyId]);

  const [CompanyFormMessage, setCompanyFormMessage] = useState("");

  const plzs = postal_codes
    .map((r) => {
      return { value: r.plz };
    })
    .filter(function(obj, index, self) {
      return (
        index ===
        self.findIndex(function(t) {
          return t["value"] === obj["value"];
        })
      );
    });

  const cts = cities.map((r) => {
    return { value: r.city };
  });
  const cants = cantons.map((r) => {
    return { value: r.name };
  });

  useEffect(() => {
    setCompanyFormMessage("");

    setIsCompanyModalVisible(false);

    setTimeout(() => {
      //setPlzOptions(plzs);
      setPlzOptions([]);
      //setCityOptions(cts);
      setCityOptions([]);
      //setCantonOptions(cants);
      setCantonOptions([]);
      //console.log(plzs);
    }, 500);

    return () => {};
  }, []);

  const [form] = Form.useForm();
  const onCompanyFormFinish = (values) => {
    console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}

    if (selectedCompany.id > 0) {
      selectedCompany.setName(values.name);
      selectedCompany.setAddress(values.address);
      selectedCompany.setPlz(values.plz);
      selectedCompany.setCity(values.city);
      selectedCompany.setCanton(values.canton);
      selectedCompany.update().then((result) => {
        if (result) {
          setSearchKeyword("");
          setCompanyFormMessage(
            t("company_detail.company_information_updated")
          );
        } else {
          setCompanyFormMessage(
            t("company_detail.cannot_update_company_information")
          );
        }
      });
    } else {
      // this is an new client
      store
        .addAdministratorCompany(
          values.name,
          values.address,
          values.plz,
          values.city,
          values.canton,
          values.region
        )
        .then((result) => {
          if (result && result.id) {
            setSearchKeyword(values.name);

            setTimeout(() => {
              setSearchKeyword("");
            }, 1000);
            setCompanyFormMessage(t("company_detail.new_company_added"));
            setTimeout(() => {
              setIsCompanyModalVisible(false);
              /*  setCompanyId(0); */
              setCompanyName("");
              setPlz("");
              setCity("");
              setCanton("");
            }, 2000);
          } else {
            setCompanyFormMessage(
              t("company_detail.cannot_add_company_information")
            );
          }
        });
    }
  };

  const onCompanyFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* const [companyId, setCompanyId] = useState(0); */
  const [clientName, setCompanyName] = useState("");
  const [clientNameValidationState, setCompanyNameValidationState] = useState(
    ""
  );
  const [address, setAddress] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [canton, setCanton] = useState("");

  const [plzOptions, setPlzOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cantonOptions, setCantonOptions] = useState([]);

  useEffect(() => {
    if (selectedCompany) {
      /*  setCompanyId(selectedCompany.id); */
      setCompanyName(selectedCompany.name);
      setAddress(selectedCompany.address);
      setPlz(selectedCompany.plz);
      setCity(selectedCompany.city);
      setCanton(selectedCompany.canton);

      form.setFieldsValue({ ...selectedCompany });
    } else {
      /* setCompanyId(0); */
      setCompanyName("");
      setAddress("");
      setPlz("");
      setCity("");
      setCanton("");
      form.resetFields();
    }
    return () => {
      // nothing yet
    };
  }, [selectedCompany]);

  const onChangeCompanyName = (e) => {
    console.log("CompanyName changed", e);
    setCompanyNameValidationState("validating");

    store.clientExists(e.target.value).then((res) => {
      if (res.exists === false) setCompanyNameValidationState("success");
      else {
        if (selectedCompany) {
          if (res.client.id === selectedCompany.id)
            setCompanyNameValidationState("success");
          //this is the same client
          else setCompanyNameValidationState("error");
        }
      }
    });
  };
  const onSelectPLZ = (data) => {
    console.log("onSelect", data);
  };
  const onChangePLZ = (data) => {
    console.log("onChangePLZ", data);
    setPlz(data);
    // if exact match then filter city autocomplete list
    if (plzs.find((r) => r.value === data)) {
      //set cantons
      var plzdata = postal_codes.find((p) => p.plz === data);
      if (plzdata) {
        /*  var cantonIds = postal_codes
          .filter((p) => p.plz === thiscity.plz)
          .map((x) => x.cantonId); 
        
          var filteredCantons = cantons.filter(function (canton) {
          return cantonIds.indexOf(canton.id) != -1;
        }); */

        var filteredCantons = cantons.filter(
          (canton) => canton.id === plzdata.cantonId
        );

        setCantonOptions(
          filteredCantons.length > 0
            ? filteredCantons.map((r) => {
                return { value: r.id, name: r.name };
              })
            : []
        );

        if (filteredCantons.length > 0) {
          setCanton(filteredCantons[0].id);

          form.setFieldsValue({ canton: filteredCantons[0].id });

          if (filteredCantons.length === 1) {
            var region = regions.find(
              (r) => r.id === filteredCantons[0].regionId
            );
            if (region) form.setFieldsValue({ region: region.name });
          }
        }
      } else {
        setCantonOptions([]);
      }

      var filteredCities = cities.filter((c) => c.plz === data);

      setCityOptions(
        filteredCities.map((r) => {
          return { value: r.city };
        })
      );

      if (filteredCities.length > 0) {
        setCity(filteredCities[0].city);
        form.setFieldsValue({ city: filteredCities[0].city });
      }
    } else {
      form.setFieldsValue({ city: "", canton: "", region: "" });
      setCityOptions([]);
      setCantonOptions([]);
    }
  };
  const onSearchPLZ = (searchText) => {
    setPlzOptions(plzs.filter((r) => r.value.indexOf(searchText) > -1));
  };
  const onChangeCity = (data) => {
    console.log("onChangeCity", data);
    setCity(data);
    // if exact match then filter city autocomplete list
    var thiscity = cities.find((r) => r.city === data);
    if (thiscity) {
      console.log(thiscity);
    }
  };
  const onChangeCanton = (data) => {
    console.log("onChangeCanton", data);
    var canton = cantons.find((c) => c.id === data);
    if (canton) {
      var region = regions.find((r) => r.id === canton.regionId);
      if (region) form.setFieldsValue({ region: region.name });
    }
  };
  return (
    <Modal
      destroyOnClose
      title={false}
      centered
      visible={isCompanyModalVisible}
      okText={t("company_detail.submit")}
      closable={false}
      footer={false}
      className={"ant-custom"}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onCompanyFormFinish}
        onFinishFailed={onCompanyFormFinishFailed}
      >
        <Row type="flex" align="left">
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "100%",
            }}
            level={3}
          >
            {companyId === 0
              ? t("company_detail.add_new")
              : t("company_detail.existing")}{" "}
            {t("company_detail.administrator_company")}
          </Title>
        </Row>
        <Form.Item
          label={t("company_detail.company_name")}
          name="name"
          validateStatus={clientNameValidationState}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("company_detail.please_enter_company_name"),
            },
          ]}
          onChange={onChangeCompanyName}
        >
          <Input value={clientName} />
        </Form.Item>
        <Form.Item
          label={t("company_detail.address")}
          name="address"
          rules={[
            {
              required: true,
              message: t("company_detail.please_enter_company_address"),
            },
          ]}
        >
          <Input value={address} />
        </Form.Item>
        <Form.Item
          label={t("company_detail.plz")}
          name="plz"
          rules={[
            {
              required: true,
              message: t("company_detail.plz"),
            },
          ]}
        >
          <AutoComplete
            options={plzOptions}
            onSelect={onSelectPLZ}
            onSearch={onSearchPLZ}
            onChange={onChangePLZ}
            placeholder={t("company_detail.plz")}
          >
            <Input value={plz} />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={t("company_detail.city")}
          name="city"
          rules={[
            {
              required: true,
              message: t("company_detail.please_enter_the_postal_code"),
            },
          ]}
        >
          {/*  <AutoComplete
            options={cityOptions}
            
            onChange={onChangeCity}
            placeholder="City"
          >
            <Input value={city} />
          </AutoComplete> */}

          <Select onChange={onChangeCity}>
            {cityOptions.map((city) => (
              <Option key={city.value} value={city.value}>
                {city.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("company_detail.canton")}
          name="canton"
          rules={[
            {
              required: true,
              message: t("company_detail.please_enter_the_canton"),
            },
          ]}
        >
          {/*    <AutoComplete
            options={cantonOptions}
         
            placeholder="Canton"
          >
            <Input value={canton} />
          </AutoComplete> */}

          <Select onChange={onChangeCanton}>
            {cantonOptions.map((canton) => (
              <Option key={canton.value} value={canton.value}>
                {canton.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t("company_detail.region")} name="region">
          <Input readOnly />
        </Form.Item>
        <Form.Item>
          <Button
            type="default"
            htmlType="button"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              margin: "0 8px 0",
            }}
            onClick={() => {
              setIsCompanyModalVisible(false);
            }}
          >
            {t("company_detail.cancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              margin: "0 8px",
            }}
          >
            {t("company_detail.submit")}
          </Button>
        </Form.Item>
        <div
          style={{
            display: "block !important",
            textAlign: "center",
            width: "100%",
          }}
        >
          {CompanyFormMessage}
        </div>
      </Form>
    </Modal>
  );
};

export default inject("store")(observer(CompanyDetailModal));
