import React, { useState } from "react";
import { observer } from "mobx-react";
import { Space, Table } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import ResizableTitle from "../Common/ResizableTitle";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
//translation
import { useTranslation } from "react-i18next";
import "../ipadCss/m_custom.css";

function ClientListTable({
  dataSource,
  isEditMode,
  isLoading,
  hasData,
  showClientDrawer,
}) {
  const { t } = useTranslation("common"); //translation

  let _columnSizes = localStorage.getItem(`clientColumnSizes`)
    ? JSON.parse(localStorage.getItem(`clientColumnSizes`))
    : null;
  const initialcolumns = [
    {
      title: "admin.name",
      width: _columnSizes ? _columnSizes["adminName"] : 420,
      dataIndex: "name",
      key: "id",
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <a
          title={t("admin.view_detail")}
          onClick={() => {
            showClientDrawer(record.key);
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: "admin.address",
      width: _columnSizes ? _columnSizes["address"] : 260,
      dataIndex: "address",
      key: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "admin.city",
      width: _columnSizes ? _columnSizes["city"] : 100,
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "admin.products",
      width: _columnSizes ? _columnSizes["products"] : 80,
      dataIndex: "portfolioCount",
      key: "portfolioCount",
      sorter: (a, b) => a.portfolioCount - b.portfolioCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "admin.properties",
      width: _columnSizes ? _columnSizes["properties"] : 110,
      dataIndex: "propertyCount",
      key: "propertyCount",
      sorter: (a, b) => a.propertyCount - b.propertyCount,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "admin.rating",
      width: _columnSizes ? _columnSizes["rating"] : 70,
      dataIndex: "rating",
      key: "rating",
      sorter: (a, b) => a.rating - b.rating,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => parseFloat(record.rating).toFixed(2),
    },
    {
      title: "admin.m2",
      dataIndex: "totalArea",
      key: "totalArea",
      sorter: (a, b) =>
        (a.totalArea ? a.totalArea : 0) - (b.totalArea ? b.totalArea : 0),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <div
          style={{ textAlign: "right" }}
          key={record.id}
        >{`${record.totalArea.toLocaleString("de-ch", {
          minimumFractionDigits: 2,
        })}`}</div>
      ),
      width: _columnSizes ? _columnSizes["m2"] : 90,
    },
    {
      title: "admin.dashboard",
      width: 100,
      key: "id",
      render: (text, record) => (
        <Space size="middle">
          <Link
            title={t("admin.view_dashboard")}
            to={`ClientDashboard/${record.key}`}
          >
            <AppstoreOutlined />
          </Link>
        </Space>
      ),
    },
  ];
  const [columns, setColumns] = useState(initialcolumns);

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      const _cSizes = nextColumns.reduce((_nc, _obj) => {
        let _name;

        if (_obj.title === "Name" || _obj.title === "Nom") {
          _name = "adminName";
        }

        if (_obj.title === "Adresse") {
          _name = "address";
        }
        if (_obj.title === "Ort" || _obj.title === "Lieu") {
          _name = "city";
        }
        if (_obj.title === "Produkte" || _obj.title === "Produits") {
          _name = "products";
        }
        if (
          _obj.title === "Anz. Liegenschaften" ||
          _obj.title === "Immeubles"
        ) {
          _name = "properties";
        }
        if (_obj.title.type === "span") {
          _name = "m2";
        }
        if (_obj.title === "Ratings" || _obj.title === "Évaluation") {
          _name = "rating";
        }

        return {
          ..._nc,
          [_name]: _obj.width ? _obj.width : 0,
        };
      }, {});
      _columnSizes = _cSizes;
      localStorage.setItem(`clientColumnSizes`, JSON.stringify(_cSizes));

      setColumns(nextColumns);
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    title: parse(t(col.title)),
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <>
      <Table
        className={"table-resizeable admin__client_table"}
        scroll={{ y: "1000" }}
        dataSource={dataSource}
        components={components}
        columns={resizableColumns}
        // columns={initialcolumns}
        pagination={{ pageSize: 20 }}
        size="small"
        bordered
        rowSelection={isEditMode}
        loading={isLoading}
        hasData={hasData}
        footer={() => dataSource.length + " " + t("admin.clients")}
      />
      {/*>
              {resizableColumns.map((col) => (
                <Table.Column
                  key={col.key}
                  title={t(col.title)}
                  dataIndex={col.dataIndex}
                  width={col.width}
                  sorter={col.sorter}
                  defaultSortOrder={col.defaultSortOrder}
                  sortDirections={col.sortDirections}
                  render={col.render}
                />
              ))}
            </Table>*/}
    </>
  );
}

export default observer(ClientListTable);
