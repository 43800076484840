import React, { useState,useEffect } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "antd";

//translation
import { useTranslation } from "react-i18next";

const PropertyAuditList = (props) => {
  const { t } = useTranslation("common"); //translation

  const { AuditLogs, property, isAdmin,store } = props;
  const [busy, setBusy] = useState(false);

  const [list, setList] = useState(AuditLogs);


  const removeAuditLog = (id) => {
    setBusy(true);

    property.removeAuditLog(id).then((s) => {
      store.setClientListUpdateOn(new Date().getTime().toString());
      console.log(s);
      setBusy(false);
    });
  };

useEffect(() => {
  setList(AuditLogs);
  return () => {
    setList([])
  }
}, [AuditLogs,store.clientListUpdateOn])

  return (
    <div className="ant-table-wrapper">
      <div
        className="ant-table ant-table-small ant-table-bordered"
        style={{ borderRight: "solid 1px rgb(240,240,240)" }}
      >
        <div className="ant-table-container">
          <div className="ant-table-content">
            <table style={{ tableLayout: "auto" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th
                    className="ant-table-cell"
                    align={"center"}
                    style={{ width: "200px", textAlign: "center" }}
                  >
                    {t("audit.audit_date")}
                  </th>
                  <th className="ant-table-cell" align={"center"}>
                    {t("audit.comment")}
                  </th>
                  {isAdmin && (
                    <th className="ant-table-cell" align={"center"}></th>
                  )}
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {list.map((a) => {
                  return (
                    <tr
                      key={a.id}
                      className="ant-table-row ant-table-row-level-0"
                    >
                      <td
                        className="ant-table-cell"
                        style={{ width: "200px", textAlign: "center" }}
                      >
                        {new Date(a.auditDate).toLocaleDateString("de", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </td>
                      <td className="ant-table-cell">{a.comment}</td>
                      {isAdmin && (
                        <td className="ant-table-cell" align={"center"}>
                          <Button
                            loading={busy}
                            onClick={() => {
                              removeAuditLog(a.id);
                            }}
                          >
                            {t("admin.delete")}
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default inject("store")(observer(PropertyAuditList));

