import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  COLORS_SERIES1,
  COLORS_SERIES2,
  COLORS_SERIES_BENCHMARK,
  COLORS_SERIES_BENCHMARK2,
} from "../Charts";
import axios from "axios";

import { Chart, Line, Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Card,
  Typography,
  Space,
  Button,
  Select,
  Statistic,
  Empty,
  TreeSelect,
  Tooltip,
  Affix,
  Spin,
  Modal,
  Result,
  Popconfirm,
  Popover,
  Avatar,
} from "antd";
const COLORS_BENCHMARK = [
  COLORS_SERIES_BENCHMARK[0],
  COLORS_SERIES_BENCHMARK2[0],
];
const { Title } = Typography;
const BarRenderUsageType = ({ resultSet, selectionModeB, t }) => {
  if (!resultSet.length || resultSet.length == 0) {
    var emptyDesc =
      t("admin_dashboard.select_portfolios_in_set_a") +
      (selectionModeB === "benchmark" ? "" : t("admin_dashboard.and_set_b")) +
      ".";
    return <Empty description={emptyDesc}></Empty>;
  }
  /*   const categories = resultSet
        .filter((value, index, self) => {
          return self.indexOf(x=>x.category_id==value.category_id) === index;
        })
        .sort((a, b) => a.category_id > b.category_id).map(c=>c.category); */

  const categories = resultSet
    .map((r) => {
      return {
        category_id: r.category_id,
        category: t("chart_dataset_names." + r.category),
      };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(
          (tc) => tc.category_id === v.category_id && tc.category === v.category
        ) === i
    )
    .sort((a, b) =>
      a.category_id > b.category_id ? 1 : b.category_id > a.category_id ? -1 : 0
    )
    .map((c) => c.category);

  const datasetnames = resultSet
    .map((c) => c.dataset)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  // var CompareToBenchMarkB = true;
  const datasets = datasetnames.map((d, index) => {
    return {
      label: d,
      data: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s) => {
          return s.value ? parseFloat(s.value).toFixed(2) : 0;
        }),
      backgroundColor: () => {
        var color = "";
        if (d.indexOf("SET") > -1) {
          if (d.indexOf(" A") > -1) color = COLORS_SERIES1[0];
          else color = COLORS_SERIES1[1];
        } else if (d.indexOf("SSREI") > -1) {
          if (d.indexOf(" A") > -1) color = COLORS_SERIES1[0];
          else color = COLORS_SERIES1[1];
        } else {
          color = COLORS_SERIES1[index];
        }
        return color;
        /*  if (d == "Set A") {
          return COLORS_SERIES1[0];
        } else if (d == "Set B") {
          return COLORS_SERIES1[0];
        } else if (d == "SSREI A") {
          //return COLORS_BENCHMARK[index];
          return COLORS_SERIES1[0];
        } else if (d == "SSREI B") {
          return COLORS_BENCHMARK[0];
          //return COLORS_SERIES2[index];
        } else if (d.indexOf(" B") == 0) {
          return COLORS_BENCHMARK[0];
          //return COLORS_SERIES2[index];
        } else return COLORS_SERIES1[0]; */
      },
      fill: true,
    };
  });

  const data = {
    labels: categories /* resultSet.map((c) => c.category) */,
    datasets: datasets /* resultSet.map((s, index) => {
          var CompareToBenchMarkB = true;
          return {
            label: s.dataset,
            data: s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0,
            backgroundColor: CompareToBenchMarkB
              ? COLORS_SERIES1[index % 2]
              : COLORS_SERIES2[index % 2],
            fill: false,
          };
        }) */,
  };
  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          // data for manipulation
          return data.datasets[tooltipItem.datasetIndex].data[
            tooltipItem.index
          ];
        },
      },
    },
    scales: { xAxes: [{ stacked: false }] },
    maintainAspectRatio: false,
    legend: { display: true },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 3,
            stepSize: 1,
          },
        },
      ],
    },
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        display: "auto",
        color: "#000000",
        anchor: "end",
        align: "top",
        offset: 4,
        formatter: function (value, context) {
          /* return context.dataIndex + ': ' + Math.round(value*100) + '%'; */
          return parseFloat(value).toFixed(2);
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
};
const TwoSetComparisonPieChart = (props) => {
  const {
    t,
    title,
    subtitle,
    apiEndPoints /* { "benchmark":"RatingByUsageBenchmark", "set":"RatingByUsageSetWIthName" } */,
    selectionModeA,
    selectionModeB,
    filtersA,
    filtersB,
    setNameA,
    setNameB,

    ...restProps
  } = props;

  const [chartData, setChartData] = useState(false);
  const [hasData, setHasData] = useState(false);

  const pfLabels = {
    "pf-1": t("admin_dashboard.portfoliotype_1"),
    "pf-2": t("admin_dashboard.portfoliotype_2"),
    "pf-3": t("admin_dashboard.portfoliotype_3"),
    "pf-4": t("admin_dashboard.portfoliotype_4"),
  };
  const requestChartData = async () => {
    var dataset = [];
    if (selectionModeA === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI A" };
        }),
      ];
    } else if (selectionModeA === "set") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {
          portfolioIds: filtersA.map((v) => {
            return parseInt(v.split("-")[1]);
          }),
          setName: "Set A",
        })

        .then((response) => response)
        .catch((error) => error.response);

      if (result.length && result.length > 0) {
        dataset = [
          ...dataset,
          ...result.map((r) => {
            return { ...r, dataset: setNameA };
          }),
        ];
      }
    } else if (["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeA) > -1) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeA]}`, {
          id: selectionModeA.split("-")[1],
          setName: pfLabels[selectionModeA] + " A",
        })

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [...dataset, ...result];
    }
    //todo by porfolio tyoes

    if (selectionModeB === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI B" };
        }),
      ];

      //setHasData(true);
    } else if (["pf-1", "pf-2", "pf-3", "pf-4"].indexOf(selectionModeB) > -1) {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {
          id: selectionModeB.split("-")[1],
          setName: pfLabels[selectionModeB] + " B",
        })

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [...dataset, ...result];
    } else if (selectionModeB === "set") {
      if (filtersB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/${apiEndPoints[selectionModeB]}`, {
            portfolioIds: filtersB.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set B",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [
          ...dataset,
          ...result.map((r) => {
            return { ...r, dataset: setNameB };
          }),
        ];
      } else {
        //setHasData(false);
      }
    }
    setChartData(dataset);
    setHasData(dataset.length > 0);
    //  setUsageBarChartData(dataset);
    // setHasUsageBarChartData(true);
  };

  useEffect(() => {
    //requestThreeYearRatings();

    requestChartData();

    return () => {
      //setUsageBarChartData(false);
    };
  }, [filtersA, filtersB, selectionModeA, selectionModeB]);
  return (
    <Card style={{ height: "430px" }}>
      <Title level={3} style={{ textAlign: "center" }}>
        {title}
        {subtitle ? subtitle : undefined}
      </Title>
      <div className={"dashboard-chart-area"}>
        <BarRenderUsageType
          resultSet={chartData}
          selectionModeB={selectionModeB}
          t={t}
        ></BarRenderUsageType>
      </div>
    </Card>
  );
};

export default TwoSetComparisonPieChart;
