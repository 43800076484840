import { toJS } from "mobx";

const criteria = [
  {
    groupName: "GESELLSCHAFT",
    children: [
      {
        code: "G1",
        key: "102.1",
        title: "102.1 Städtebau und Architekturs",
        help: true,
        manual: true,
        automatic: true,
        weblink: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        plan: true,
        messungen: true,
        sonstige: true,
      },
      {
        code: "G2",
        key: "103.1",
        title: "103.1 Nutzungsdichte",
        children: [
          {
            key: "103.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "103.1.2",
            title: "Büro",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "103.1.3",
            title: "Retail",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "103.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "103.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "G3",
        key: "103.2",
        title: "103.2 Nutzungsangebot im Quartier",
        help: true,
        manual: true,
        weblink: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "G4",
        key: "103.3",
        title: "103.3 Hindernisfreies Bauen",
        help: true,
        manual: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        plan: true,
        automatic: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "G5",
        key: "104.1",
        title: "104.1 Angebot halböffentliche Innenräume",
        help: true,
        children: [
          {
            key: "104.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            weblink: true,
            messungen: true,
            sonstige: true,
          },
          {
            key: "104.1.2",
            title: "Büro",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            weblink: true,
            messungen: true,
            sonstige: true,
          },
          {
            key: "104.1.3",
            title: "Retail",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            weblink: true,
            messungen: true,
            sonstige: true,
          },
          {
            key: "104.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "104.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "G6",
        key: "104.2",
        title: "104.2 Angebot halböffentliche Aussenräume",
        help: true,
        manual: true,
        automatic: true,
        weblink: true,
        plan: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        sonstige: true,
      },
      {
        code: "G7",
        key: "104.3",
        title: "104.3 Subjektive Sicherheit",
        help: true,
        manual: true,
        plan: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "G8",
        key: "105.1",
        title: "105.1 Nutzungsflexibilität und -variabilität",
        children: [
          {
            key: "105.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            plan: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.1.2",
            title: "Büro",
            help: true,
            manual: true,
            plan: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.1.3",
            title: "Retail",
            help: true,
            manual: true,
            plan: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "G9",
        key: "105.2",
        title: "105.2 Gebrauchsqualität privater Innen- und Aussenräume",
        children: [
          {
            key: "105.2.1",
            title: "Wohnen",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            automatic: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.2.2",
            title: "Büro",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            automatic: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.2.3",
            title: "Retail",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            automatic: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.2.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "105.2.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "G10",
        key: "106.1",
        title: "106.1 Tageslicht",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        plan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "G11",
        key: "106.2",
        title: "106.2 Schallschutz externe und interne Quellen",
        help: true,
        manual: true,
        automatic: true,
        plan: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        weblink: true,
        messungen: true,
        sonstige: true,
      },
      {
        code: "G12",
        key: "107.1",
        title: "107.1 Raunmluftqualität",
        help: true,
        manual: true,
        automatic: true,
        plan: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "G13",
        key: "107.2",
        title: "107.2 Strahlungen (Radon)",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        weblink: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "G14",
        key: "108.1",
        title: "108.1 Behaglichkeit im Sommer ",
        children: [
          {
            key: "108.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "108.1.2",
            title: "Büro",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "108.1.3",
            title: "Retail",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            plan: true,
            messungen: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "108.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "108.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "G15",
        key: "108.2",
        title: "108.2 Behaglichkeit im Winter ",
        help: true,
        manual: true,
        automatic: true,
        plan: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
    ],
  },
  {
    groupName: "WIRTSCHAFT",
    children: [
      {
        code: "W1",
        key: "201.1",
        title: "201.1 Lebenszykluskosten",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W2",
        key: "201.2",
        title: "201.2 Betriebskonzept",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W3",
        key: "202.1",
        title: "202.1 Bauweise, Bauteile und Bausubstanz",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        plan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W4",
        key: "203.1",
        title: "203.1 Entscheidungsfindung",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W5",
        key: "204.1",
        title: "204.1 Altlastenbelastung",
        help: true,
        manual: true,
        automatic: true,
        weblink: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "W6",
        key: "204.2",
        title: "204.2 Naturgefahren und Erdbebensicherheit",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        weblink: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "W7",
        key: "204.3",
        title: "204.3 Technische Erschliessung",
        help: true,
        manual: true,
        weblink: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "W8",
        key: "205.1",
        title: "205.1 Erreichbarkeit ",
        help: true,
        manual: true,
        weblink: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "W9",
        key: "205.2",
        title: "205.2 Zugang Parzelle / verkehrstechnische Erschliessung",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W10",
        key: "206.1",
        title: "206.1 Mietpreise",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: true,
        fotoorplan: true,
        fotoorgoogleorplan: true,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "W11",
        key: "207.1",
        title: "207.1 Nachfrage und Nutzungsangebot",
        children: [
          {
            key: "207.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "207.1.2",
            title: "Büro",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "207.1.3",
            title: "Retail",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "207.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "207.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: true,
            fotoorplan: true,
            fotoorgoogleorplan: true,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
    ],
  },
  {
    groupName: "UMWELT",
    children: [
      {
        code: "U1",
        key: "301.1",
        title: "301.1 Primärenergie nicht erneuerbar Erstellung",
        help: true,
        manual: true,
        automatic: true,
        plan: true,
        foto: false,
        fotoorgoogle: false,
        fotoorplan: false,
        fotoorgoogleorplan: true,
        messungen: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "U2",
        key: "301.2",
        title: true,
        help: true,
        manual: true,
        automatic: true,
        foto: false,
        fotoorgoogle: true,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "U3",
        key: "303.2",
        title: "303.2 Ressourcenschonung",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: false,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "U4",
        key: "303.3",
        title: "303.3 Umwelt-, entsorgungs-/gesundheitsrelevante Bestandteile",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: false,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "U5",
        key: "304.2",
        title: "304.2 Energieverbrauch",
        help: true,
        children: [
          {
            key: "304.2.1",
            title: "Wohnen",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.2.2",
            title: "Büro",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.2.3",
            title: "Retail",
            help: true,
            manual: true,
            automatic: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.2.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.2.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "U6",
        key: "304.3",
        title: "304.3 Abfallentsorgung",
        help: true,
        children: [
          {
            key: "304.3.1",
            title: "Wohnen",
            help: true,
            manual: true,
            foto: false,
            fotoorgoogle: true,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            weblink: true,
            automatic: true,
            messungen: true,
            plan: true,
            sonstige: true,
          },
          {
            key: "304.3.2",
            title: "BÜRO",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.3.3",
            title: "Retail",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.3.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "304.3.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "U7",
        key: "305.1",
        title: "305.1 Mobilitätskonzept",
        children: [
          {
            key: "305.1.1",
            title: "Wohnen",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            weblink: true,
            automatic: true,
            messungen: true,
            plan: true,
            sonstige: true,
          },
          {
            key: "305.1.2",
            title: "Büro",
            help: true,
            manual: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            weblink: true,
            automatic: true,
            messungen: true,
            plan: true,
            sonstige: true,
          },
          {
            key: "305.1.3",
            title: "Retail",
            manual: true,
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            weblink: true,
            automatic: true,
            messungen: true,
            plan: true,
            sonstige: true,
          },
          {
            key: "305.1.4",
            title: "Bildung",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
          {
            key: "305.1.5",
            title: "Gesundheit",
            help: true,
            foto: true,
            fotoorgoogle: false,
            fotoorplan: false,
            fotoorgoogleorplan: false,
            manual: true,
            automatic: true,
            messungen: true,
            plan: true,
            weblink: true,
            sonstige: true,
          },
        ],
      },
      {
        code: "U8",
        key: "306.1",
        title: "306.1 Umgebungsgestaltung / Biodiversität",
        help: true,
        manual: true,
        automatic: true,
        foto: false,
        fotoorgoogle: true,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        weblink: true,
        messungen: true,
        plan: true,
        sonstige: true,
      },
      {
        code: "U9",
        key: "306.2",
        title: "306.2 Versickerung und Retention",
        help: true,
        manual: true,
        automatic: true,
        foto: true,
        fotoorgoogle: false,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
      {
        code: "U10",
        key: "307.1",
        title: "307.1 Bauliche Verdichtung",
        help: true,
        manual: true,
        automatic: true,
        foto: false,
        fotoorgoogle: true,
        fotoorplan: false,
        fotoorgoogleorplan: false,
        messungen: true,
        plan: true,
        weblink: true,
        sonstige: true,
      },
    ],
  },
];

const getWeightedAverageFromSubrating = (
  p,

  residentialRating,
  officeRating,
  commercialRating,
  buildingRating,
  healthRating
) => {
  const property = toJS(p);
  if (property.ratings.length === 0) return 0;

  var avg = 0;
  var totalArea =
    property.areaOffice +
    property.areaResidential +
    property.areaCommercial +
    property.areaBuilding +
    property.areaHealth;

  var areaOfficePercentage = property.areaOffice / totalArea;
  var areaResidentialPercentage = property.areaResidential / totalArea;
  var areaCommercialPercentage = property.areaCommercial / totalArea;
  var areaBuildingPercentage = property.areaBuilding / totalArea;
  var areaHealthPercentage = property.areaHealth / totalArea;

  avg =
    officeRating * areaOfficePercentage +
    residentialRating * areaResidentialPercentage +
    commercialRating * areaCommercialPercentage +
    buildingRating * areaBuildingPercentage +
    healthRating * areaHealthPercentage;

  if (
    (property.areaOffice > 0 && officeRating === "") ||
    (property.areaResidential > 0 && residentialRating === "") ||
    (property.areaCommercial > 0 && commercialRating === "") ||
    (property.areaBuilding > 0 && buildingRating === "") ||
    (property.areaHealth > 0 && healthRating === "")
  ) {
    avg = NaN;
  }

  return avg;
};
const getCompanyTotalArea = (c) => {
  var sum = 0;
  if (!c.portfolios) return 0;
  c.portfolios.forEach((pf) => {
    if (pf.properties.length > 0) {
      pf.properties.forEach((pp) => {
        sum += getPropertyTotalArea(pp);
      });
    }
  });

  return sum;
};

const getPortfolioTotalArea = (portfolio) => {
  var sum = 0;
  if (portfolio.properties.length > 0) {
    portfolio.properties.forEach((pp) => {
      sum += getPropertyTotalArea(pp);
    });
  }

  return sum;
};

const getPropertyTotalArea = (property) => {
  return (
    parseFloat(property.areaOffice) +
    parseFloat(property.areaResidential) +
    parseFloat(property.areaCommercial) +
    parseFloat(property.areaBuilding) +
    parseFloat(property.areaHealth)
  );
};
const getPropertyRating = (p) => {
  if (!p || p === undefined) return 0;
  const property = p; //toJS(p);
  if (property.ratings.length === 0) return 0;

  var ratingCount = 0;
  var sumRating = 0;
  var totalArea =
    parseFloat(property.areaOffice) +
    parseFloat(property.areaResidential) +
    parseFloat(property.areaCommercial) +
    parseFloat(property.areaBuilding) +
    parseFloat(property.areaHealth);
  var areaOfficePercentage = parseFloat(property.areaOffice) / totalArea;
  var areaResidentialPercentage =
    parseFloat(property.areaResidential) / totalArea;
  var areaCommercialPercentage =
    parseFloat(property.areaCommercial) / totalArea;
  var areaBuildingPercentage = parseFloat(property.areaBuilding) / totalArea;
  var areaHealthPercentage = parseFloat(property.areaHealth) / totalArea;

  //try {

  const _constainsNull = property.ratings
    .map((_pR) => {
      const _key = _pR.key.split(".");

      if (_key.length === 3) {
        if (parseInt(_key[2]) === 1 && property.areaResidential === 0) {
          return 0;
        } else if (parseInt(_key[2]) === 2 && property.areaOffice === 0) {
          return 0;
        } else if (parseInt(_key[2]) === 3 && property.areaCommercial === 0) {
          return 0;
        } else if (parseInt(_key[2]) === 4 && property.areaBuilding === 0) {
          return 0;
        } else if (parseInt(_key[2]) === 5 && property.areaHealth === 0) {
          return 0;
        } else {
          return parseFloat(_pR.value);
        }
      } else {
        return parseFloat(_pR.value);
      }
    })
    .includes(NaN);

  let _missingFields = false;

  const _iAR = property.ratings.findIndex((_pr) => _pr.key === "103.1.1");
  const _iAO = property.ratings.findIndex((_pr) => _pr.key === "103.1.2");
  const _iAC = property.ratings.findIndex((_pr) => _pr.key === "103.1.3");
  const _iAB = property.ratings.findIndex((_pr) => _pr.key === "103.1.4");
  const _iAH = property.ratings.findIndex((_pr) => _pr.key === "103.1.5");

  if (property.areaResidential > 0 && _iAR === -1) {
    _missingFields = true;
  }

  if (property.areaOffice > 0 && _iAO === -1) {
    _missingFields = true;
  }

  if (property.areaCommercial > 0 && _iAC === -1) {
    _missingFields = true;
  }
  if (property.areaBuilding > 0 && _iAB === -1) {
    _missingFields = true;
  }
  if (property.areaHealth > 0 && _iAH === -1) {
    _missingFields = true;
  }

  if (!_constainsNull && !_missingFields) {
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        ratingCount++;
        // var value = 0;
        if (c.children) {
          //compute weighted vg from subratings first

          // fx= P.areaOffice/P.percentageOffice + P.areaResidential/P.percentageResidential+P.areaCommercial/P/percentageCommercial

          var sumSubrating = 0;
          //
          c.children.forEach((s) => {
            var subrate = property.ratings.find((r) => r.key == s.key);
            if (subrate) {
              //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...
              var factor = [
                areaResidentialPercentage,
                areaOfficePercentage,
                areaCommercialPercentage,
                areaBuildingPercentage,
                areaHealthPercentage,
              ][parseInt(subrate.key.split(".")[2]) - 1];

              if (!subrate.value || subrate.value === "") {
                sumSubrating += parseFloat(0) * factor;
              } else {
                sumSubrating += parseFloat(subrate.value) * factor;
              }
            }
          });
          // sumRating += parseFloat(sumSubrating / c.children.length);
          sumRating += sumSubrating;
        } else {
          var ar = property.ratings.find((r) => r.key == c.key);
          if (ar) {
            sumRating += parseFloat(ar.value);
          }
        }
      });
    });
    //sumRating = parseFloat((sumRating / ratingCount).toFixed(2));
    sumRating = sumRating / ratingCount;
    /*  } catch (err) {
      console.log(
        "ERROR: While Calculating property rating." + JSON.stringify(err)
      );
    } */
    return sumRating;
  } else {
    return NaN;
  }
};

const getPortfolioRating = (pf) => {
  var PortfolioTotalArea = getPortfolioTotalArea(pf);

  var sumRating = 0.0;
  var propertyCount = 0.0;

  if (pf.properties.length > 0) {
    pf.properties.forEach((pp) => {
      propertyCount += 1;
      var rating = getPropertyRating(pp);
      var weight = getPropertyTotalArea(pp) / PortfolioTotalArea;
      var rw = rating * weight;
      sumRating += rw;
    });
  }

  var finalRating = sumRating;
  if (isNaN(finalRating)) finalRating = 0.0;
  return finalRating;
};

const getCompanyRating = (c) => {
  var companyTotalArea = getCompanyTotalArea(c);

  var sumRating = 0.0;
  var propertyCount = 0.0;
  if (!c.portfolios) return 0;
  c.portfolios.forEach((pf) => {
    if (pf.properties.length > 0) {
      pf.properties.forEach((pp) => {
        propertyCount += 1;
        var rating = getPropertyRating(pp);
        var weight = getPropertyTotalArea(pp) / companyTotalArea;
        var rw = rating * weight;
        sumRating += rw;
      });
    }
  });

  var finalRating = sumRating;
  if (isNaN(finalRating)) finalRating = 0.0;
  return finalRating;
};

const getMissingRatingKeys = (ratings) => {
  console.log(
    "🚀 ~ file: RatingCriteria.js ~ line 894 ~ getMissingRatingKeys ~ ratings",
    ratings.toJSON()
  );
  var missings = [];
  criteria.forEach((group) => {
    group.children.forEach((rating) => {
      if (rating.children) {
        rating.children.forEach((subrating) => {
          if (!ratings.find((r) => parseInt(r.key) == subrating.key)) {
            missings.push(`${rating.code} : ${subrating.title}`);
          }
        });
      } else {
        if (!ratings.find((r) => parseInt(r.key) == rating.key)) {
          missings.push(`${rating.code} : ${rating.title}`);
        }
      }
    });
  });

  return missings;
};
export {
  criteria,
  getPropertyRating,
  getPortfolioRating,
  getPortfolioTotalArea,
  getCompanyRating,
  getWeightedAverageFromSubrating,
  getMissingRatingKeys,
};
