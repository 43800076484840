import { types } from "mobx-state-tree";

const PropertyAuditLogModel = types
  .model("PropertyModel", {
    id: types.optional(types.number, 0),
    auditDate: types.optional(types.string, ""),
    comment: types.optional(types.string, ""),

    // an audit log is part of a Property
    propertyId: types.optional(types.number, 0),

   
  })
  ;

export default PropertyAuditLogModel;
