import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Select,
  Statistic,
  Empty,
  TreeSelect,
  Tooltip,
  Affix,
  Spin,
  Modal,
  Result,
  Popconfirm,
  Popover,
  Avatar,
} from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ApartmentOutlined,
  LoadingOutlined,
  UndoOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

import { Chart, Line, Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { toGenerator } from "mobx-state-tree";
//translation
import { useTranslation } from "react-i18next";

import { DashboardFilterBox } from "../../Common/Charts";

import TwoSetComparisonBarChart from "../../Common/Charts/TwoSetComparisonBarChart";
import TwoSetComparisonPieChart from "../../Common/Charts/TwoSetComparisonPieChart";

const COLORS_SERIES1 = ["#4f81bd", "#ffb281"];
const COLORS_SERIES2 = ["#4f81bd", "#FF6492"];
const COLOR1 = "#7A77FF";
const COLORS_SERIES_SETA = [
  "#4f81bd",
  "#6597cb",
  "#7dadd8",
  "#98c3e4",
  "#b4d9f1",
  "#d2efff",
];
const COLORS_SERIES_SETB = [
  "#ff6492",
  "#ff7fa7",
  "#ff97ba",
  "#ffafcc",
  "#ffc5dc",
  "#ffdbea",
];
const COLORS_SERIES_BENCHMARK = [
  "#ff6026",
  "#ff7f42",
  "#ff9a60",
  "#ffb281",
  "#ffcaa4",
  "#ffe0c9",
];
const COLORS_SERIES_SETA1 = [
  "#50A6C2",
  "#ADD8E6",
  "#1874CD",
  "#009ACD",
  "#00B7FF",
  "#6CA6CD",
  "#BFEFFF",
];
const COLORS_SERIES_RGB = ["#FF6492", "#86C67C", "#1874CD"];
const xbarRenderUsageType = ({ resultSet }) => {
  const data = {
    labels: resultSet.categories().map((c) => c.category),
    datasets: resultSet.series().map((s, index) => {
      var labels = {
        "IndexVsSetA.rating_setA": "Set A",
        "IndexVsSetA.rating_benchmark": "Benchmark",
        "SetAVsSetB.rating_setA": "Set A",
        "SetAVsSetB.rating_setB": "Set B",
      };
      var CompareToIndex = s.key.indexOf("IndexVsSetA") > -1;
      return {
        label: labels[s.key],
        data: s.series.map((r) => {
          return r.value ? parseFloat(r.value.toFixed(2)) : 0;
        }),
        backgroundColor: CompareToIndex
          ? COLORS_SERIES1[index]
          : COLORS_SERIES2[index],
        fill: false,
      };
    }),
  };
  const options = {
    scales: { xAxes: [{ stacked: false }] },
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 3,
            stepSize: 1,
          },
        },
      ],
    },
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        color: "#000000",
        anchor: "end",
        align: "top",
        offset: 4,
      },
    },
  };
  return <Bar data={data} options={options} />;
};

const BarRenderUsageType = ({ resultSet, CompareToBenchMarkB, hasData, t }) => {
  if (hasData === false) {
    var emptyDesc =
      t("admin_dashboard.select_portfolios_in_set_a") +
      (CompareToBenchMarkB === true ? "" : t("admin_dashboard.and_set_b")) +
      ".";
    return <Empty description={emptyDesc}></Empty>;
  }
  /*   const categories = resultSet
    .filter((value, index, self) => {
      return self.indexOf(x=>x.category_id==value.category_id) === index;
    })
    .sort((a, b) => a.category_id > b.category_id).map(c=>c.category); */

  const categories = resultSet
    .map((r) => {
      return { category_id: r.category_id, category: r.category };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(
          (t) => t.category_id === v.category_id && t.category === v.category
        ) === i
    )
    .sort((a, b) =>
      a.category_id > b.category_id ? 1 : b.category_id > a.category_id ? -1 : 0
    )
    .map((c) => c.category);

  const datasetnames = resultSet
    .map((c) => c.dataset)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  // var CompareToBenchMarkB = true;
  const datasets = datasetnames.map((d, index) => {
    return {
      label: d,
      data: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s) => {
          return s.value ? parseFloat(s.value).toFixed(2) : 0;
        }),
      backgroundColor: CompareToBenchMarkB
        ? COLORS_SERIES1[index]
        : COLORS_SERIES2[index],
      fill: true,
    };
  });

  const data = {
    labels: categories /* resultSet.map((c) => c.category) */,
    datasets: datasets /* resultSet.map((s, index) => {
      var CompareToBenchMarkB = true;
      return {
        label: s.dataset,
        data: s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0,
        backgroundColor: CompareToBenchMarkB
          ? COLORS_SERIES1[index % 2]
          : COLORS_SERIES2[index % 2],
        fill: false,
      };
    }) */,
  };
  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          // data for manipulation
          return data.datasets[tooltipItem.datasetIndex].data[
            tooltipItem.index
          ];
        },
      },
    },
    scales: { xAxes: [{ stacked: false }] },
    maintainAspectRatio: false,
    legend: { display: true },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 3,
            stepSize: 1,
          },
        },
      ],
    },
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        display: "auto",
        color: "#000000",
        anchor: "end",
        align: "top",
        offset: 4,
        formatter: function (value, context) {
          /* return context.dataIndex + ': ' + Math.round(value*100) + '%'; */
          return parseFloat(value).toFixed(2);
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
};

const PieRenderUsageType = ({
  resultSet,
  CompareToBenchMarkB,
  hasData,
  pieChartUsageTypeSource,
  t,
}) => {
  if (hasData === false) {
    var emptyDesc =
      t("admin_dashboard.select_portfolios_in_set_a") +
      (CompareToBenchMarkB === true ? "" : t("admin_dashboard.and_set_b")) +
      ".";
    return <Empty description={emptyDesc}></Empty>;
  }
  /*   const categories = resultSet
    .filter((value, index, self) => {
      return self.indexOf(x=>x.category_id==value.category_id) === index;
    })
    .sort((a, b) => a.category_id > b.category_id).map(c=>c.category); */

  const categories = resultSet
    .map((r) => {
      return { category_id: r.category_id, category: r.category };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(
          (t) => t.category_id === v.category_id && t.category === v.category
        ) === i
    )
    .sort((a, b) =>
      a.category_id > b.category_id ? 1 : b.category_id > a.category_id ? -1 : 0
    )
    .map((c) => c.category);

  const datasetnames = resultSet
    .map((c) => c.dataset)
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    .filter(
      (name) =>
        name ==
        (pieChartUsageTypeSource === "1"
          ? "Set A"
          : CompareToBenchMarkB
          ? "Benchmark"
          : "Set B")
    );

  // var CompareToBenchMarkB = true;

  const datasets = datasetnames.map((d, index) => {
    return {
      label: d,
      data: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s) => {
          return s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0;
        }),
      backgroundColor: resultSet
        .sort((a, b) =>
          a.category_id > b.category_id
            ? 1
            : b.category_id > a.category_id
            ? -1
            : 0
        )
        .filter((r) => r.dataset === d)
        .map((s, si) => {
          return pieChartUsageTypeSource === "1"
            ? COLORS_SERIES_SETA[si]
            : CompareToBenchMarkB
            ? COLORS_SERIES_BENCHMARK[si]
            : COLORS_SERIES_SETB[si];
        }),

      fill: true,
    };
  });

  const data = {
    labels: categories /* resultSet.map((c) => c.category) */,
    datasets: datasets /* resultSet.map((s, index) => {
      var CompareToBenchMarkB = true;
      return {
        label: s.dataset,
        data: s.value ? parseFloat(parseFloat(s.value).toFixed(2)) : 0,
        backgroundColor: CompareToBenchMarkB
          ? COLORS_SERIES1[index % 2]
          : COLORS_SERIES2[index % 2],
        fill: false,
      };
    }) */,
  };
  const options = {
    animation: { animateScale: true },
    maintainAspectRatio: false,

    legend: { display: true, position: "left", boxWidth: 3, align: "start" },
    /* responsive:false,
    height: 250, */
    layout: {
      padding: {
        left: 0,
        right: 50,
        top: 20,
        bottom: 20,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index] || "";

          if (label) {
            label += ": ";
          }
          label += (
            Math.round(data.datasets[0].data[tooltipItem.index] * 100) / 100
          ).toLocaleString("de-ch", { minimumFractionDigits: 2 });

          let sum = 0;
          let dataArr = data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });

          let value = data.datasets[0].data[tooltipItem.index];
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          var vv =
            isNaN((value * 100) / sum) || value === 0
              ? ""
              : "  (" + percentage + ")";
          label += vv;
          return label;
        },
      },
    },
    plugins: {
      datalabels: {
        display: "auto",
        color: "#000000",
        anchor: "end",
        align: "end",
        offset: 0,
        clamp: true,
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + "%";

          var vv = isNaN((value * 100) / sum) || value === 0 ? "" : percentage;
          // : value + ", " + percentage;

          return /* (ctx.dataIndex%2==0?" \n":"")+ */ vv;
        },
        color: "#000000",
      },
    },
  };
  return <Pie data={data} options={options} />;
};

const barOptions = {
  scales: { xAxes: [{ stacked: false }] },
  maintainAspectRatio: false,
  legend: { display: false },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 3,
          stepSize: 1,
        },
      },
    ],
  },
  plugins: {
    // Change options for ALL labels of THIS CHART
    datalabels: {
      color: "#000000",
      anchor: "end",
      align: "top",
      offset: 4,
    },
  },
};

const { SHOW_CHILD } = TreeSelect;
const { Option } = Select;

const { Title } = Typography;

const RatingBox = (props) => {
  const { t } = useTranslation("common");
  const { BenchMarkData } = props;

  var bb = [...BenchMarkData].reverse();
  if (bb.length > 1) bb.pop();
  return (
    <>
      <div className={"ant-table-wrapper"} style={{ width: "400px" }}>
        <div className={"ant-table ant-table-bordered ant-table-small"}>
          <div className={"ant-table-container"}>
            <div className={"ant-table-content"}>
              <table style={{ tableLayout: "auto" }}>
                <thead className={"ant-table-thead"}>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.period")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {" "}
                    {t("admin_dashboard.rating")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    {t("admin_dashboard.properties")}
                  </th>
                  <th
                    className={"ant-table-cell"}
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      borderBottom: "solid 1px rgb(220,220,220)",
                      borderRight: "solid 1px rgb(220,220,220)",
                    }}
                  >
                    m<sup>2</sup>
                  </th>
                </thead>
                <tbody className={"ant-table-tbody"}>
                  {bb.map((b) => {
                    return (
                      <>
                        <tr className={"ant-table-row ant-table-row-level-0"}>
                          <td align={"center"}>
                            {b.category2 + "." + b.category}
                          </td>
                          <td align={"center"}>
                            {parseFloat(b.rating).toFixed(2)}
                          </td>
                          <td align={"center"}>{b.property_count}</td>
                          <td align={"center"}>
                            {parseFloat(b.totalArea).toLocaleString("de-ch", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardFilterBoxEx = (props) => {
  const {
    t,
    ClientOptions,
    setName,
    treeSelectSetPlaceHolder,
    setColor,
    Color,
    selectedKeys,
    setSelectedKeys,
    selectionMode,
    setSelectionMode,
  } = props;

  const [PortfolioSetRating, setPortfolioSetRating] = useState(0);

  const [PortfolioSetPropertyCount, setPortfolioSetPropertyCount] = useState(0);

  const [PortfolioSetTotalArea, setPortfolioSetTotalArea] = useState(0.0);

  const [SelectedNames, setSelectedNames] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState(selectedKeys);

  const [InternalSelectionMode, setInternalSelectionMode] =
    useState(selectionMode);

  const [benchMarkData, setBenchMarkData] = useState([
    {
      dataset: "Benchmark",
      category: 0,
      category2: 0,
      rating: "0",
      totalArea: 0,
    },
  ]);

  const [activeBenchmarkDataIndex, setActiveBenchmarkDataIndex] = useState(0);
  const PortfolioTreeSelectOnChange = (value) => {
    console.log("onChange ", value);
    setSelectedRowKeys(value);
  };

  const tPropsB = {
    treeData: ClientOptions,
    value: selectedRowKeys,
    onChange: PortfolioTreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,

    listHeight: 700,
    placeholder: t("admin_dashboard.please_select_set_b") /*setPlaceHolder*/,
    style: {
      width: "350px",
    },
  };

  const handleComparisonTypeChange = (value) => {
    setInternalSelectionMode(value);

    setActiveBenchmarkDataIndex(0);
  };
  const resetFilters = () => {
    //setCompareToBenchMarkB(true);

    setSelectedRowKeys([] /* allRowKeys */);
  };
  //RatingByThreeYears
  const requestThreeYearRatings = async () => {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    if (InternalSelectionMode === "benchmark") {
      const {
        data: resultBenchmark,
        status: responseStatusBenchmark,
        statusTextBenchmark,
      } = await axios
        .post(`/api/dashboard/RatingByThreeYearsBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      //Benchmark Ratings
      var benchmarkInfo = resultBenchmark
        .filter((d) => d.dataset === "Benchmark" && d.rating)
        .sort(
          (a, b) =>
            parseFloat(a.category) - parseFloat(b.category) ||
            parseFloat(a.category2) - parseFloat(b.category2)
        );

      setBenchMarkData(benchmarkInfo);
      setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);
    } else if (InternalSelectionMode === "set" && selectedRowKeys.length > 0) {
      const {
        data: resultA,
        status: responseStatusA,
        statusTextA,
      } = await axios
        .post(`/api/dashboard/RatingByThreeYearsSetWithName`, {
          portfolioIds: selectedRowKeys.map((v) => {
            return parseInt(v.split("-")[1]);
          }) || [0],
          setName: setName,
        })

        .then((response) => response)
        .catch((error) => error.response);

      var item = resultA.filter(
        (s) =>
          s.dataset === setName && s.category === year && s.category2 === month
      );

      //SetA
      if (item) {
        /*  setPortfolioSetRating(parseFloat(item.rating ? item.rating : 0).toFixed(2));
        setPortfolioSetPropertyCount(item.property_count);
        setPortfolioSetTotalArea(item.totalArea); */

        setBenchMarkData(item);
        setActiveBenchmarkDataIndex(item.length - 1); /*  */
      }
    }
  };

  useEffect(() => {
    var selections = [];
    ClientOptions.forEach((opt) => {
      opt.children.forEach((c) => {
        selections.push(c.value);
      });
    });
    setSelectedRowKeys(selections);
    return () => {
      setSelectedRowKeys([]);
    };
  }, [ClientOptions]);

  useEffect(() => {
    //setSelectedNames([])
    var list = [];
    selectedRowKeys.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNames(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    setSelectedKeys(selectedRowKeys);
    return () => {
      setSelectedNames("");
    };
  }, [selectedRowKeys]);

  useEffect(() => {
    setSelectionMode(InternalSelectionMode);
    return () => {};
  }, [InternalSelectionMode]);

  useEffect(() => {
    requestThreeYearRatings();
    return () => {};
  }, [InternalSelectionMode, selectedKeys]);
  return (
    <Card className={"ant-card-shadow ant-card-statistic"}>
      <Row gutter={[0, 25]}>
        <Col flex="auto" style={{ textAlign: "center" }}>
          <Space>
            <Select
              defaultValue="benchmark"
              style={{
                width: InternalSelectionMode !== "set" ? 528 : 170,
                textAlign: "left",
              }}
              onChange={handleComparisonTypeChange}
              value={InternalSelectionMode}
            >
              <Option value="benchmark" title={t("admin_dashboard.benchmark")}>
                {t("admin_dashboard.benchmark")}
              </Option>
              <Option value="set">{setName}</Option>
            </Select>
            {InternalSelectionMode !== "benchmark" && (
              <>
                <Tooltip title={SelectedNames} placement={"left"} color={Color}>
                  <TreeSelect
                    className={"dashbboard-treeselect"}
                    {...tPropsB}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip title={t("admin_dashboard.reset_filters")}>
              <Button
                shape="circle"
                icon={<UndoOutlined />}
                onClick={resetFilters}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row>
        {InternalSelectionMode === "benchmark" && (
          <>
            <Col
              span={6}
              key={
                benchMarkData[activeBenchmarkDataIndex].dataset +
                "_" +
                benchMarkData[activeBenchmarkDataIndex].category +
                "_" +
                benchMarkData[activeBenchmarkDataIndex].category2
              }
            >
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333" }}>
                      {t("admin_dashboard.rating")}
                    </strong>
                  </>
                }
                value={parseFloat(
                  benchMarkData[activeBenchmarkDataIndex].rating
                ).toFixed(2)}
                precision={2}
                valueStyle={{ color: "#ff6026" }}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333" }}>
                      {t("admin_dashboard.properties")}
                    </strong>
                  </>
                }
                value={benchMarkData[activeBenchmarkDataIndex].property_count}
                precision={0}
                groupSeparator={"'"}
                valueStyle={{ color: "#ff6026" }}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={
                  <>
                    <strong style={{ color: "#333", marginRight: "10px" }}>
                      m<sup>2</sup>
                    </strong>
                  </>
                }
                value={benchMarkData[activeBenchmarkDataIndex].totalArea}
                precision={0}
                groupSeparator={"'"}
                valueStyle={{ color: "#ff6026" }}
              />
            </Col>
            <Col span={6}>
              {benchMarkData.length > 1 && (
                <Statistic
                  title={
                    <>
                      <div>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.previous_month")}
                        </strong>
                      </div>
                      <Popover
                        placement="right"
                        title={false}
                        content={
                          <RatingBox BenchMarkData={benchMarkData}></RatingBox>
                        }
                        trigger="click"
                      >
                        <Avatar
                          size={40}
                          icon={<CalendarOutlined />}
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#ff6026",
                            cursor: "pointer",
                          }}
                        />
                      </Popover>
                    </>
                  }
                  value={""}
                  precision={0}
                  valueStyle={{ color: "#ff6026", display: "none" }}
                />
              )}
            </Col>
          </>
        )}
        {InternalSelectionMode === "set" && (
          <>
            {[benchMarkData[benchMarkData.length - 1]].map((benchmark) => (
              <>
                <Col
                  span={6}
                  key={
                    benchmark.dataset +
                    "_" +
                    benchmark.category +
                    "_" +
                    benchmark.category2
                  }
                >
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.rating")}

                          <span className={"rating-prefix"}>
                            {benchmark.category2 + "." + benchmark.category}
                          </span>
                        </strong>
                      </>
                    }
                    value={parseFloat(benchmark.rating).toFixed(2)}
                    precision={2}
                    valueStyle={{ color: Color }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333" }}>
                          {t("admin_dashboard.properties")}
                        </strong>
                      </>
                    }
                    value={benchmark.property_count}
                    precision={0}
                    valueStyle={{ color: Color }}
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title={
                      <>
                        <strong style={{ color: "#333", marginRight: "10px" }}>
                          m<sup>2</sup>
                        </strong>
                      </>
                    }
                    value={benchmark.totalArea}
                    precision={0}
                    groupSeparator={"'"}
                    valueStyle={{ color: Color }}
                  />
                </Col>
              </>
            ))}
          </>
        )}
      </Row>
    </Card>
  );
};
function Dashboard(props) {
  const isAuthenticated = props.store.login.isLoggedIn;
  const { store } = props;
  const { t } = useTranslation("common");

  const data = [
    { year: "1991", value: 3 },
    { year: "1992", value: 4 },
    { year: "1993", value: 3.5 },
    { year: "1994", value: 5 },
    { year: "1995", value: 4.9 },
    { year: "1996", value: 6 },
    { year: "1997", value: 7 },
    { year: "1998", value: 9 },
    { year: "1999", value: 13 },
  ];

  const config = {
    data,
    title: {
      visible: true,
      text: t("admin_dashboard.line_chart_with_data_point"),
    },
    xField: t("admin_dashboard.year"),
    yField: t("admin_dashboard.value"),
    width: 800,
    height: 400,
  };

  const [usageBarChartData, setUsageBarChartData] = useState(false);
  const [hasUsageBarChartData, setHasUsageBarChartData] = useState(false);
  const [regionBarChartData, setRegionBarChartData] = useState(false);
  const [hasRegionBarChartData, setHasRegionBarChartData] = useState(false);
  const [regionPieChartData, setRegionPieChartData] = useState(false);
  const [hasRegionPieChartData, setHasRegionPieChartData] = useState(false);

  const [areaByUsageData, setAreaByUsageData] = useState(false);
  const [hasAreaByUsageData, setHasAreaByUsageData] = useState(false);

  const [criteriaGroupBarChartData, setCriteriaGroupBarChartData] =
    useState(false);
  const [hasCriteriaGroupBarChartData, setHasCriteriaGroupBarChartData] =
    useState(false);

  const [ratingSetA, setRatingSetA] = useState(0);
  const [propertyCountSetA, setPropertyCountSetA] = useState(0);
  const [totalAreaSetA, setTotalAreaSetA] = useState(0);
  const [propertyCountBenchmarkOrSetA, setPropertyCountBenchmarkOrSetA] =
    useState(0);

  const [benchMarkData, setBenchMarkData] = useState([
    {
      dataset: "Benchmark",
      category: 0,
      category2: 0,
      rating: "0",
      totalArea: 0,
    },
  ]);

  const [activeBenchmarkDataIndex, setActiveBenchmarkDataIndex] = useState(0);

  const [ClientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState([]);
  useEffect(() => {
    Chart.pluginService.register(ChartDataLabels);
    Chart.defaults.global.defaultFontFamily = "Bahnschrift Regular";
    Chart.defaults.global.tooltips.cornerRadius = 0;
    Chart.defaults.global.tooltips.backgroundColor = "#ffffff";
    Chart.defaults.global.tooltips.titleFontColor = "#4f81bd";
    Chart.defaults.global.tooltips.bodyFontColor = "#000000";
    Chart.defaults.global.tooltips.borderColor = "#aaaaaa";
    Chart.defaults.global.tooltips.borderWidth = 1;
    //Chart.defaults.bar.labels.fontFamily = "Bahnschrift Regular";
    //Chart.defaults.global.legend.labels.fontFamily = "Bahnschrift Regular";
    Chart.defaults.global.plugins.datalabels.font.family =
      "Bahnschrift Regular";
    Chart.plugins.register({
      id: "paddingBelowLegends",
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height = this.height + 40;
        };
      },
    });
    return () => {
      Chart.pluginService.unregister(ChartDataLabels);
    };
  }, []);

  const [dashboardPortfolios, setDashboardPortfolios] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (store.company.type == 0) {
        await store.getClients();
        await store.getPortfolios();

        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/portfolios`, {
            t: store.company.type,
            user_id: store.login.id,
          })
          .then((response) => response)
          .catch((error) => error.response);

        if (result.success) {
          setDashboardPortfolios(result.records);
        }
      }
    }
    fetchData();
    return () => {
      setDashboardPortfolios([]);
    };
  }, []);

  useEffect(() => {
    (async () => {
      var co = [];

      store.clients.forEach((c) => {
        // add the client only if it has porfolios..
        if (
          store.portfolios
            .filter((pf) => {
              return (
                dashboardPortfolios.findIndex(
                  (rr) => rr.portfolio_id === pf.id
                ) > -1
              );
            })
            .find((pf) => pf.companyId === c.id)
        ) {
          co.push({ ...c, key: c.id });
        }

        setClients(co);
      });
    })();
  }, [dashboardPortfolios]);

  const createRandomChildren = (parentkey) => {
    var list = [];
    /*  var childCount = Math.round(Math.random() * 10) + 1;

    for (var index = 1; index <= childCount; index++) {
      list.push({
        title: "PF" + parentkey + "-" + index,
        value: parentkey + "-" + index,
        key: parentkey + "-" + index,
      });
    } */

    var xlist = store.portfolios.filter(
      (pf) =>
        pf.companyId === parentkey &&
        dashboardPortfolios.findIndex((rr) => rr.portfolio_id === pf.id) > -1
    );
    if (xlist) {
      list = xlist.map((pf) => {
        return {
          title: pf.name,
          value: parentkey + "-" + pf.id,
          key: parentkey + "-" + pf.id,
        };
      });
    }
    return list;
  };

  useEffect(() => {
    var children = [];
    var selections = [];
    clients.forEach((client) => {
      children.push({
        title: client.name,
        value: client.id,
        key: client.id,
        children: createRandomChildren(client.id),
      });

      children[children.length - 1].children.forEach((c) => {
        selections.push(c.value);
      });
    });

    setClientOptions(children);

    setSelectedRowKeysA(selections);
    setSelectedRowKeysB(selections);
    setAllRowKeys(selections);

    return () => {
      setClientOptions([]);
    };
  }, [clients]);

  const [CompareToBenchMarkA, setCompareToBenchMarkA] = useState(true);

  const [CompareToBenchMarkB, setCompareToBenchMarkB] = useState(true);

  const [selectedRowKeysA, setSelectedRowKeysA] = useState([]);

  const [SelectedNamesA, setSelectedNamesA] = useState("");
  const [SelectedNamesB, setSelectedNamesB] = useState("");

  const [ColorA, setColorA] = useState("#4f81bd");
  const [ColorB, setColorB] = useState("#ff6026");

  const [selectedRowKeysB, setSelectedRowKeysB] = useState([]);

  const [allRowKeys, setAllRowKeys] = useState([]);

  const [selectionModeA, setSelectionModeA] = useState("benchmark");
  const [selectionModeB, setSelectionModeB] = useState("benchmark");

  //RatingByThreeYears
  const requestThreeYearRatings = async () => {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();

    if (selectionModeB === "benchmark") {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetWithName`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set A",
          })

          .then((response) => response)
          .catch((error) => error.response);

        var item = result.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //SetA
        if (item) {
          setRatingSetA(parseFloat(item.rating ? item.rating : 0).toFixed(2));
          setPropertyCountSetA(item.property_count);
          setTotalAreaSetA(item.totalArea);
        }

        const {
          data: resultBenchmark,
          status: responseStatusBenchmark,
          statusTextBenchmark,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsBenchmark`, {})

          .then((response) => response)
          .catch((error) => error.response);

        //Benchmark Ratings
        var benchmarkInfo = resultBenchmark
          .filter((d) => d.dataset === "Benchmark" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );

        /*  var currentBenchMark=benchmarkInfo[benchmarkInfo.length-1]
          currentBenchMark.children=[];
          console.log(currentBenchMark)
          if(benchmarkInfo.length>1){
            currentBenchMark.children=[];
            benchmarkInfo.forEach(bf=>{
             if(bf.dataset!=currentBenchMark.dataset&&bf.category!=currentBenchMark.category &&bf.category2!=currentBenchMark.category2 )
             {
               currentBenchMark.children.push(bf)
             }
            })
          } */
        setBenchMarkData(benchmarkInfo);
        setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);
        setPropertyCountBenchmarkOrSetA(
          benchmarkInfo.length > 0
            ? benchmarkInfo[benchmarkInfo.length - 1].property_count
            : 0
        );
      } else {
        /*  setHasUsageBarChartData(false); */
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: resultA,
          status: responseStatusA,
          statusTextA,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetWithName`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            setName: "Set A",
          })

          .then((response) => response)
          .catch((error) => error.response);

        var item = resultA.find(
          (s) =>
            s.dataset === "Set A" &&
            s.category === year &&
            s.category2 === month
        );

        //SetA
        if (item) {
          setRatingSetA(parseFloat(item.rating ? item.rating : 0).toFixed(2));
          setPropertyCountSetA(item.property_count);
          setTotalAreaSetA(item.totalArea);
        }

        const {
          data: resultB,
          status: responseStatusB,
          statusTextB,
        } = await axios
          .post(`/api/dashboard/RatingByThreeYearsSetWithName`, {
            portfolioIds: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            setName: "Set B",
          })

          .then((response) => response)
          .catch((error) => error.response);

        //Benchmark Ratings
        var benchmarkInfo = resultB
          .filter((d) => d.dataset === "Set B" && d.rating)
          .sort(
            (a, b) =>
              parseFloat(a.category) - parseFloat(b.category) ||
              parseFloat(a.category2) - parseFloat(b.category2)
          );
        setBenchMarkData(benchmarkInfo);
        setActiveBenchmarkDataIndex(benchmarkInfo.length - 1);

        setPropertyCountBenchmarkOrSetA(
          benchmarkInfo.length > 0
            ? benchmarkInfo[benchmarkInfo.length - 1].property_count
            : 0
        );
      } else {
        //setUsageBarChartData(false);
        setHasUsageBarChartData(false);
      }
    }
  };

  const requestUsageTypeDataEx = async () => {
    var dataset = [];
    if (selectionModeA === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/RatingByUsageBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI A" };
        }),
      ];
    } else if (selectionModeA === "set") {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetWithName`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set A",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
      } else {
        setHasUsageBarChartData(false);
      }
    }
    //todo by porfolio tyoes

    if (selectionModeB === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/RatingByUsageBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI B" };
        }),
      ];
      setUsageBarChartData(dataset);
      setHasUsageBarChartData(true);
    } else if (selectionModeB === "set") {
      if (selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetWithName`, {
            portfolioIds: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set B",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
        setUsageBarChartData(dataset);
        setHasUsageBarChartData(true);
      } else {
        setHasUsageBarChartData(false);
      }
    }

    //  setUsageBarChartData(dataset);
    // setHasUsageBarChartData(true);
  };

  const requestUsageTypeData = async () => {
    if (CompareToBenchMarkB === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setUsageBarChartData(result);
        setHasUsageBarChartData(true);
      } else {
        setHasUsageBarChartData(false);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setUsageBarChartData(result);
        setHasUsageBarChartData(true);
      } else {
        //setUsageBarChartData(false);
        setHasUsageBarChartData(false);
      }
    }
  };

  const requestRegionDataEx = async () => {
    var dataset = [];
    if (selectionModeA === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/RatingByRegionBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI A" };
        }),
      ];
    } else if (selectionModeA === "set") {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetWithName`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set A",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
      } else {
        setHasRegionBarChartData(false);
      }
    }
    //todo by porfolio tyoes

    if (selectionModeB === "benchmark") {
      const {
        data: result,
        status: responseStatus,
        statusText,
      } = await axios
        .post(`/api/dashboard/RatingByRegionBenchmark`, {})

        .then((response) => response)
        .catch((error) => error.response);

      dataset = [
        ...dataset,
        ...result.map((r) => {
          return { ...r, dataset: "SSREI B" };
        }),
      ];
      setRegionBarChartData(dataset);
      setHasRegionBarChartData(true);
    } else if (selectionModeB === "set") {
      if (selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetWithName`, {
            portfolioIds: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
            setName: "Set B",
          })

          .then((response) => response)
          .catch((error) => error.response);

        dataset = [...dataset, ...result];
        setRegionBarChartData(dataset);
        setHasRegionBarChartData(true);
      } else {
        setHasRegionBarChartData(false);
      }
    }
  };

  const requestRegionData = async () => {
    if (CompareToBenchMarkB === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setRegionBarChartData(result);
        setHasRegionBarChartData(true);
      } else {
        setHasRegionBarChartData(false);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setRegionBarChartData(result);
        setHasRegionBarChartData(true);
      } else {
        //setUsageBarChartData(false);
        setHasRegionBarChartData(false);
      }
    }
  };

  const requestRegionPieData = async () => {
    if (CompareToBenchMarkB === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setRegionPieChartData(result);
        setHasRegionPieChartData(true);
      } else {
        setHasRegionPieChartData(false);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/TotalAreaByRegionSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setRegionPieChartData(result);
        setHasRegionPieChartData(true);
      } else {
        //setUsageBarChartData(false);
        setHasRegionPieChartData(false);
      }
    }
  };

  const requestAreaByUsageData = async () => {
    if (CompareToBenchMarkB === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setAreaByUsageData(result);
        setHasAreaByUsageData(true);
      } else {
        setHasAreaByUsageData(false);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/AreaByUsageSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setAreaByUsageData(result);
        setHasAreaByUsageData(true);
      } else {
        //setUsageBarChartData(false);
        setHasAreaByUsageData(false);
      }
    }
  };

  const requestCriteriaGroupData = async () => {
    if (CompareToBenchMarkB === true) {
      if (selectedRowKeysA.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsBenchmark`, {
            portfolioIds: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }),
          })

          .then((response) => response)
          .catch((error) => error.response);

        setCriteriaGroupBarChartData(result);
        setHasCriteriaGroupBarChartData(true);
      } else {
        setHasCriteriaGroupBarChartData(false);
      }
    } else {
      if (selectedRowKeysA.length > 0 && selectedRowKeysB.length > 0) {
        const {
          data: result,
          status: responseStatus,
          statusText,
        } = await axios
          .post(`/api/dashboard/RatingByCriteriaGroupSetVsSet`, {
            portfolioIdsA: selectedRowKeysA.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
            portfolioIdsB: selectedRowKeysB.map((v) => {
              return parseInt(v.split("-")[1]);
            }) || [0],
          })

          .then((response) => response)
          .catch((error) => error.response);

        setCriteriaGroupBarChartData(result);
        setHasCriteriaGroupBarChartData(true);
      } else {
        //setUsageBarChartData(false);
        setHasCriteriaGroupBarChartData(false);
      }
    }
  };

  useEffect(() => {
    //setSelectedNamesA([])
    var list = [];
    selectedRowKeysA.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNamesA(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  key={i}
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    return () => {
      setSelectedNamesA("");
    };
  }, [selectedRowKeysA]);
  useEffect(() => {
    //setSelectedNamesB([])
    var list = [];
    selectedRowKeysB.forEach((a) => {
      var ids = a.split("-");
      var c0 = ClientOptions.find((cl) => cl.value == ids[0]);
      var c1 = c0.children.find((ch) => ch.value == a);
      if (!list[c0.title]) {
        list[c0.title] = { text: c0.title, children: [] };
      }
      list[c0.title].children.push(c1.title);
    });

    var s = "";
    var ci = 0;
    for (var c in list) {
      if (ci > 0) s += " |";
      s += c + ": ";
      list[c].children.forEach((child, cindex, clist) => {
        if (cindex > 0) s += ", ";
        s += child;
      });

      ci++;
    }
    setSelectedNamesB(
      s.split("|").map((ss) => (
        <>
          <div>
            {ss.split(":").map((p, i) => {
              return (
                <div
                  key={i}
                  style={{
                    marginLeft: i == 0 ? "0px" : "10px",
                    fontWeight: i == 0 ? "bold" : "normal",
                  }}
                >
                  {p.indexOf(",") > -1
                    ? p.split(",").map((pp) => <div>- {pp}</div>)
                    : (i == 0 ? "" : "- ") + p}
                </div>
              );
            })}
          </div>
        </>
      ))
    );
    return () => {
      setSelectedNamesB("");
    };
  }, [selectedRowKeysB]);
  useEffect(() => {
    //requestThreeYearRatings();

    requestUsageTypeDataEx();
    requestRegionDataEx();
    requestRegionPieData();
    requestCriteriaGroupData();
    requestAreaByUsageData();
    return () => {
      //setUsageBarChartData(false);
    };
  }, [selectedRowKeysA, selectedRowKeysB, selectionModeA, selectionModeB]);

  useEffect(() => {
    return () => {};
  }, [usageBarChartData]);

  const PortfolioATreeSelectOnChange = (value) => {
    console.log("onChange ", value);
    setSelectedRowKeysA(value);
  };

  const PortfolioBTreeSelectOnChange = (value) => {
    console.log("onChange ", value);
    setSelectedRowKeysB(value);
  };

  const tPropsA = {
    treeData: ClientOptions,
    value: selectedRowKeysA,
    onChange: PortfolioATreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,
    listHeight: 700,
    placeholder: t("admin_dashboard.please_select_set_a"),
    style: {
      width: "350px",
    },
  };

  const tPropsB = {
    treeData: ClientOptions,
    value: selectedRowKeysB,
    onChange: PortfolioBTreeSelectOnChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    maxTagCount: 3,

    listHeight: 700,
    placeholder: t("admin_dashboard.please_select_set_b"),
    style: {
      width: "350px",
    },
  };

  const handleComparisonTypeChange = (value) => {
    setSelectionModeB(value);
    setCompareToBenchMarkB(value === "benchmark");
    setActiveBenchmarkDataIndex(0);
  };

  const resetFilters = () => {
    setCompareToBenchMarkB(true);
    setSelectedRowKeysA(allRowKeys);
    setSelectedRowKeysB(allRowKeys);
  };
  const resetFiltersA = () => {
    setSelectedRowKeysA([] /* allRowKeys */);
  };
  const resetFiltersB = () => {
    //setCompareToBenchMarkB(true);

    setSelectedRowKeysB([] /* allRowKeys */);
  };

  const [pieChartRegionSource, setPieChartRegionSource] = useState("1");
  const [pieChartUsageTypeSource, setPieChartUsageTypeSource] = useState("1");
  const handlePieChartRegionChange = (value) => {
    setPieChartRegionSource(value);
  };

  const handlePieChartUsageTypeChange = (value) => {
    setPieChartUsageTypeSource(value);
  };

  const [queryBarChartUsageType, setQueryBarChartUsageType] = useState({
    measures: ["IndexVsSetA.rating_setA", "IndexVsSetA.rating_benchmark"],
    timeDimensions: [],
    order: {
      "IndexVsSetA.rating_setA": "desc",
    },
    dimensions: ["IndexVsSetA.usage_type"],
  });

  const [queryBarChartCriteriaGroup, setQueryBarChartCriteriaGroup] = useState({
    measures: ["IndexVsSetA.rating_setA", "IndexVsSetA.rating_benchmark"],
    timeDimensions: [],
    order: {
      "IndexVsSetA.rating_setA": "desc",
    },
    dimensions: ["IndexVsSetA.criteria_group"],
  });

  const [queryBarChartRegion, setQueryBarChartRegion] = useState({
    measures: ["Benchmark.average_rating"],
    timeDimensions: [],
    order: {
      "Benchmark.average_rating": "desc",
    },
    dimensions: ["Benchmark.region"],
    filters: [],
  });

  const [queryPieChartRegion, setQueryPieChartRegion] = useState({
    measures: ["Benchmark.average_rating"],
    timeDimensions: [],
    order: {
      "Benchmark.average_rating": "desc",
    },
    dimensions: ["Benchmark.region"],
    filters: [],
  });

  const [queryPieChartUsageType, setQueryPieChartUsageType] = useState({
    measures: ["Benchmark.average_rating"],
    timeDimensions: [],
    order: {
      "Benchmark.average_rating": "desc",
    },
    dimensions: ["Benchmark.usage_type"],
    filters: [],
  });

  useEffect(() => {
    var cubeName = CompareToBenchMarkB ? "IndexVsSetA" : "SetAVsSetB";
    var valueA = selectedRowKeysA;
    var valueB = selectedRowKeysB;
    var q1 = {
      measures: CompareToBenchMarkB
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.usage_type`]: "desc",
      },
      dimensions: [`${cubeName}.usage_type`],
      filters: [],
    };
    var q2 = {
      measures: CompareToBenchMarkB
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.region`]: "asc",
      },
      dimensions: [`${cubeName}.region`],
      filters: [],
    };
    var q3 = {
      measures: CompareToBenchMarkB
        ? [`IndexVsSetA.rating_setA`, "IndexVsSetA.rating_benchmark"]
        : [`SetAVsSetB.rating_setA`, "SetAVsSetB.rating_setB"],
      timeDimensions: [],
      order: {
        [`${cubeName}.criteria_group`]: "asc",
      },
      dimensions: [`${cubeName}.criteria_group`],
      filters: [],
    };

    var q4 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.region"],
      filters: [],
    };
    var q5 = {
      measures: ["Benchmark.average_rating"],
      timeDimensions: [],
      order: {
        "Benchmark.average_rating": "desc",
      },
      dimensions: ["Benchmark.usage_type"],
      filters: [],
    };

    if (CompareToBenchMarkB) {
      //use set A
      var pieFilters = [];
      if (valueA.length > 0) {
        pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }
      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        //use Benchmark
        q4.filters = [];
      }
      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        //use Benchmark
        q5.filters = [];
      }
    } else {
      var pieFilters = [];
      //use set A
      if (valueA.length > 0) {
        var pieFilters = [
          {
            dimension: "Benchmark.portfolio_id",
            operator: "equals",
            values: valueA.map((v) => {
              return v.split("-")[1];
            }),
          },
        ];
      }

      if (pieChartRegionSource === "1") {
        q4.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q4.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }

      if (pieChartUsageTypeSource === "1") {
        q5.filters = pieFilters;
      } else {
        if (valueB.length > 0) {
          q5.filters = [
            {
              dimension: "Benchmark.portfolio_id",
              operator: "equals",
              values: valueB.map((v) => {
                return v.split("-")[1];
              }),
            },
          ];
        }
      }
    }

    var filters = [];
    if (CompareToBenchMarkB) {
      if (valueA.length > 0) {
        filters = [
          {
            or: [
              {
                dimension: "IndexVsSetA.row_type",
                operator: "equals",
                values: ["Benchmark"],
              },
              {
                and: [
                  {
                    dimension: "IndexVsSetA.row_type",
                    operator: "equals",
                    values: ["Set A"],
                  },
                  {
                    dimension: "IndexVsSetA.portfolio_id",
                    operator: "equals",
                    values: valueA.map((v) => {
                      return v.split("-")[1];
                    }),
                  },
                ],
              },
            ],
          },
        ];
      }
    } else {
      //if (valueA.length > 0) {
      filters = [
        {
          or: [
            valueA.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["Set A"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set A"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueA.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
            valueB.length === 0
              ? {
                  dimension: "SetAVsSetB.row_type",
                  operator: "equals",
                  values: ["Set B"],
                }
              : {
                  and: [
                    {
                      dimension: "SetAVsSetB.row_type",
                      operator: "equals",
                      values: ["Set A"],
                    },
                    {
                      dimension: "SetAVsSetB.portfolio_id",
                      operator: "equals",
                      values: valueB.map((v) => {
                        return v.split("-")[1];
                      }),
                    },
                  ],
                },
          ],
        },
      ];
      // }
    }
    q1.filters = filters;
    q2.filters = filters;
    q3.filters = filters;

    setQueryBarChartUsageType(q1);
    setQueryBarChartRegion(q2);
    setQueryBarChartCriteriaGroup(q3);
    setQueryPieChartRegion(q4);
    setQueryPieChartUsageType(q5);
    return () => {
      setQueryBarChartUsageType(q1);
      setQueryBarChartRegion(q2);
      setQueryBarChartCriteriaGroup(q3);
      setQueryPieChartRegion(q4);
      setQueryPieChartUsageType(q5);
    };
  }, [
    selectedRowKeysA,
    selectedRowKeysB,
    CompareToBenchMarkB,
    pieChartRegionSource,
    pieChartUsageTypeSource,
  ]);

  const [headerAffixClassName, setHeaderAffixClassName] = useState(
    "page-title title-affix"
  );
  const [isAffixed, setIsAffixed] = useState(false);

  useLayoutEffect(() => {
    setIsAffixed(false);
    return () => {
      setIsAffixed(false);
    };
  }, []);

  const [IsAddingToIndex, setIsAddingToIndex] = useState(false);
  const [AddToIndexStatus, setAddToIndexStatus] = useState(0);

  function onConfirmAddToIndex() {
    setAddToIndexStatus(2);
    setIsAddingToIndex(true);

    store.generateCurrentMonthIndex().then((success) => {
      setAddToIndexStatus(success ? 3 : 0);

      if (success) {
        async function fetchData() {
          const {
            data: result,
            status: responseStatus,
            statusText,
          } = await axios
            .post(`/api/dashboard/portfolios`, {
              t: store.company.type,
              user_id: store.login.id,
            })
            .then((response) => response)
            .catch((error) => error.response);

          if (result.success) {
            setDashboardPortfolios(result.records);
          }
        }
        fetchData();

        requestThreeYearRatings();

        requestUsageTypeDataEx();
        requestRegionDataEx();
        requestRegionPieData();
        requestCriteriaGroupData();
        requestAreaByUsageData();
      }
    });
  }

  function onCancelAddToIndex(e) {
    console.log(e);
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  return (
    <>
      <Affix
        style={{ position: "absolute" }}
        offsetTop={0}
        onChange={(affixed) => {
          setIsAffixed(affixed);
          setHeaderAffixClassName(
            affixed
              ? "page-title title-affix title-with-bg"
              : "page-title title-affix"
          );
        }}
      ></Affix>
      <Affix offsetTop={0}>
        <Row
          className={"page-title"}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? "rgba(255,255,255,0.8)"
              : "rgba(255,255,255,0.0)",
            boxShadow: isAffixed
              ? "10px 1px 15px rgba(128,128,128,0.5)"
              : "none",
          }}
        >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Title className={isAffixed ? "page-title" : ""}>
              {t("admin_dashboard.dashboard")}
            </Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {process.env.NODE_ENV === "development" && (
              <div style={{ margin: "20px" }} align={"right"}>
                <Popconfirm
                  title={t(
                    "admin_dashboard.this_action_delete_current_month_index_entries"
                  )}
                  onConfirm={onConfirmAddToIndex}
                  onCancel={onCancelAddToIndex}
                  okText={t("admin_dashboard.yes")}
                  cancelText={t("admin_dashboard.no")}
                >
                  <Button>
                    {t("admin_dashboard.recalculate_index_for_this_month")}
                  </Button>
                </Popconfirm>
              </div>
            )}
          </Col>
        </Row>
      </Affix>
      {/* <Scrollbars
        height={"600"}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        
      > */}
      {/* Key Indicators */}
      {/*  <Affix offsetTop={90}> */}
      <Row gutter={[32, 24]}>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorA}
            setColor={setColorA}
            setName={"Portfolio Set A"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysA}
            setSelectedKeys={setSelectedRowKeysA}
            selectionMode={selectionModeA}
            setSelectionMode={setSelectionModeA}
          ></DashboardFilterBox>
        </Col>
        <Col span={12}>
          <DashboardFilterBox
            ClientOptions={ClientOptions}
            t={t}
            Color={ColorB}
            setColor={setColorB}
            setName={"Portfolio Set B"}
            treeSelectSetPlaceHolder={"Select Portfolios"}
            selectedKeys={selectedRowKeysB}
            setSelectedKeys={setSelectedRowKeysB}
            selectionMode={selectionModeB}
            setSelectionMode={setSelectionModeB}
          ></DashboardFilterBox>
        </Col>
      </Row>
      {/*  Charts */}
      {/*  </Affix> */}
      <Row gutter={[32, 24]}>
        <Col span={8} lg={8} md={24} sm={24}>
          {/* <Card>
            <Title level={3} style={{ textAlign: "center" }}>
              {t("admin_dashboard.nutzungen")}
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textDecoration: "overline",
                }}
              >
                x
              </span>
            </Title>
            <div className={"dashboard-chart-area"}>
              <BarRenderUsageType
                resultSet={usageBarChartData}
                CompareToBenchMarkB={selectionModeB==='benchmark'}
                hasData={hasUsageBarChartData}
                t={t}
              ></BarRenderUsageType>
            </div>
          </Card>  */}
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.nutzungen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByUsageBenchmark",
              set: "RatingByUsageSetWIthName",
              "pf-1": "RatingByUsagePortfolioType",
              "pf-2": "RatingByUsagePortfolioType",
              "pf-3": "RatingByUsagePortfolioType",
              "pf-4": "RatingByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>

        <Col span={16} lg={16} md={24} sm={24}>
          {/*  <Card>
            <Title level={3} style={{ textAlign: "center" }}>
              {t("admin_dashboard.regionen")}
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textDecoration: "overline",
                }}
              >
                x
              </span>
            </Title>
            <div className={"dashboard-chart-area"}>
              <BarRenderUsageType
                resultSet={regionBarChartData}
                CompareToBenchMarkB={selectionModeB === "benchmark"}
                hasData={hasRegionBarChartData}
                t={t}
              ></BarRenderUsageType>
            </div>
          </Card> */}
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.regionen")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByRegionBenchmark",
              set: "RatingByRegionSetWithName",
              "pf-1": "RatingByRegionPortfolioType",
              "pf-2": "RatingByRegionPortfolioType",
              "pf-3": "RatingByRegionPortfolioType",
              "pf-4": "RatingByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
      </Row>

      <Row gutter={[32, 24]} style={{ marginBottom: "35px" }}>
        <Col span={8} lg={8} sm={24} md={24}>
          {/* <Card>
            <Title level={3} style={{ textAlign: "center" }}>
              {t("admin_dashboard.bereich")}
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textDecoration: "overline",
                }}
              >
                x
              </span>
            </Title>

            <div className={"dashboard-chart-area"}>
              <BarRenderUsageType
                resultSet={criteriaGroupBarChartData}
                CompareToBenchMarkB={CompareToBenchMarkB}
                hasData={hasCriteriaGroupBarChartData}
                t={t}
              ></BarRenderUsageType>
            </div>
          </Card> */}
          <TwoSetComparisonBarChart
            t={t}
            title={t("admin_dashboard.bereich")}
            subtitle={""}
            apiEndPoints={{
              benchmark: "RatingByCriteriaGroupBenchmark",
              set: "RatingByCriteriaGroupSetWithName",
              "pf-1": "RatingByCriteriaGroupPortfolioType",
              "pf-2": "RatingByCriteriaGroupPortfolioType",
              "pf-3": "RatingByCriteriaGroupPortfolioType",
              "pf-4": "RatingByCriteriaGroupPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonBarChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          {/* <Card>
            <Title
              level={3}
              style={{ textAlign: "left", textTransform: "uppercase" }}
            >
              <Select
                defaultValue="1"
                bordered={false}
                style={{ float: "right", width: "150px" }}
                onChange={handlePieChartUsageTypeChange}
                value={pieChartUsageTypeSource}
                style={{ float: "right" }}
              >
                <Option value="1">{t("admin_dashboard.set_a")}</Option>
                <Option value="2">
                  {CompareToBenchMarkB
                    ? t("admin_dashboard.benchmark")
                    : t("admin_dashboard.set_b")}
                </Option>
              </Select>
              <div>
                {t("admin_dashboard.flache")}
                <span
                  style={{
                    display: "block",
                    fontSize: "18px",
                    textTransform: "lowercase",
                  }}
                >
                  m<sup>2</sup>
                </span>
              </div>
            </Title>
            <div className={"dashboard-chart-area"}>
              <PieRenderUsageType
                resultSet={areaByUsageData}
                CompareToBenchMarkB={CompareToBenchMarkB}
                hasData={hasAreaByUsageData}
                pieChartUsageTypeSource={pieChartUsageTypeSource}
                t={t}
              ></PieRenderUsageType>
            </div>
          </Card> */}
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.flache")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "AreaByUsageBenchmark",
              set: "AreaByUsageSetWithName",
              "pf-1": "AreaByUsagePortfolioType",
              "pf-2": "AreaByUsagePortfolioType",
              "pf-3": "AreaByUsagePortfolioType",
              "pf-4": "AreaByUsagePortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
        <Col span={8} lg={8} sm={24} md={24}>
          {/*  <Card>
            <Title
              level={3}
              style={{ textAlign: "left", textTransform: "uppercase" }}
            >
              <Select
                defaultValue="1"
                bordered={false}
                style={{ float: "right", width: "150px" }}
                onChange={handlePieChartRegionChange}
                value={pieChartRegionSource}
                style={{ float: "right" }}
              >
                <Option value="1">{t("admin_dashboard.set_a")}</Option>
                <Option value="2">
                  {CompareToBenchMarkB
                    ? t("admin_dashboard.benchmark")
                    : t("admin_dashboard.set_b")}
                </Option>
              </Select>
              {t("admin_dashboard.gebäude")}
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            </Title>
            <div className={"dashboard-chart-area"}>
              <PieRenderUsageType
                resultSet={regionPieChartData}
                CompareToBenchMarkB={CompareToBenchMarkB}
                hasData={hasRegionPieChartData}
                pieChartUsageTypeSource={pieChartRegionSource}
                t={t}
              ></PieRenderUsageType>
            </div>
          </Card> */}
          <TwoSetComparisonPieChart
            t={t}
            title={t("admin_dashboard.gebäude")}
            subtitle={
              <span
                style={{
                  display: "block",
                  fontSize: "18px",
                  textTransform: "lowercase",
                }}
              >
                m<sup>2</sup>
              </span>
            }
            apiEndPoints={{
              benchmark: "TotalAreaByRegionBenchmark",
              set: "TotalAreaByRegionSetWithName",
              "pf-1": "TotalAreaByRegionPortfolioType",
              "pf-2": "TotalAreaByRegionPortfolioType",
              "pf-3": "TotalAreaByRegionPortfolioType",
              "pf-4": "TotalAreaByRegionPortfolioType",
            }}
            selectionModeA={selectionModeA}
            selectionModeB={selectionModeB}
            filtersA={selectedRowKeysA}
            filtersB={selectedRowKeysB}
            setNameA={"Set A"}
            setNameB={"Set B"}
          ></TwoSetComparisonPieChart>
        </Col>
      </Row>

      {/*  </Scrollbars> */}
      <Modal
        title={false}
        footer={false}
        centered
        visible={IsAddingToIndex}
        closable={false}
      >
        {AddToIndexStatus === 0 && (
          <Result
            status="error"
            title={t("admin_dashboard.failed_to_create_index")}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setIsAddingToIndex(false);
                }}
              >
                {t("admin_dashboard.close")}
              </Button>,
            ]}
          />
        )}
        {AddToIndexStatus === 2 && (
          <Result
            icon={<Spin indicator={antIcon} />}
            title={t("admin_dashboard.creating_index")}
          />
        )}
        {AddToIndexStatus === 3 && (
          <Result
            status="success"
            title={t("admin_dashboard.monthly_index_created")}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setIsAddingToIndex(false);
                }}
              >
                {t("admin_dashboard.close")}
              </Button>,
            ]}
          />
        )}
      </Modal>
    </>
  );
}

export default observer(Dashboard);
