import React, { useState } from "react";
import { observer, inject } from "mobx-react";

import "moment/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";

import { Row, Col, Form, Input, Button, message, DatePicker } from "antd";
//translation
import { useTranslation } from "react-i18next";

const PropertyAuditAdder = (props) => {
  const { t } = useTranslation("common"); //translation

  const { property, store } = props;
  const [auditform] = Form.useForm();
  const [busy, setBusy] = useState(false);
  const onFinishAuditForm = (values) => {
    var data = {
      auditDate: values.auditDate.format("MM/DD/YYYY"),
      comment: values.comment ? values.comment : "",
    };
    setBusy(true);
    property.addAuditLog(data).then((s) => {
      if (s) {

        message.success(t("audit.audit_log_added_successfully"));
        store.setClientListUpdateOn(new Date().getTime().toString());
      } else {
        message.error(t("audit.could_not_add_audit_log_property"));
      }
      setBusy(false);
    });
  };

  return (
    <Form
      form={auditform}
      name="horizontal_login"
      layout="inline"
      onFinish={onFinishAuditForm}
    >
      <Row style={{ width: "100%", margin: "20px 0px" }}>
        <Col span={6}>
          <Form.Item
            label={t("audit.title")}
            name="auditDate"
            rules={[
              { required: true, message: t("audit.please_enter_audit_date") },
            ]}
          >
            <DatePicker locale={locale} format={"DD.MM.YYYY"} />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item name="comment" label= {t("audit.comment")}>
            <Input maxLength={2000} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={busy}>
              {t("audit.add_audit_log")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default inject("store")(observer(PropertyAuditAdder));
