import React from "react";
import { observer } from "mobx-react";

import AdminDashboard from "../components/Admin/Dashboard";
import ClientDashboard from "../components/Client/Dashboard";

const Dashboard = (props) => {
  const { store } = props;

  return (
    <>
      {store.company.type === 0 && <AdminDashboard {...props} />}
      {store.company.type === 1 && <ClientDashboard {...props} />}
    </>
  );
};

export default observer(Dashboard);
