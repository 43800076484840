import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  Space,
  Table,
  Modal,
  Drawer,
  Descriptions,
  Divider,
  Select,
} from "antd";

import ClientPortfoliosDrawer from "./ClientPortfoliosDrawer";
import ClientUsersListing from "./ClientUsersListing";
import CompanyModel from "../../models/CompanyModel";
//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;
const ClientDetailDrawer = (props) => {
  const { t } = useTranslation("common"); //translation

  const {
    store,
    isClientDetailVisible,
    setIsClientDetailVisible,
    isClientUsersModalVisible,
    setIsClientUsersModalVisible,
    /*  isClientPortfoliosVisible,
    setIsClientPortfoliosVisible, */
    postal_codes,
    cities,
    cantons,
    regions,
    /*  loadClientList,
    isNewClient,
    setIsNewClient,
    selectedClient,
    setSelectedClient, */
    isAddMode,
    setIsAddMode,
    getCantonName,
  } = props;

  const loadClientList = () => {
    store.getClients();
  };

  const clientId = store.activeClientId;
  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}));
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    if (clientId === 0) {
      setSelectedClient(CompanyModel.create({}));
    } else {
      setSelectedClient(store.clients.find((c) => c.id === clientId));
    }
    return () => {
      /*   setSelectedClient(CompanyModel.create({})); */
    };
  }, [clientId]);

  const [isClientPortfoliosVisible, setIsClientPortfoliosVisible] = useState(
    false
  );

  const clientUsersProps = {
    store,
    /*  isClientUsersModalVisible,
    setIsClientUsersModalVisible, */
    selectedClient,
   
    mustRefresh: isClientPortfoliosVisible,
    /*   isAddMode,
    setIsAddMode, */
  };

  const clientPortfoliosProps = {
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible,
    /* clientId:  selectedClient.id , */
    /* selectedClient, */
    portfolios,
    getCantonName,
    /*  portfolios: store.portfolios.filter(p=>p.companyId==selectedClient.id) */
  };

  const [ClientFormMessage, setClientFormMessage] = useState("");

  /*   const plzs = postal_codes.map((r) => {
    return { value: r.plz };
  }); */

  const plzs = postal_codes
    .map((r) => {
      return { value: r.plz };
    })
    .filter(function(obj, index, self) {
      return (
        index ===
        self.findIndex(function(t) {
          return t["value"] === obj["value"];
        })
      );
    });

  /*   const cts = cities.map((r) => {
    return { value: r.city };
  });
  const cants = cantons.map((r) => {
    return { value: r.name };
  });
 */
  useEffect(() => {
    setClientFormMessage("");
    setIsFormMode(false);
    setIsClientDetailVisible(false);

    setPlzOptions([]);

    setCityOptions([]);

    setCantonOptions(
      cantons.map((r) => {
        return { value: r.id, name: r.name };
      })
    );

    return () => {};
  }, []);
  /* 
  useEffect(() => {
    store.getPropertiesByCompany(selectedClient.id);
    return () => {};
  }, [selectedClient]);
 */
  const [form] = Form.useForm();
  const onClientFormFinish = (values) => {
    console.log("Success:", values); //{email: "valerie", password: "12345678$", remember: true}

    if (selectedClient) {
      selectedClient.setName(values.name);
      selectedClient.setAddress(values.address);
      selectedClient.setPlz(values.plz);
      selectedClient.setCity(values.city);
      selectedClient.setCanton(values.canton);
      selectedClient.setRegion(values.region);
      selectedClient.update().then((result) => {
        if (result) {
          //loadClientList();
          store.setClientListUpdateOn(new Date().getTime().toString());
          setTitle(values.name);

          setClientFormMessage(t("client_detail.client_info_updated_msg"));
          setTimeout(() => {
            setClientFormMessage("");
          }, 3000);
        } else {
          setClientFormMessage(t("client_detail.cant_up_client_info_msg"));
        }
      });
    } else {
      // this is an new client
      store
        .addClient(
          values.name,
          values.address,
          values.plz,
          values.city,
          values.canton
        )
        .then((result) => {
          if (result && result.id) {
            setSelectedClient(result.id);
            //setIsNewClient(false);
            setIsFormMode(false);
            //loadClientList();
            store.setClientListUpdateOn(new Date().getTime().toString());
            setClientFormMessage(t("client_detail.new_client_added_msg"));
            store.setActiveClientId(result.id);
            setClientName(result.name);
            setPlz(result.plz);
            setCity(result.city);
            setCanton(result.canto);
            setTitle(result.name);

            setTimeout(() => {
              /*  setIsClientDetailVisible(false);
              setClientId(0);
              setClientName("");
              setPlz("");
              setCity("");
              setCanton(""); */
              setClientFormMessage("");
            }, 3000);
          } else {
            setClientFormMessage(t("client_detail.cant_add_client_info_msg"));
          }
        });
    }
  };

  const onClientFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //const [clientId, setClientId] = useState(0);
  const [clientName, setClientName] = useState("");
  const [clientNameValidationState, setClientNameValidationState] = useState(
    ""
  );
  const [address, setAddress] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [canton, setCanton] = useState("");

  const [plzOptions, setPlzOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [cantonOptions, setCantonOptions] = useState([]);

  const [isFormMode, setIsFormMode] = useState(false);

  useEffect(() => {
    if (selectedClient) {
      //setClientId(selectedClient.id);
      setClientName(selectedClient.name);
      setAddress(selectedClient.address);
      setPlz(selectedClient.plz);
      onChangePLZ(selectedClient.plz);
      setCity(selectedClient.city);
      setCanton(selectedClient.canton);

      form.setFieldsValue({ ...selectedClient });
    } else {
      // setClientId(0);
      setClientName("");
      setAddress("");
      setPlz("");
      setCity("");
      setCanton("");
      form.resetFields();
    }
    return () => {
      // setClientId(0);
      setClientName("");
      setAddress("");
      setPlz("");
      setCity("");
      setCanton("");
      form.resetFields();
    };
  }, [selectedClient]);

  const onChangeClientName = (e) => {
    setClientNameValidationState("validating");

    store.clientExists(e.target.value).then((res) => {
      if (res.exists === false) setClientNameValidationState("success");
      else {
        if (selectedClient) {
          if (res.client.id === selectedClient.id)
            setClientNameValidationState("success");
          //this is the same client
          else setClientNameValidationState("error");
        }
      }
    });
  };
  const onSelectPLZ = (data) => {
    console.log("onSelect", data);
  };
  const onChangePLZ = (data) => {
    setPlz(data);
    // if exact match then filter city autocomplete list
    if (plzs.find((r) => r.value === data)) {
      //set cantons
      var plzdata = postal_codes.find((p) => p.plz === data);
      if (plzdata) {
        /*  var cantonIds = postal_codes
          .filter((p) => p.plz === thiscity.plz)
          .map((x) => x.cantonId); 
        
          var filteredCantons = cantons.filter(function (canton) {
          return cantonIds.indexOf(canton.id) != -1;
        }); */

        var filteredCantons = cantons.filter(
          (canton) => canton.id === plzdata.cantonId
        );

        setCantonOptions(
          filteredCantons.length > 0
            ? filteredCantons.map((r) => {
                return { value: r.id, name: r.name };
              })
            : []
        );

        if (filteredCantons.length > 0) {
          setCanton(filteredCantons[0].id);

          form.setFieldsValue({ canton: filteredCantons[0].id });

          if (filteredCantons.length === 1) {
            var region = regions.find(
              (r) => r.id === filteredCantons[0].regionId
            );
            if (region) form.setFieldsValue({ region: region.name });
          }
        }
      } else {
        setCantonOptions(
          cantons.map((r) => {
            return { value: r.id, name: r.name };
          })
        );
      }

      var filteredCities = cities.filter((c) => c.plz === data);

      setCityOptions(
        filteredCities.map((r) => {
          return { value: r.city };
        })
      );

      if (filteredCities.length > 0) {
        setCity(filteredCities[0].city);
        form.setFieldsValue({ city: filteredCities[0].city });
      }
    } else {
      setCityOptions([]);
    }
  };
  const onSearchPLZ = (searchText) => {
    setPlzOptions(plzs.filter((r) => r.value.indexOf(searchText) > -1));
  };
  const onChangeCity = (data) => {
    console.log("onChangeCity", data);
    setCity(data);
    // if exact match then filter city autocomplete list
    var thiscity = cities.find((r) => r.city === data);
    if (thiscity) {
      console.log(thiscity);
    }
  };
  const onChangeCanton = (data) => {
    console.log("onChangeCanton", data);
    var canton = cantons.find((c) => c.id === data);
    if (canton) {
      var region = regions.find((r) => r.id === canton.regionId);
      if (region) form.setFieldsValue({ region: region.name });
    }
  };

  const [title, setTitle] = useState("");
  useEffect(() => {
    setTitle(
      selectedClient.id === 0
        ? t("client_detail.new_client")
        : selectedClient.name
    );
    /* if (isNewClient && !selectedClient) {
      setIsFormMode(true);
    } else {
      setIsFormMode(false);
    } */
    return () => {
      setTitle(
        selectedClient.id === 0
          ? t("client_detail.new_client")
          : selectedClient.name
      );
    };
  }, [selectedClient]);
  return (
    <>
      <Drawer
        destroyOnClose
        title={false}
        placement="right"
        closable={true}
        onClose={() => {
          if (clientId === 0) setIsClientDetailVisible(false);
          else {
            if (isFormMode) {
              setIsFormMode(false);
            } else setIsClientDetailVisible(false);
          }

          store.setActiveClientId(0);
        }}
        visible={isClientDetailVisible && clientId > 0}
        getContainer={true}
        style={{ position: "absolute" }}
        width={"calc(100% - 300px)"}
      >
        <>
          <Title
            style={{
              display: "block !important",
              textAlign: "left",
              width: "calc(50% - 8px)",
            }}
            level={3}
          >
            {t("client_detail.client_semi")} {title}
          </Title>

          <div style={{ textAlign: "left", marginBottom: "16px" }}>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  setIsFormMode(true);
                }}
              >
                {t("client_detail.edit")}
              </Button>

              <Button
                type="default"
                htmlType="button"
                onClick={() => {
                  //store.getPortfoliosByCompany(clientId).then((x) => {
                  // selectedClient.getPortfolios().then((s) => {
                  setIsClientPortfoliosVisible(true);
                  // });
                  //});
                }}
              >
                {t("client_detail.view_portfolios")} ({store.portfolios.length})
              </Button>
            </Space>
          </div>
          {selectedClient && (
            <Descriptions
              bordered
              size={"default"}
              className={"description-label-fix"}
            >
              {/*   <Descriptions.Item label="Name" span={3}>
            {selectedClient.name}
          </Descriptions.Item> */}
              <Descriptions.Item label={t("client_detail.address")} span={3}>
                {selectedClient.address}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_detail.plz")}>
                {selectedClient.plz}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_detail.city")} span={2}>
                {selectedClient.city}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_detail.canton")}>
                {selectedClient.canton}
              </Descriptions.Item>
              <Descriptions.Item label={t("client_detail.region")}>
                {selectedClient.region}
              </Descriptions.Item>
            </Descriptions>
          )}
        </>
        <Divider></Divider>
        <ClientUsersListing {...clientUsersProps}></ClientUsersListing>
        <Modal
          title={false}
          centered
          visible={isFormMode}
          closable={false}
          footer={false}
        >
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: false }}
            onFinish={onClientFormFinish}
            onFinishFailed={onClientFormFinishFailed}
          >
            <Row type="flex" align="middle">
              <Title
                style={{
                  display: "block !important",
                  textAlign: "left",
                  width: "100%",
                }}
                level={3}
              >
                {title}
              </Title>
            </Row>

            <Form.Item
              label={t("client_detail.client_name")}
              name="name"
              validateStatus={clientNameValidationState}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("client_detail.please_enter_client_name"),
                },
              ]}
              onChange={onChangeClientName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("client_detail.address")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("client_detail.please_enter_client_add"),
                },
              ]}
            >
              <Input value={address} />
            </Form.Item>
            <Form.Item
              label={t("client_detail.plz")}
              name="plz"
              rules={[
                {
                  required: true,
                  message: t("client_detail.please_enter_postal_code"),
                },
              ]}
            >
              <AutoComplete
                options={plzOptions}
                onSelect={onSelectPLZ}
                onSearch={onSearchPLZ}
                onChange={onChangePLZ}
                placeholder={t("client_detail.plz")}
              >
                <Input />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              label={t("client_detail.city")}
              name="city"
              rules={[
                {
                  required: true,
                  message: t("client_detail.please_enter_postal_code"),
                },
              ]}
            >
              <AutoComplete
                options={cityOptions}
                onChange={onChangeCity}
                placeholder="City"
              >
                <Input value={city} />
              </AutoComplete>
              {/* <Select onChange={onChangeCity}>
                {cityOptions.map((city) => (
                  <Option key={city.value} value={city.value}>
                    {city.value}
                  </Option>
                ))}
              </Select> */}
            </Form.Item>
            <Form.Item
              label={t("client_detail.canton")}
              name="canton"
              rules={[
                {
                  required: true,
                  message: t("client_detail.please_enter_canton"),
                },
              ]}
            >
              {/*  <AutoComplete
            options={cantonOptions}
         
            placeholder="Canton"
          >
            <Input value={canton} />
          </AutoComplete> */}

              <Select onChange={onChangeCanton}>
                {cantonOptions.map((canton) => (
                  <Option key={canton.value} value={canton.value}>
                    {canton.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t("client_detail.region")} name="region">
              <Input readOnly={cityOptions.length > 0} />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {clientId === 0
                    ? t("client_detail.save")
                    : t("client_detail.update")}
                </Button>
                <Button
                  type="button"
                  htmlType="button"
                  onClick={() => {
                    /*
                  if (isNewClient) {
                    setIsClientDetailVisible(false);
                    
                  } else setIsFormMode(false);
                  */
                    setIsFormMode(false);
                  }}
                >
                  {clientId === 0
                    ? t("client_detail.cancel")
                    : t("client_detail.done")}
                </Button>
              </Space>
            </Form.Item>
            <div
              style={{
                display: "block !important",
                textAlign: "center",
                width: "100%",
              }}
            >
              {ClientFormMessage}
            </div>
          </Form>
        </Modal>
      </Drawer>
      {store.activeClientId > 0 && (
        <ClientPortfoliosDrawer
          {...clientPortfoliosProps}
        ></ClientPortfoliosDrawer>
      )}
    </>
  );
};

export default observer(ClientDetailDrawer);
