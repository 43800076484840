import React from "react";
import { observer } from "mobx-react";

import { Typography, Button, Modal } from "antd";
//translation
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const PropertyImportModal = (props) => {
  const { t } = useTranslation("common"); //translation

  const { visible, setVisible } = props;

  return (
    <Modal
      title={false}
      centered
      visible={visible}
      okText={t("property.dismiss")}
      closable={false}
      footer={false}
      width="640"
      className={"xant-custom"}
      onOk={() => {
        setVisible(false);
      }}
    >
      <Title>{t("property.import_property_data")}</Title>
      <Button
        onClick={() => {
          setVisible(false);
        }}
      >
        {t("property.dismiss")}
      </Button>
    </Modal>
  );
};

export default observer(PropertyImportModal);
