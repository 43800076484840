import React from "react";
import { Form, Input, Button, Upload, Alert } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
//translation
import { useTranslation } from "react-i18next";

const FileUploadForm = ({
  visible,
  confirmLoading,
  handleCancel,
  handleOk,
  setFiles,
  documents,
  setTitle,
  title,
  setDescription,
  description,
  titleExist,
}) => {
  const { t } = useTranslation("common"); //translation

  const [form] = Form.useForm();
  const [multiFile, setMulti] = React.useState(false);
  const [noFile, setNoFile] = React.useState(false);

  const props = {
    onRemove: (file) => {
      setFiles((prevState) => {
        const index = prevState.indexOf(file);
        const newFileList = prevState.slice();

        newFileList.splice(index, 1);

        return newFileList;
      });
    },

    beforeUpload: (file) => {
      setMulti(false);

      setFiles((prevState) => {
        if (prevState.length === 0) {
          return [...prevState, file];
        } else {
          setMulti(true);
          return prevState;
        }
      });

      return false;
    },

    document,
  };

  return (
    <Modal
      title={t("file_upload.upload_new_document")}
      visible={visible}
      okText={t("file_upload.save")}
      cancelText={t("file_upload.cancel")}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setNoFile(false);

            if (documents.length === 0) {
              setNoFile(true);
            } else {
              handleOk();
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      // footer={[

      //    <Button key="back" onClick={handleCancel}>
      //    Close
      //    </Button>,
      //    <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
      //    Save
      //    </Button>,
      // ]}
    >
      {titleExist && (
        <Alert
          message={t("file_upload.warning")}
          description={t("file_upload.title_already_exists")}
          type="error"
          showIcon
          closable
        />
      )}
      <Form style={{ marginTop: "5px" }} form={form} layout="vertical">
        <Form.Item
          label={t("file_upload.title")}
          name="title"
          rules={[
            {
              required: true,
              message: t("file_upload.please_input_document_title"),
            },
          ]}
        >
          <Input
            onChange={(e) => setTitle(e.currentTarget.value)}
            value={title}
          />
        </Form.Item>

        <Form.Item
          label={t("file_upload.description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("file_upload.please_input_document_description"),
            },
          ]}
        >
          <TextArea
            rows={4}
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
          />
        </Form.Item>

        {multiFile && (
          <Alert
            message={t("file_upload.warning")}
            description={t("file_upload.only_one_file_can_be_uploaded")}
            type="warning"
            showIcon
            closable
          />
        )}
        {noFile && (
          <Alert
            message={t("file_upload.warning")}
            description={t("file_upload.no_file_selected")}
            type="error"
            showIcon
            closable
          />
        )}
        <br />
        <h4> {t("file_upload.file")}</h4>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>
            {t("file_upload.select_file")}
          </Button>
        </Upload>
      </Form>
    </Modal>
  );
};

export default FileUploadForm;
