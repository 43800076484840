import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  Row,
  Col,
  Form,
  // Input,
  Button,
  Modal,
  // InputNumber,
  Typography,
  message,
  // Collapse,
  Card,
  Spin,
  // Tooltip,
} from "antd";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { Scrollbars } from "react-custom-scrollbars";
import parse from "html-react-parser";
import {
  criteria,
  // getPropertyRating,
  getWeightedAverageFromSubrating,
} from "../../../data/RatingCriteria";
import PropertyModel from "../../../models/PropertyModel";

//Rows
import RatingRow from "./RatingRow";
import RatingRowPerUsage from "./RatingRowPerUsage";
//CriteriaList
import RatingCriteriaList from "./RatingCriteriaList";
//Controller
import RatingsModalController from "../../../controllers/RatingsModalController";

//translation
import { useTranslation } from "react-i18next";

// import { COLORS_SERIES } from "../../../data/constants";

// const { Panel } = Collapse;
// const { TextArea } = Input;
const { Title, Link } = Typography;

const ActualRatingsModal = (props) => {
  var { t } = useTranslation("common"); //translation
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { store, visible, setVisible, id, refreshList, setPropertyId } = props;
  // const COLORS_SERIES = { G: "#ff5555", U: "#aacc88", W: "#99ccff" };
  const currentProperty = id
    ? /*  ? store.properties.find((pp) => pp.id === id) */
      store.clients
        .find((c) => c.id === store.activeClientId)
        .portfolios.find((pf) => pf.id === store.activePortfolioId)
        .properties.find((pp) => pp.id === id)
    : PropertyModel.create({});

  const [currentPortfolioPropertyIds, setCurrentPortfolioPropertyIds] =
    useState([]);
  const [ratings, setRatings] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [indexedRatings, setIndexedRatings] = useState([]);
  // const [saveDone, setSaveDone] = useState(false);

  useEffect(() => {
    const propertyIds = [];
    store.clients
      .find((c) => c.id === store.activeClientId)
      .portfolios.find((pf) => pf.id === store.activePortfolioId)
      .properties.map((pp) => propertyIds.push(pp.id));
    setCurrentPortfolioPropertyIds(propertyIds);
    // console.log(currentPortfolioPropertyIds, "currentPortfolioPropertyIds");
  }, []);

  useEffect(() => {
    if (currentProperty.id > 0)
      currentProperty.getRatings().then((success) => {
        if (success) {
          setRatings(currentProperty.ratings);

          // does not require adding initial ratings in this
          /* if (currentProperty.ratings.length === 0) {
            saveInitialRatings(currentProperty);
          } */
          currentProperty.getIndexedRatings().then((returnedIndexedRatings) => {
            setIndexedRatings(returnedIndexedRatings);
          });
        } else {
          //failed to initialize...
          form.resetFields();
        }
      });

    return () => {
      form.resetFields();
    };
  }, [currentProperty]);

  const [form] = Form.useForm();

  //generate google maps link for address
  const getGoogleMapsLink = (address, plz, city) => {
    return `https://www.google.com/maps/search/?api=1&query=${address}+${plz}+${city}`;
  };

  // get property index in array
  const getPropertyIndexValue = (propertyId, prevOrNext) => {
    if (prevOrNext === "prev") {
      return currentPortfolioPropertyIds[
        currentPortfolioPropertyIds.indexOf(propertyId) - 1
      ];
    } else if (prevOrNext === "next") {
      return currentPortfolioPropertyIds[
        currentPortfolioPropertyIds.indexOf(propertyId) + 1
      ];
    }
  };
  // change current property to previous
  const onPreviousProperty = (currentPropertyId) => {
    setIsModalLoading(true);
    const prevPropertyId = getPropertyIndexValue(currentPropertyId, "prev");
    store.setActivePropertyId(prevPropertyId).then(() => {
      if (setPropertyId) setPropertyId(prevPropertyId);
      setTimeout(() => {
        setIsModalLoading(false);
      }, 250);
    });
    setIsFormChanged(false);
  };
  // change current property to next
  const onNextProperty = (currentPropertyId) => {
    setIsModalLoading(true);
    const nextPropertyId = getPropertyIndexValue(currentPropertyId, "next");

    store.setActivePropertyId(nextPropertyId).then(() => {
      if (setPropertyId) setPropertyId(nextPropertyId);

      setTimeout(() => {
        setIsModalLoading(false);
      }, 250);
    });
    setIsFormChanged(false);
  };
  //when any form value changes..
  const onFormValuesChange = (changedValues, allValues) => {
    // sacn for subrating chnages and computethe parent critreias weighted average...
    for (var key in changedValues) {
      if (key.split(".").length === 3) {
        var trueKey = key.split("_")[0].split(".").slice(0, 2).join(".");
        computeCriteriaAverageRating(trueKey);
        /* ratings.forEach((rating) => {
          if (rating.key === trueKey) {
            rating.setValues({ value: changedValues[trueKey] });
           // computeCriteriaAverageRating(trueKey);
          }
        }); */
      }
    }
    computeTotalAverageRating();
  };

  const isIndexed = (_criteriaKey, _value) => {
    if (!indexedRatings || indexedRatings.length === 0) return true;

    let key_parts = _criteriaKey.split(".");
    let _newCriteriaKey =
      key_parts.length > 2 ? _criteriaKey : _criteriaKey + ".1";

    var found = false;
    let matchRating = indexedRatings.find((ir) => {
      return (
        ir.key === _newCriteriaKey &&
        parseFloat(ir.value) === parseFloat(_value)
      );
    });

    if (matchRating) {
      found = true;
    } else {
      found = false;
    }
    return found;
  };

  const onFormFinish = (values) => {
    //transform form fields to array of {key,value,comment}
    var ratings = [];
    var p_id = currentProperty.id;
    for (var i in values) {
      var kp = i.split("_");
      var field = ratings.find((f) => f.key === kp[0]);
      var found = true;
      if (!field) {
        field = {
          propertyId: p_id,
          key: kp[0],
          value: 0,
          comment: "",
          indexChanged: 0,
        };
        found = false;
      }
      if (kp[1] === "rating") {
        field.value = parseFloat(values[i]);
        field.indexChanged = isIndexed(kp[0], values[i]) ? 0 : 1;
      } else field.comment = values[i];

      if (!field.comment) field.comment = "";
      if (!found) ratings.push(field);
    }

    // Remove value if usage type is 0

    currentProperty
      .updateRatings(
        ratings,
        null,
        Math.round(parseFloat(totalAverageRating) * 100) / 100
      )
      .then((success) => {
        if (success) {
          store.setClientListUpdateOn(new Date().getTime().toString());
          message.success(t("admin_rating.ratings_updated_successfully"));
          // setSaveDone(true);
        }
      });
  };
  const onFormFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const saveInitialRatings = (currentProperty) => {
    //transform form fields to array of {key,value,comment}
    var defaults = [];
    if (currentProperty.propertyAttributes.length > 0) {
      defaults = store.propertyDefaultRatings.filter((def) =>
        currentProperty.propertyAttributes.includes(def.attribute_id)
      );
    }
    const getDefaultInitialValue = (key) => {
      var ret = false;
      console.log("Matching " + key + "...");
      if (currentProperty.propertyAttributes.length > 0) {
        // this assumes that there could be multiple matches
        var defaultKeyRating = defaults.filter(
          (def) => def.key === key && def.value > -1
        );
        if (defaultKeyRating && defaultKeyRating.length) {
          if (defaultKeyRating.length > 0) {
            // take the last element's .value
            var thisKeyRating = defaultKeyRating[defaultKeyRating.length - 1];
            ret = thisKeyRating.value;
            console.log(
              "Matched:" +
                key +
                " = " +
                thisKeyRating.attribute_id +
                ":" +
                thisKeyRating.key +
                "=>" +
                thisKeyRating.value
            );
          }
        }
      }

      // age based rating calculation
      // if (ret == 0) {
      var thiskey = "";
      if (key.split(".".length == 3)) {
        thiskey = key.split(".").splice(0, 2).join(".");
      } else thiskey = key;

      const ageRatingsByCriteria = {
        "vor/um 1960": {
          106.2: 0,
          107.1: 0,
          108.1: 0,
          108.2: 0,
          202.1: 0,
          301.1: 3,
          301.2: 0,
          303.2: 0,
          303.3: 1,
        },
        "1960-1969": {
          202.1: 0,
        },
        "1970-1990": {
          106.2: 0,
          107.1: 0,
          108.1: 0,
          108.2: 0,
          301.1: 2,
          301.2: 0,
          303.2: 0,
          303.3: 0,
        },
        "Gebäude ohne Abschattung": {
          106.1: 3,
        },
        "Gebäude mit Abschattung": { 106.1: 2 },
        "1988-2003": {
          106.2: 2,
        },
        "2004-2006": {
          204.2: 3,
        },
        "2006-2009": {
          106.1: 3,
          106.2: 3,
          204.2: 3,
        },
        /* "nach 2009": {
          "103.3": 3,
          "106.1": 3,
          "106.2": 3,
          "204.2": 3,
        }, */
      };

      //which age bracket does this property belong?
      var age_bracket = "";
      var this_age = currentProperty.age;
      if (this_age < 1960) age_bracket = "vor/um 1960";
      if (this_age >= 1960 && this_age <= 1969) age_bracket = "1960-1969";
      if (this_age >= 1969 && this_age <= 1990) age_bracket = "1970-1990";

      if (currentProperty.propertyAttributes.indexOf(13) > -1) {
        // Gebäude mit Abschattung
        if (this_age > 1995 && this_age <= 2006)
          age_bracket = "Gebäude mit Abschattung";
      } else {
        //Gebäude ohne Abschattung
        if (this_age > 1995 && this_age <= 2006)
          age_bracket = age_bracket = "Gebäude ohne Abschattung";
      }

      if (this_age > 1995 && this_age <= 2006) {
        if (currentProperty.propertyAttributes.indexOf(13) > -1)
          age_bracket = "Gebäude mit Abschattung";
        else age_bracket = "Gebäude ohne Abschattung";
      }

      if (this_age > 1988 && this_age <= 2003) age_bracket = "1988-2003";
      if (this_age > 2003 && this_age <= 2006) age_bracket = "20043-2006";
      if (this_age > 2006 /* && this_age <= 2009 */) age_bracket = "2006-2009";
      //if (this_age > 2009) age_bracket = "nach 2009";

      if (age_bracket !== "") {
        var bracket_criteria = ageRatingsByCriteria[age_bracket];
        if (key in bracket_criteria) {
          ret = bracket_criteria[key];
          console.log("Matched:" + key + " = " + age_bracket + "=>" + ret);
        }
      }
      // }

      return ret;
    };

    var ratings = [];
    var p_id = currentProperty.id;

    criteria.forEach((group) => {
      group.children.forEach((rating) => {
        if (rating.children) {
          rating.children.forEach((subrating) => {
            var defaultValue = getDefaultInitialValue(subrating.key);
            if (defaultValue !== false) {
              ratings.push({
                propertyId: p_id,
                key: subrating.key,
                value: defaultValue,
                comment: "",
              });
            }
          });
        } else {
          var defaultValue = getDefaultInitialValue(rating.key);
          if (defaultValue !== false) {
            ratings.push({
              propertyId: p_id,
              key: rating.key,
              value: defaultValue,
              comment: "",
            });
          }
        }
      });
    });

    currentProperty.updateRatings(ratings).then((success) => {
      console.log("Default ratings applied to new property.");
    });
  };

  useEffect(() => {
    bindRatings();
    return () => {
      form.resetFields();
    };
  }, [ratings]);

  const [totalAverageRating, setTotalAverageRating] = useState(0);
  const bindRatings = () => {
    const property = currentProperty;
    /*  var totalArea =
      property.areaOffice + property.areaResidential + property.areaCommercial;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea; */

    var hasRatings = ratings.length > 0;

    form.resetFields();
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        // if there are subratings...
        if (c.children) {
          var cv = [];
          c.children.forEach((u) => {
            var key = u.key;
            var rating = ratings.find((r) => r.key === key);
            if (rating) {
              // console.log("rating found: " + key);
              form.setFieldsValue({ [key + "_rating"]: rating.value });
              form.setFieldsValue({ [key + "_comment"]: rating.comment });
              cv.push(rating.value);
            } else {
              //form.setFieldsValue({ [key + "_rating"]: "" });

              // form.setFieldsValue({ [key + "_comment"]: "" });
              cv.push(0);
            }
          });
          computeCriteriaAverageRating(c.key);
          /*    var wAVgRating = getWeightedAverageFromSubrating(
            property,

            cv[0],
            cv[1],
            cv[2]
          );
          //var wAVgRating = getFormCriteriaRating(property, c.key, form);
          if (isNaN(wAVgRating)) wAVgRating = 0;
          form.setFieldsValue({ [c.key + "_rating"]: wAVgRating }); */

          // computeCriteriaAverageRating(c.key);
        } else {
          var rating = ratings.find((r) => r.key === c.key);
          if (rating) {
            form.setFieldsValue({ [c.key + "_rating"]: rating.value });
            form.setFieldsValue({ [c.key + "_comment"]: rating.comment });
          } else {
            // form.setFieldsValue({ [c.key + "_rating"]: "" });
            //form.setFieldsValue({ [c.key + "_comment"]: "" });
          }
        }
      });
    });

    computeTotalAverageRating();
  };

  //when a usage rating changes, the parent criteria's avg rating is calculated again.

  const getAverage = (key) => {
    if (key === undefined) return 0;

    var subrating = [1, 2, 3, 4, 5].map((x) => {
      return parseFloat(form.getFieldValue(key + `.${x}_rating`));
    });

    var avg = getWeightedAverageFromSubrating(
      currentProperty,

      isNaN(subrating[0]) ? "" : subrating[0],
      isNaN(subrating[1]) ? "" : subrating[1],
      isNaN(subrating[2]) ? "" : subrating[2],
      isNaN(subrating[3]) ? "" : subrating[3],
      isNaN(subrating[4]) ? "" : subrating[4]
    );

    return avg;

    // var value = 0;
    // criteria.forEach((g) => {
    //   g.children.forEach((c) => {
    //     //this is the parent criteria
    //     if (c.key === key) {
    //       var sumRating = 0;
    //       if (c.children) {
    //         c.children.forEach((s) => {
    //           sumRating += parseFloat(form.getFieldValue(s.key + "_rating"));
    //         });
    //         sumRating = parseFloat((sumRating / c.children.length).toFixed(2));
    //       }

    //       value = sumRating;
    //     }
    //   });
    // });
    // return isNaN(value) ? 0 : value;
  };
  const computeCriteriaAverageRating = (key) => {
    storeScrollY();

    var subrating = [1, 2, 3, 4, 5].map((x) => {
      return parseFloat(form.getFieldValue(key + `.${x}_rating`));
    });

    var v = getWeightedAverageFromSubrating(
      currentProperty,

      isNaN(subrating[0]) ? "" : subrating[0],
      isNaN(subrating[1]) ? "" : subrating[1],
      isNaN(subrating[2]) ? "" : subrating[2],
      isNaN(subrating[3]) ? "" : subrating[3],
      isNaN(subrating[4]) ? "" : subrating[4]
    );
    // if (isNaN(v)) v = 0;
    form.setFieldsValue({ [key + "_computedRating"]: v });
    /*  criteria.forEach((g) => {
      g.children.forEach((c) => {
        //this is the parent criteria
        if (c.key === key) {
          var sumRating = 0;
          if (c.children) {
            c.children.forEach((s) => {
              sumRating += parseFloat(form.getFieldValue(s.key + "_rating"));
            });
            sumRating = parseFloat((sumRating / c.children.length).toFixed(2));
          }

          form.setFieldsValue({ [key + "_rating"]: sumRating });
        }
      });
    }); */
    //computeTotalAverageRating();
  };

  //confirm modal component
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [direction, setDirection] = useState("close");
  const ConfirmModal = ({ propertyId }) => {
    return (
      <Modal
        visible={confirmVisible}
        closable={false}
        width="420px"
        footer={[
          <Button
            type="default"
            style={{
              display: "inline-block",
            }}
            onClick={() => {
              // setIsFormChanged(false);
              setConfirmVisible(false);
            }}
          >
            {t("common.cancel")}
          </Button>,
          <Button
            type="default"
            style={{
              display: "inline-block",
            }}
            onClick={() => {
              if (direction === "next") {
                onNextProperty(propertyId);
              } else if (direction === "prev") {
                onPreviousProperty(propertyId);
              } else {
                setVisible(false);
              }
              setConfirmVisible(false);
            }}
          >
            {t("common.no")}
          </Button>,
          <Button
            type="primary"
            htmlType="submit"
            form="actualratingsform"
            style={{
              display: "inline-block",
            }}
            onClick={() => {
              form.submit();
              setIsFormChanged(false);
              setTimeout(() => {
                if (direction === "next") {
                  onNextProperty(propertyId);
                  // setSaveDone(false);
                } else if (direction === "prev") {
                  onPreviousProperty(propertyId);
                  // setSaveDone(false);
                } else {
                  setVisible(false);
                  // setSaveDone(false);
                }
              }, 3000);
              setConfirmVisible(false);
            }}
          >
            {t("common.yes")}
          </Button>,
        ]}
      >
        <Row>
          <Col span={4} align="left">
            <ExclamationCircleOutlined
              style={{ color: "orange", fontSize: "200%" }}
            />
          </Col>
          <Col span={20}>
            <h3> {t("admin_rating.confirmation")}</h3>
            {t("admin_rating.confirmation_message")}
          </Col>
        </Row>
      </Modal>
    );
  };

  //confirm button
  // const confirmModal = (propertyId, nextOrBack) => {
  //   Modal.confirm({
  //     title: t("admin_rating.confirmation"),
  //     icon: <ExclamationCircleOutlined />,
  //     content: t("admin_rating.confirmation_message"),
  //     okText: t("common.yes"),
  //     cancelText: t("common.no"),
  //     okButtonProps: {
  //       type: "primary",
  //       htmlType: "submit",
  //       form: "actualratingsform",
  //       style: {
  //         display: "inline-block",
  //         margin: "0 8px",
  //       },
  //     },
  //     cancelButtonProps: {
  //       type: "default",
  //       style: {
  //         display: "inline-block",
  //         margin: "0 8px",
  //       },
  //     },
  //     onOk() {
  //       setIsFormChanged(false);
  //       setTimeout(() => {
  //         if (nextOrBack === "next") {
  //           onNextProperty(propertyId);
  //           // setSaveDone(false);
  //         } else if (nextOrBack === "prev") {
  //           onPreviousProperty(propertyId);
  //           // setSaveDone(false);
  //         } else {
  //           setVisible(false);
  //           // setSaveDone(false);
  //         }
  //       }, 3000);
  //     },
  //     onCancel() {
  //       if (nextOrBack === "next") {
  //         onNextProperty(propertyId);
  //       } else if (nextOrBack === "prev") {
  //         onPreviousProperty(propertyId);
  //       } else {
  //         setVisible(false);
  //       }
  //     },
  //   });
  // };

  const getFormPropertyRating = (p, form) => {
    const property = toJS(p);
    if (property.ratings.length === 0) return 0;

    var ratingCount = 0;
    var sumRating = 0;
    var totalArea =
      property.areaOffice +
      property.areaResidential +
      property.areaCommercial +
      property.areaBuilding +
      property.areaHealth;

    var areaOfficePercentage = property.areaOffice / totalArea;
    var areaResidentialPercentage = property.areaResidential / totalArea;
    var areaCommercialPercentage = property.areaCommercial / totalArea;
    var areaBuildingPercentage = property.areaBuilding / totalArea;
    var areaHealthPercentage = property.areaHealth / totalArea;

    const _ratings = form.getFieldsValue();
    let _hasInvalidValue = false;

    for (const _rK of Object.keys(_ratings)) {
      if (_rK.includes("rating")) {
        if (isNaN(parseFloat(_ratings[_rK]))) {
          _hasInvalidValue = true;

          break;
        }
      }
    }

    if (!_hasInvalidValue) {
      //try {
      criteria.forEach((g) => {
        g.children.forEach((c) => {
          ratingCount++;
          // var value = 0;
          if (c.children) {
            //compute weighted vg from subratings first

            // fx= P.areaOffice/P.percentageOffice + P.areaResidential/P.percentageResidential+P.areaCommercial/P/percentageCommercial

            var sumSubrating = 0;
            //
            c.children.forEach((s) => {
              //subrate key will always be xxx.yyy.zzz,, get z then subtract 1 to get equiv factor 1- areaOfficePercentage...

              var factor = [
                areaResidentialPercentage,
                areaOfficePercentage,
                areaCommercialPercentage,
                areaBuildingPercentage,
                areaHealthPercentage,
              ][parseInt(s.key.split(".")[2]) - 1];

              // if((property.areaOffice > 0 && s.title === "Büro") || (property.areaResidential > 0 && s.title === "Wohnen")  || (property.areaCommercial > 0 && s.title === "Gewerbe")) {
              if (
                !form.getFieldValue(s.key + "_rating") ||
                form.getFieldValue(s.key + "_rating") === ""
              ) {
                sumSubrating += parseFloat(parseFloat(0)) * factor;
              } else {
                sumSubrating +=
                  parseFloat(
                    parseFloat(form.getFieldValue(s.key + "_rating"))
                  ) * factor;
              }
              // } else {
              //   sumSubrating += 0
              // }
            });
            // sumRating += parseFloat(sumSubrating / c.children.length);
            if (sumSubrating === undefined /*|| isNaN(sumSubrating)*/) {
              sumSubrating = 0;
            }

            sumRating += sumSubrating;
          } else {
            var vv = parseFloat(form.getFieldValue(c.key + "_rating"));
            //if (!isNaN(vv))
            sumRating += vv; //parseFloat(ar.value);
          }
        });
      });
      var result = sumRating / ratingCount;

      return result;
    } else {
      return NaN;
    }
  };
  const computeTotalAverageRating = () => {
    /*   var ratingCount = 0;
    var sumRating = 0;
    criteria.forEach((g) => {
      g.children.forEach((c) => {
        ratingCount++;
        // var value = 0;
        if (c.children) {
          //compute avg from subratings first
          var sumSubrating = 0;
          c.children.forEach((s) => {
            var subrate = form.getFieldValue(s.key + "_rating");
            if (subrate) {
              sumSubrating += parseFloat(subrate);
            }
          });
          sumRating += parseFloat(sumSubrating / c.children.length);
        } else {
          var ar = form.getFieldValue(c.key + "_rating");
          if (ar) {
            sumRating += parseFloat(ar);
          }
        }
      }); 
    });
    sumRating = parseFloat((sumRating / ratingCount).toFixed(2)); */

    //setTotalAverageRating(sumRating);

    //var s = getPropertyRating(currentProperty);
    var s = getFormPropertyRating(currentProperty, form);
    setTotalAverageRating(s);
  };

  const {
    setHelpDescription,
    helpTextTitle,
    helpGroup,
    helpChild,
    helpIntro,
    helpManual,
    helpAutomatic,
    helpCardStyle,
    helpFoto,
    helpFotoGoogle,
    helpFotoPlan,
    helpFotoGooglePlan,
    helpMessungen,
    helpPlan,
    helpWebLink,
    helpSonstige,
  } = RatingsModalController({ criteria, t });

  function blackOrWhiteText(bgColor) {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? "black" : "white";
  }

  const groupsHeaderStyle = ["t1", "t2", "t3"];
  const scrollRef = useRef(null);

  const [scrollY, setScrollY] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  //ref.current.scrollTop
  const storeScrollY = () => {
    if (!scrollRef.current) return;
    setScrollCount(scrollCount + 1);
    var sy = scrollRef.current.lastViewScrollTop; //getScrollTop();
    if (scrollRef.current) setScrollY(sy);
  };
  useLayoutEffect(() => {
    //useEffect(() => {

    setTimeout(() => {
      if (scrollRef.current) scrollRef.current.scrollTop(scrollY);
    }, 0.5);
  }, [scrollY, scrollCount]);

  const handleScrollStop = () => {
    //storeScrollY();
  };

  return (
    <Modal
      style={{ maxHeight: "100vh" }}
      destroyOnClose
      maskClosable={false}
      key={"actualRating"}
      width={1600}
      title={false}
      centered
      visible={visible}
      okText={t("admin_rating.submit")}
      closable={true}
      onCancel={() => {
        if (isFormChanged) {
          setDirection("close");
          setConfirmVisible(true);
        } else setVisible(false);
        // if (setPropertyId) setPropertyId(0);
      }}
      footer={false}
    >
      <ConfirmModal propertyId={currentProperty?.id} />
      <Form
        form={form}
        layout="vertical"
        name="actualratingsform"
        initialValues={{ remember: false }}
        onFinish={onFormFinish}
        onFinishFailed={onFormFinishFailed}
        onValuesChange={onFormValuesChange}
      >
        <Spin spinning={isModalLoading}>
          <Row type="flex" align="left">
            <Col span={12}>
              <Title
                style={{
                  display: "block !important",
                  textAlign: "left",
                  width: "100%",
                }}
                level={3}
              >
                {currentProperty?.id > 0 ? currentProperty.name + " : " : ""}
                {t("admin_rating.actual_property_ratings")}
              </Title>
            </Col>
            <Col span={12}>
              <div align={"right"} style={{ marginRight: "50px" }}>
                <Button
                  type="primary"
                  icon={<CaretLeftOutlined />}
                  disabled={
                    getPropertyIndexValue(currentProperty?.id, "prev") ==
                    undefined
                  }
                  style={{
                    margin: "0 5px 0",
                  }}
                  onClick={() => {
                    if (isFormChanged) {
                      setDirection("prev");
                      setConfirmVisible(true);
                    } else onPreviousProperty(currentProperty?.id);
                  }}
                />

                <Button
                  type="primary"
                  icon={<CaretRightOutlined />}
                  disabled={
                    getPropertyIndexValue(currentProperty?.id, "next") ==
                    undefined
                  }
                  style={{
                    margin: "0 5px 0",
                  }}
                  onClick={() => {
                    if (isFormChanged) {
                      setDirection("next");
                      setConfirmVisible(true);
                    } else onNextProperty(currentProperty?.id);
                  }}
                />
              </div>
            </Col>
            <Link
              style={{ margin: "0 0 10px 10px" }}
              href={getGoogleMapsLink(
                currentProperty.address,
                currentProperty.plz,
                currentProperty.city
              )}
              target="_blank"
            >
              {currentProperty.address}, {currentProperty.plz}{" "}
              {currentProperty.city}
            </Link>
          </Row>
          <Row gutter={16}>
            <Col span={13}>
              <div
                className="ant-table ant-table-small ant-table-bordered"
                style={{
                  borderRight: "solid 1px rgb(240,240,240)",
                }}
              >
                <div className="ant-table-container">
                  <table style={{ tableLayout: "auto" }}>
                    <colgroup>
                      <col style={{ width: "50px" }}></col>
                      <col style={{ width: "300px" }}></col>
                      <col style={{ width: "175px" }}></col>
                      <col style={{ width: "245px" }}></col>
                    </colgroup>
                    <thead className={"ant-table-thead "}>
                      <tr>
                        <th className="ant-table-cell" align={"center"}></th>
                        <th className="ant-table-cell" align={"center"}></th>
                        <th
                          className="ant-table-cell"
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("admin_rating.rating")}
                        </th>
                        <th className="ant-table-cell" align={"center"}>
                          {t("admin_rating.comment")}
                        </th>
                        {/* <th className="ant-table-cell" align={"center"}>
                        {t("admin_rating.snbs")}
                      </th> */}
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <Scrollbars
                ref={scrollRef}
                style={{ height: 700 }}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                onScrollStop={handleScrollStop}
              >
                <div
                  className="ant-table ant-table-small ant-table-bordered"
                  style={{
                    borderRight: "solid 1px rgb(240,240,240)",
                  }}
                >
                  <div className="ant-table-container">
                    <table style={{ tableLayout: "auto" }}>
                      <colgroup>
                        <col style={{ width: "50px" }}></col>
                        <col style={{ width: "300px" }}></col>
                        <col style={{ width: "175px" }}></col>
                      </colgroup>
                      {criteria &&
                        criteria.map((group, i) => {
                          return (
                            <React.Fragment key={group.groupName}>
                              <thead
                                key={group.groupName + "_thead"}
                                className={
                                  "ant-table-thead " + groupsHeaderStyle[i]
                                }
                              >
                                <tr key={group.groupName + "_tr"}>
                                  <th
                                    className="ant-table-cell"
                                    align={"center"}
                                    colSpan={2}
                                  >
                                    <Title level={4} style={{ margin: "0px" }}>
                                      {t("criteria.group." + i.toString())}
                                    </Title>
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {/*  {i === 0
                                    ? `Rating (${(
                                        Math.round(
                                          parseFloat(totalAverageRating) * 100
                                        ) / 100
                                      ).toFixed(2)})`
                                    : ""} */}
                                  </th>
                                  <th
                                    className="ant-table-cell"
                                    align={"center"}
                                    colSpan={2}
                                  >
                                    {/*  {i === 0 ? "Comment" : ""} */}
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                key={group.groupName + "_tbody"}
                                className={
                                  "ant-table-tbody " + groupsHeaderStyle[i]
                                }
                              >
                                {group.children.map((criteria, cindex) => {
                                  return criteria.children ? (
                                    <RatingRowPerUsage
                                      // key={criteria.key}
                                      criteria={criteria}
                                      setHelpDescription={setHelpDescription}
                                      // getAverage={getAverage}
                                      form={form}
                                      indexedRatings={indexedRatings}
                                      groupName={group.groupName}
                                      index={cindex}
                                      blackOrWhiteText={blackOrWhiteText}
                                      currentProperty={currentProperty}
                                      setIsFormChanged={setIsFormChanged}
                                    />
                                  ) : (
                                    <RatingRow
                                      // key={criteria.key}
                                      criteria={criteria}
                                      setHelpDescription={setHelpDescription}
                                      form={form}
                                      indexedRatings={indexedRatings}
                                      groupName={group.groupName}
                                      index={cindex}
                                      blackOrWhiteText={blackOrWhiteText}
                                      computeTotalAverageRating={
                                        computeTotalAverageRating
                                      }
                                      currentProperty={currentProperty}
                                      setIsFormChanged={setIsFormChanged}
                                    />
                                  );
                                })}
                              </tbody>
                            </React.Fragment>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </Scrollbars>
              <div
                className="ant-table ant-table-small ant-table-bordered"
                style={{
                  borderRight: "solid 1px rgb(240,240,240)",
                }}
              >
                <div className="ant-table-container">
                  <table style={{ tableLayout: "auto" }}>
                    <colgroup>
                      <col style={{ width: "50px" }}></col>
                      <col style={{ width: "300px" }}></col>
                      <col style={{ width: "175px" }}></col>
                    </colgroup>
                    <thead className={"ant-table-thead "}>
                      <tr>
                        <th
                          className="ant-table-cell"
                          style={{ textTransform: "uppercase" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textTransform: "uppercase" }}
                        >
                          <strong>{t("admin_rating.total")}</strong>
                        </th>
                        <th
                          className="ant-table-cell"
                          style={{
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <strong>{`${(
                            Math.round(parseFloat(totalAverageRating) * 100) /
                            100
                          ).toFixed(2)}`}</strong>
                        </th>
                        <th className="ant-table-cell" align={"center"}></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </Col>
            <Col span={11}>
              <Row>
                <Col flex={"auto"}>
                  <Card>
                    <Title level={2}>
                      {t("admin_rating.rating")}{" "}
                      <span style={{ float: "right", zoom: 1.4 }}>
                        {(
                          Math.round(parseFloat(totalAverageRating) * 100) / 100
                        ).toFixed(2)}
                      </span>
                    </Title>

                    {/* <Statistic
                    title={t("admin_rating.rating")}
                    precision={2}
                    value={(
                      Math.round(parseFloat(totalAverageRating) * 100) / 100
                    ).toFixed(2)}
                    style={{ zoom: 2.4, textAlign: "center" }}
                  ></Statistic> */}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col flex={"auto"}>
                  <Card
                    title={
                      helpGroup &&
                      parse(
                        `<strong>${helpGroup}</strong> ${helpTextTitle} ${
                          helpChild && `- ${helpChild.toLocaleUpperCase()}`
                        }`
                      )
                      // <div
                      //   style={{
                      //     color: `${blackOrWhiteText(
                      //       group && COLORS_SERIES[group]
                      //     )}`,
                      //   }}
                      // >
                      //   {helpTextTitle}
                      // </div>
                    }
                    style={{ marginTop: "20px" }}
                    className={`${helpCardStyle}-criteria`}
                  >
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      autoHeight
                      autoHeightMin={100}
                      autoHeightMax={550}
                    >
                      <RatingCriteriaList
                        helpIntro={helpIntro}
                        helpManual={helpManual}
                        helpAutomatic={helpAutomatic}
                        helpFoto={helpFoto}
                        helpFotoGoogle={helpFotoGoogle}
                        helpFotoPlan={helpFotoPlan}
                        helpFotoGooglePlan={helpFotoGooglePlan}
                        helpPlan={helpPlan}
                        helpMessungen={helpMessungen}
                        helpWebLink={helpWebLink}
                        helpSonstige={helpSonstige}
                      />

                      {/* {helpText.intro != "" && (
                        <div className={"rating-help-group"}>
                          <p className="bold">{t("criteria.requirements")}</p>
                          <p className="content">{parse(helpText.intro)}</p>
                        </div>
                      )}
                      {helpText.manual != "" && (
                        <div className={"rating-help-group"}>
                          <p className="bold">{t("criteria.manual")}</p>
                          <p className="content">{parse(helpText.manual)}</p>
                        </div>
                      )}
                      {helpText.automatic != "" && (
                        <div className={"rating-help-group"}>
                          <p className="bold">{t("criteria.automatic")}</p>
                          <p className="content">{parse(helpText.automatic)}</p>
                        </div>
                      )}
                      <div className={"rating-help-group"}>
                        {(helpFoto != "" ||
                          helpPlan != "" ||
                          helpMessungen != "" ||
                          helpWebLink != "" ||
                          helpSonstige != "") && (
                          <p className="bold">{t("criteria.proof")}</p>
                        )}

                        {helpFoto != "" && (
                          <div className={"rating-help-group"}>
                            <p className="nachweise">{t("criteria.foto")}</p>
                            <p className="content">{parse(helpFoto)}</p>
                          </div>
                        )}
                        {helpPlan != "" && (
                          <div className={"rating-help-group"}>
                            <p className="nachweise">{t("criteria.plan")}</p>
                            <p className="content">{parse(helpPlan)}</p>
                          </div>
                        )}
                        {helpMessungen != "" && (
                          <div className={"rating-help-group"}>
                            <p className="nachweise">
                              {t("criteria.messungen")}
                            </p>
                            <p className="content">{parse(helpMessungen)}</p>
                          </div>
                        )}

                        {/* setting weblink  
                        {helpWebLink != "" && (
                          <div className={"rating-help-group"}>
                            <p className="nachweise">{t("criteria.weblink")}</p>
                            <p className="content">{parse(helpWebLink)}</p>
                          </div>
                        )}
                        {helpSonstige != "" && (
                          <div className={"rating-help-group"}>
                            <p className="nachweise">
                              {t("criteria.sonstige")}
                            </p>
                            <p className="content">{parse(helpSonstige)}</p>
                          </div>
                        )}
                      </div> */}
                    </Scrollbars>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}></Col>
            <Col span={8}>
              <div align={"right"} style={{ margin: "20px" }}>
                <Form.Item>
                  <Button
                    type="default"
                    htmlType="button"
                    style={{
                      display: "inline-block",
                      width: "calc(33% - 16px)",
                      margin: "0 8px 0",
                    }}
                    onClick={() => {
                      if (isFormChanged) {
                        setDirection("close");
                        setConfirmVisible(true);
                      } else setVisible(false);
                      // if (setPropertyId) setPropertyId(0);
                    }}
                  >
                    {t("admin_rating.close")}
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      display: "inline-block",
                      width: "calc(33% - 16px)",
                      margin: "0 8px",
                    }}
                    onClick={() => setIsFormChanged(false)}
                  >
                    {t("admin_rating.save")}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  );
};

export default observer(ActualRatingsModal);
