import React from "react";

//translation
import { useTranslation } from "react-i18next";
import { COLORS_SERIES } from "../../../data/constants";
import CriteriaSettingsController from "../../../controllers/CriteriaSettingsController";

const RatingRow = (props) => {
  const { t } = useTranslation("common"); //translation
  const { blackOrWhiteText } = CriteriaSettingsController({});

  const { criteria, setHelpDescription, groupName, index } = props;
  // const COLORS_SERIES = { G: "#ff5555", U: "#aacc88", W: "#99ccff" };
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];

  return (
    <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
      <td
        className="ant-table-cell"
        style={{
          textAlign: "center",
          maxWidth: "50px",
          backgroundColor: code_color,
          color: `${blackOrWhiteText(code_color)}`,
        }}
      >
        {row_code}
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
        onFocus={() => {
          setHelpDescription(criteria.key);
        }}
      >
        {t("criteria." + criteria.key.split(".").join("_") + ".title")}
      </td>
      <td
        className="ant-table-cell"
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
      ></td>
      <td
        className="ant-table-cell"
        colSpan={2}
        onClick={() => {
          setHelpDescription(criteria.key);
        }}
      ></td>
    </tr>
  );
};

export default RatingRow;
