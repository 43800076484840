import React, { useEffect } from "react";
// import logo from "../logo.svg";
import "antd/dist/antd.css";
import "./App.css";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { observer, inject } from "mobx-react"; //These functions make our components observable and be able to use the store

import ProtectedRoute from "../components/ProtectedRoute";
import Login from "./Login";

import Dashboard from "../components/Dashboard";
// import Dashboard from "./Admin/Dashboard/TestDashboard";
// import Dashboard2 from "../components/Admin/Dashboard2";
import Management from "../components/Admin/Management";
import FileDownloads from "../components/Admin/Documents/FileDownloads";
import ClientDashboard from "../components/Admin/ClientDashboard";

import ClientList from "./Admin/ClientList";
import Portfolios from "./Client/Portfolios";

function App(props) {
  const { store } = props;
  useEffect(() => {
    store.setClearCache();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/login" render={() => <Login store={store} />} />

          <ProtectedRoute
            exact={true}
            path="/"
            component={Dashboard}
            store={store}
            adminOnly={false}
          />
          {/*  <ProtectedRoute
            exact={true}
            path="/Dashboard2"
            component={Dashboard2}
            adminOnly={false}
            store={store}
          /> */}
          <ProtectedRoute
            exact={true}
            path="/Management"
            component={Management}
            adminOnly={true}
            store={store}
          />
          <ProtectedRoute
            exact={true}
            path="/ClientDashboard/:id"
            component={ClientDashboard}
            adminOnly={true}
            store={store}
          />
          <ProtectedRoute
            exact={true}
            path="/Documents"
            component={FileDownloads}
            adminOnly={false}
            store={store}
          />
          {/*  <ProtectedRoute
            exact={true}
            path="/Dashboard/:id"
            component={ClientDashboard}
            store={store}
          /> */}
          <ProtectedRoute
            path="/Clients"
            component={ClientList}
            adminOnly={true}
            store={store}
          />
          <ProtectedRoute
            path="/Portfolios"
            component={Portfolios}
            adminOnly={false}
            store={store}
          />
          <ProtectedRoute component={Dashboard} store={store} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default inject("store")(observer(App));
