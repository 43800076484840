import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'

import {
  Row,
  Col,
  Card,
  Typography,
  Popconfirm,
  Input,
  Button,
  Space,
  Table,
  Affix,
  message,
} from 'antd'

import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  postal_codes,
  cities,
  cantons,
  regions,
  getCantonName,
} from '../../../data/AddressBook'

import CompanyDetailModal from './CompanyDetailModal'
import CompanyDetailDrawer from './CompanyDetailDrawer'

import CompanyModel from '../../../models/CompanyModel'

//translation
import { useTranslation } from 'react-i18next'

//const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography
const { Search } = Input

function Management(props) {
  const { t } = useTranslation('common') //translation

  const { store } = props

  const selectedClientId = store.activeAdminCompanyId
  const [searchKeyword, setSearchKeyword] = useState('')
  const [Message, setMessage] = useState('')

  const [IsEditMode, setIsEditMode] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [HasData, setHasData] = useState(false)

  /* For Delete purposes */
  const [CompanyIDsForDeletion, setCompanyIDsForDeletion] = useState([])
  const [CompanyNameForDeletion, setCompanyNameForDeletion] = useState('')

  const [isClientDetailVisible, setIsClientDetailVisible] = useState(false)

  const [isClientUsersModalVisible, setIsClientUsersModalVisible] = useState(
    false
  )

  const [isClientPortfoliosVisible, setIsClientPortfoliosVisible] = useState(
    false
  )

  const [selectedClient, setSelectedClient] = useState(CompanyModel.create({}))

  /*  useEffect(() => {
    if (selectedClient) selectedClient.getPortfolios((success) => {});
  }, [selectedClient]);
 */
  const [isNewClient, setIsNewClient] = useState(true)

  useEffect(() => {
    setMessage('')

    setIsEditMode(false)
    setHasData(false)
    setIsClientDetailVisible(false)

    // setTimeout(() => {
    setIsLoading(true)

    store.getAdministratorCompanies().then((success) => {
      loadAdministratorList()
      setHasData(true)
      setIsLoading(false)
    })

    // }, 500);

    return () => {
      setIsEditMode(false)
    }
  }, [])

  useEffect(() => {
    loadAdministratorList()
  }, [store.activeAdmins, searchKeyword])

  const [dataSource, setdataSource] = useState([])

  const dataSourceTest = [
    {
      key: '1',
      name: 'Juan Del Cruz',
      city: 'City 1',
      product_count: 1,
      property_count: 1,
      rating: 1,
    },
    {
      key: '2',
      name: 'Villar Real Estate',
      city: 'City 1',
      product_count: 3,
      property_count: 12,
      rating: 1,
    },
    {
      key: '3',
      name: 'Wakanda Insurance Co.',
      city: 'City 2',
      product_count: 15,
      property_count: 1553,
      rating: 3,
    },
    {
      key: '4',
      name: 'Ditto Inc.',
      city: 'City 3',
      product_count: 5,
      property_count: 250,
      rating: 2,
    },
  ]

  const [cityFilters, setCityFilters] = useState([])
  const [cantonFilters, setCantonFilters] = useState([])
  const [regionFilters, setRegionFilters] = useState([])
  const loadAdministratorList = (keyword = '') => {
    //if(keyword) setSearchKeyword(keyword);

    var sword = (keyword && keyword.length > 0 ? keyword : searchKeyword).trim()

    setdataSource(
      store.activeAdmins
        .filter(
          (r) =>
            sword.length === 0 ||
            r.name.toLowerCase().indexOf(sword.toLowerCase()) > -1
        )
        .map((c) => {
          return {
            key: c.id,
            name: c.name,
            city: c.city,
            canton: c.canton,
            // region: c.region,
            portfolioCount: c.portfolioCountCached,
            propertyCount: c.propertyCountCached,
            rating: c.ratingCached,
          }
        })
    )

    setIsLoading(false)
  }

  useEffect(() => {
    var uniqueCityNames = dataSource
      .map((c) => {
        return {
          value: c.city,
        }
      })
      .filter(function(obj, index, self) {
        return (
          index ===
          self.findIndex(function(t) {
            return t['value'] === obj['value']
          })
        )
      })

    setCityFilters(
      uniqueCityNames
        .sort((a, b) => a.value.localeCompare(b.value))
        .map((c) => {
          return { text: c.value, value: c.value }
        })
    )

    var uniqueCantonNames = dataSource
      .map((c) => {
        return {
          value: c.canton,
        }
      })
      .filter(function(obj, index, self) {
        return (
          index ===
          self.findIndex(function(t) {
            return t['value'] === obj['value']
          })
        )
      })

    setCantonFilters(
      uniqueCantonNames
        .map((c) => {
          return { text: getCantonName(c.value), value: c.value }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    )

    var uniqueRegionNames = dataSource
      .map((c) => {
        return {
          value: c.region,
        }
      })
      .filter(function(obj, index, self) {
        return (
          index ===
          self.findIndex(function(t) {
            return t['value'] === obj['value']
          })
        )
      })
      .sort((a, b) => a.value.localeCompare(b.value))
    setRegionFilters(
      uniqueRegionNames.map((c) => {
        return { text: c.value, value: c.value }
      })
    )

    return () => {
      //reset
      setCityFilters([])
      setCantonFilters([])
      setRegionFilters([])
    }
  }, [dataSource])

  /*  const getCantonName = (id) => {
    var canton = cantons.find((c) => c.id === id);
    return canton ? canton.name : "";
  }; */
  const columns = [
    {
      title: t('management.name'),
      dataIndex: 'name',
      key: 'id',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <a
          title="View Detail"
          onClick={() => {
            showClientDrawer(record.key)
          }}
        >
          {record.name}
        </a>
      ),
    },
    {
      title: t('management.city'),
      dataIndex: 'city',
      key: 'city',
      filters: cityFilters || [],
      onFilter: (value, record) => record.city === value,
      sorter: (a, b) => a.city.localeCompare(b.city),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('management.canton'),
      dataIndex: 'canton',
      key: 'canton',
      filters: cantonFilters || [],
      onFilter: (value, record) => record.canton === value,
      sorter: (a, b) => a.canton.localeCompare(b.canton),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => getCantonName(record.canton),
    },
    // {
    //   title: t("management.region"),
    //   dataIndex: "region",
    //   key: "region",
    //   filters: regionFilters || [],
    //   onFilter: (value, record) => record.region === value,
    //   sorter: (a, b) => a.region.localeCompare(b.region),
    //   defaultSortOrder: "descend",
    //   sortDirections: ["descend", "ascend"],
    // },
  ]
  const search = (keyword) => {
    setSearchKeyword(keyword)
    setIsLoading(true)
  }
  const addNewClient = () => {
    store.setActiveAdminCompanyId(0)
    setIsNewClient(true)
    setSelectedClient(CompanyModel.create({}))

    setIsClientModalVisible(true)
  }
  const showUserModal = (id) => {
    //setSelectedClientData
    setSelectedClient(store.administrators.find((c) => c.id === id))

    setIsClientUsersModalVisible(true)
  }
  const showClientDrawer = (id) => {
    store.setActiveAdminCompanyId(id)

    setSelectedClient(store.administrators.find((c) => c.id === id))

    setIsNewClient(false)

    //setSelectedClientData
  }

  useEffect(() => {
    if (selectedClient.id > 0) {
      setIsClientDetailVisible(true)
    }
    return () => {}
  }, [selectedClient])

  const [isClientModalVisible, setIsClientModalVisible] = useState(false)
  const clientDetailModalProps = {
    store,
    isCompanyModalVisible: isClientModalVisible,
    setIsCompanyModalVisible: setIsClientModalVisible,
    postal_codes,
    cities,
    cantons,
    regions,
    search,
    setSearchKeyword,
  }

  const clientDetailDrawerProps = {
    store,
    isClientDetailVisible: isClientDetailVisible,
    setIsClientDetailVisible,
    /* isClientUsersModalVisible,
    setIsClientUsersModalVisible,
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible, */
    postal_codes,
    cities,
    cantons,
    regions,
    /* loadAdministratorList,
    isNewClient,
    setIsNewClient,
    selectedClient,
    setSelectedClient,
    isAddMode,
    setIsAddMode, */
    getCantonName,
  }

  const clientPortfoliosProps = {
    store,
    isClientPortfoliosVisible,
    setIsClientPortfoliosVisible,
    clientId: isClientPortfoliosVisible ? selectedClient.id : 0,
    getCantonName,
    /*  portfolios: store.portfolios.filter(p=>p.companyId==selectedClient.id) */
  }
  const [isAffixed, setIsAffixed] = useState(false)
  const DeleteCompany = () => {
    if (CompanyIDsForDeletion.length > 0) {
      store
        .setMultipleClientStatus('inactive', CompanyIDsForDeletion)
        .then((success) => {
          if (success) {
            store.setClientListUpdateOn(new Date().getTime().toString())

            if (CompanyIDsForDeletion.length === 1) {
              message.success(
                `Company ${CompanyNameForDeletion} deleted successfully`
              )

              setCompanyNameForDeletion('')
            } else {
              message.success(`Selected Company deleted successfully`)
            }

            setCompanyIDsForDeletion([])
          } else {
            message.error(`Delete action failed`)

            setCompanyNameForDeletion('')
            setCompanyIDsForDeletion([])
          }
        })
    }
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      )
      setCompanyIDsForDeletion(selectedRowKeys)

      if (selectedRows.length === 1) {
        setCompanyNameForDeletion(selectedRows[0].name)
      }
    },
    /*  getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }), */
  }
  return (
    <>
      <Affix
        style={{ position: 'absolute' }}
        offsetTop={0}
        onChange={(affixed) => {
          console.log(affixed)
          setIsAffixed(affixed)
        }}
      ></Affix>
      <Affix offsetTop={0}>
        <Row
          className={'page-title'}
          gutter={[32, 24]}
          style={{
            backgroundColor: isAffixed
              ? 'rgba(255,255,255,0.8)'
              : 'rgba(255,255,255,0.0)',
            boxShadow: isAffixed
              ? '10px 1px 15px rgba(128,128,128,0.5)'
              : 'none',
          }}
        >
          <Col flex="auto">
            <Title className={isAffixed ? 'page-title' : ''}>
              {' '}
              {t('management.management')}{' '}
            </Title>
          </Col>
          <Col></Col>
        </Row>
      </Affix>
      <Row gutter={[32, 24]}>
        <Col flex="auto">
          <Card
            className={'ant-custom-table'}
            style={{ overflow: 'hidden', padding: '15px', minHeight: '500px' }}
          >
            <Row gutter={[32, 24]}>
              {/*   <Col flex="auto">
                <Title level={3}>Clients</Title>
              </Col> */}
              <Col
                style={{
                  textAlign: 'left',
                }}
              >
                <Space>
                  <Search
                    placeholder={t('management.search_company')}
                    onSearch={(value) => search(value)}
                  />
                  {IsEditMode === false && (
                    <>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => addNewClient()}
                      >
                        {t('management.add')}
                      </Button>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => setIsEditMode(rowSelection)}
                      >
                        {t('management.edit')}
                      </Button>
                    </>
                  )}
                  {IsEditMode && (
                    <>
                      <Popconfirm
                        placement={'bottom'}
                        title={t('client.are_you_sure_you_want_remove')}
                        onConfirm={() => DeleteCompany()}
                        okText={t('client.yes')}
                        cancelText={t('client.no')}
                      >
                        <Button
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => console.log('+')}
                        >
                          {t('management.delete')}
                        </Button>
                      </Popconfirm>
                      <Button onClick={() => setIsEditMode(false)}>
                        {t('management.done')}
                      </Button>
                    </>
                  )}
                </Space>
              </Col>
            </Row>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize: 20 }}
              size="small"
              bordered="true"
              rowSelection={IsEditMode}
              loading={IsLoading}
              hasData={HasData}
            />

            {selectedClientId > 0 && (
              <CompanyDetailDrawer
                {...clientDetailDrawerProps}
              ></CompanyDetailDrawer>
            )}
          </Card>
        </Col>
      </Row>
      <CompanyDetailModal {...clientDetailModalProps}></CompanyDetailModal>
    </>
  )
}

export default inject('store')(observer(Management))
