import React from "react";

//translation
import { useTranslation } from "react-i18next";
import { COLORS_SERIES } from "../../../data/constants";
import CriteriaSettingsController from "../../../controllers/CriteriaSettingsController";
const RatingRowPerUsage = (props) => {
  const { t } = useTranslation("common"); //translation
  const { blackOrWhiteText } = CriteriaSettingsController({});
  const { criteria, setHelpDescription, groupName, index, currentProperty } =
    props;

  // const COLORS_SERIES = { G: "#ff5555", U: "#aacc88", W: "#99ccff" };
  const row_code = groupName[0] + (index + 1);
  const code_color = COLORS_SERIES[groupName[0]];

  return (
    <React.Fragment key={criteria.key}>
      <tr key={criteria.key} className="ant-table-row ant-table-row-level-0">
        <td
          rowSpan={
            4 -
            [
              currentProperty.areaCommercial,
              currentProperty.areaOffice,
              currentProperty.areaResidential,
            ].filter((_) => _ === 0).length
          }
          style={{
            textAlign: "center",
            maxWidth: "50px",
            backgroundColor: code_color,
            color: `${blackOrWhiteText(code_color)}`,
          }}
          className="ant-table-cell"
        >
          {row_code}
        </td>
        <td
          className="ant-table-cell"
          colSpan={4}
          onClick={() => {
            setHelpDescription(criteria.key);
          }}
        >
          {t("criteria." + criteria.key.split(".").join("_") + ".title")}
        </td>
      </tr>
      {criteria.children &&
        criteria.children.map((c, _i) => {
          return (
            <tr key={c.key} className="ant-table-row ant-table-row-level-0">
              <td
                style={{
                  backgroundColor: code_color,
                }}
                className="ant-table-cell"
              ></td>
              <td
                className="ant-table-cell"
                colSpan={4}
                onClick={() => {
                  setHelpDescription(c.key);
                }}
              >
                <div style={{ marginLeft: "50px" }}>
                  {" "}
                  {t("criteria." + c.key.split(".").join("_") + ".title")}
                </div>
              </td>
            </tr>
          );
        })}
    </React.Fragment>
  );
};

export default RatingRowPerUsage;
