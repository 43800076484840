export const ageRatingsByCriteria = (currentProperty) => {
  let _pE = {};
  if (currentProperty.areaResidential > 0) {
    _pE = {
      ..._pE,
      "108.1.1": null,
    };
  }

  if (currentProperty.areaOffice > 0) {
    _pE = {
      ..._pE,
      "108.1.2": null,
    };
  }

  if (currentProperty.areaCommercial > 0) {
    _pE = {
      ..._pE,
      "108.1.3": null,
    };
  }

  if (currentProperty.areaBuilding > 0) {
    _pE = {
      ..._pE,
      "108.1.4": null,
    };
  }

  if (currentProperty.areaHealth > 0) {
    _pE = {
      ..._pE,
      "108.1.5": null,
    };
  }

  return {
    "> 1": {
      106.2: null,
      107.1: null,
      ..._pE,
      108.2: null,
      202.1: null,
      301.1: null,
      301.2: null,
      303.2: null,
      303.3: null,
    },
    // "< 1960": {
    //   106.2: 0,
    //   107.1: 0,
    //   ..._pE,
    //   108.2: 0,
    //   202.1: 0,
    //   301.1: 3,
    //   301.2: 0,
    //   303.2: 0,
    //   303.3: 1,
    // },
    // "1960-1969": {
    //   106.2: 0,
    //   107.1: 0,
    //   ..._pE,
    //   108.2: 0,
    //   202.1: 0,
    //   301.1: 2,
    //   301.2: 0,
    //   303.2: 0,
    //   303.3: 0,
    // },
    // "1970-1987": {
    //   106.2: 0,
    //   107.1: 0,
    //   ..._pE,
    //   108.2: 0,
    //   301.1: 2,
    //   301.2: 0,
    //   303.2: 0,
    //   303.3: 0,
    // },
    // "1988-1990": {
    //   106.2: 2,
    //   107.1: 0,
    //   ..._pE,
    //   108.2: 0,
    //   301.1: 2,
    //   301.2: 0,
    //   303.2: 0,
    //   303.3: 0,
    // },
    // "1991-1994": {
    //   106.2: 2,
    //   303.2: 0,
    // },
    // "1995-2002": {
    //   106.1: 3,
    //   106.2: 2,
    // },
    // "1995-2002 mit Abschattung": {
    //   106.1: 2,
    //   106.2: 2,
    // },
    // "2003-2006": {
    //   106.1: 3,
    //   106.2: 2,
    //   204.2: 3,
    // },
    // "2003-2006 mit Abschattung": {
    //   106.1: 2,
    //   106.2: 2,
    //   204.2: 3,
    // },
    // "> 2006": {
    //   106.1: 3,
    //   106.2: 3,
    //   204.2: 3,
    // },
    // "> 2006 mit Abschattung": {
    //   106.1: 2,
    //   106.2: 3,
    //   204.2: 3,
    // },
  };
};
